import React from "react";
import { Text } from "~shared/components/Text";
import clsx from "clsx";

type Props = {
  title: string;
  value: string;
  className?: string;
};

export const TitleValue: React.FC<Props> = ({ title, value, className = "" }) => {
  return (
    <div className={clsx("flex gap-1 flex-wrap", className)}>
      <div>
        <Text component='span' className='font-bold'>
          {title}
        </Text>
        <span className='font-bold'>:</span>
      </div>
      <div>{value}</div>
    </div>
  );
};
