// eslint-disable-next-line
// @ts-nocheck
import React, { useMemo } from "react";
import { TableHeadCell } from "~/shared/components/TableHeadLabel";
import { Link } from "~/shared/components/Link";
import { formatDateForTable } from "~/shared/lib/formatDate";
import { useSortProps } from "~/shared/hooks/useSortProps";
import { ActiveOrder } from "~/shared/types/ActiveOrder";
import { Column, Path, pathLink } from "../types";
import { formatDescriptionForTable } from "~/shared/lib/formatDescriptionForTable";
import { ProjectsModel } from "~/generated/graphql";
import { useResortColumns } from "~/shared/hooks/useResortColumns";
import { getTableCellDefaultStyles } from "~/shared/lib/getTableCellDefaultStyles";
import clsx from "clsx";
import { getImageSrc } from "~shared/lib/getImageSrc";
import { useLocation } from "react-router-dom";
import { StatusColorWrapper } from "~/shared/components/StatusColorWrapper";

const orderKey = "__projects-columns-order";

export const useColumns = (
  activeOrder?: ActiveOrder,
  handleOrderClick?: (_activeOrder: ActiveOrder) => void
) => {
  const { getClickHandler, getActiveProps } = useSortProps(handleOrderClick, activeOrder);
  const location = useLocation();
  const pathName = Object.keys(Path).find((key) => Path[key] === location.pathname);

  const defaultColumns: Record<Column["id"], Column> = useMemo(
    () => ({
      id: {
        id: "id",
        label: (
          <TableHeadCell
            title='ID'
            cellId='id'
            onSortClick={getClickHandler("id")}
            sortProps={getActiveProps("id")}
          />
        ),
        style: { ...getTableCellDefaultStyles(50), paddingRight: 24 },
        className: "text-grayLight cursor-default",
        defaultSelected: true,
        selectTitle: "ID"
      },
      image: {
        id: "image",
        label: <TableHeadCell title='Image short' cellId='image' />,
        style: { ...getTableCellDefaultStyles(52), paddingLeft: 8, cursor: "default" },
        render: (value: ProjectsModel["image"], row: ProjectsModel) => (
          <img
            className={clsx("w-[52px] h-[30px] rounded object-cover", {
              hidden: !value?.path
            })}
            loading='lazy'
            src={getImageSrc(value) ?? ""}
            alt={row.name as string}
          />
        ),
        defaultSelected: true,
        selectTitle: "Image"
      },
      name: {
        id: "name",
        label: (
          <TableHeadCell
            title='Designation name'
            cellId='name'
            onSortClick={getClickHandler("name")}
            sortProps={getActiveProps("name")}
          />
        ),
        style: { ...getTableCellDefaultStyles(300), cursor: "default" },
        render: (value = "", row: ProjectsModel) => {
          return (
            <Link
              className='transition-all'
              to={`${pathLink[pathName]?.replace(":id", String(row.id))}`}
            >
              {formatDescriptionForTable(value) === ""
                ? "Без названия"
                : formatDescriptionForTable(value)}
            </Link>
          );
        },
        defaultSelected: true,
        selectTitle: "Designation name"
      },
      description: {
        id: "description",
        label: (
          <TableHeadCell
            title='Description'
            cellId='description'
            onSortClick={getClickHandler("description")}
            sortProps={getActiveProps("description")}
          />
        ),
        style: { ...getTableCellDefaultStyles(300), cursor: "default" },
        render: (value = "", row: ProjectsModel) => {
          return (
            <Link
              className='transition-all'
              to={`${pathLink[pathName]?.replace(":id", String(row.id))}`}
            >
              {formatDescriptionForTable(value)}
            </Link>
          );
        },
        defaultSelected: true,
        selectTitle: "Description"
      },
      status: {
        id: "status",
        label: <TableHeadCell title='Status' cellId='status' />,
        style: { ...getTableCellDefaultStyles(150), cursor: "default" },
        defaultSelected: true,
        selectTitle: "Status",
        render: (value: { name: string; id: number }) => <StatusColorWrapper value={value} />
      },
      publishedAt: {
        id: "publishedAt",
        label: (
          <TableHeadCell
            title='Published at'
            align='center'
            cellId='published_at'
            onSortClick={getClickHandler("publishedAt")}
            sortProps={getActiveProps("publishedAt")}
          />
        ),
        style: { ...getTableCellDefaultStyles(170), cursor: "default" },
        align: "center",
        render: formatDateForTable,
        defaultSelected: true,
        selectTitle: "Published at"
      }
    }),
    [getActiveProps, getClickHandler]
  );

  const { columns, onSortColumnsEnd } = useResortColumns(defaultColumns, orderKey);

  return { columns, onSortColumnsEnd };
};
