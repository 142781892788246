import { Box, ClickAwayListener, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Button } from "~/shared/components/Button";
import { useRole } from "../Roles";
import { StatusesExpertise } from "~/shared/types/statusesExpertise";
import { Text } from "~/shared/components/Text";
import { useModal } from "~/shared/hooks/useModal";
import { CustomTooltip } from "~/shared/components/CustomTooltip";

type ModeratinIput = { projectsSendToModerateId: number };

export type ModerationProps = {
  initialValues?: {
    status?: { id?: number; name?: string | null } | null;
    id?: number;
    rejectReason?: string | null;
  } | null;
  sendToModerate?: (input: ModeratinIput) => void;
  decline?: (input: ModeratinIput & { reason: string }) => void;
  isModerator: boolean;
  refetch?: () => void;
  isFullData?: boolean;
  publish?: (input: ModeratinIput) => void;
  canNotSendToModerate?: boolean;
};

export const Moderation: React.FC<ModerationProps> = ({
  initialValues,
  sendToModerate,
  isModerator,
  decline,
  refetch,
  publish,
  isFullData = false,
  canNotSendToModerate
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");

  const { hasPermissions } = useRole();

  const isAdmin = hasPermissions(["all"]);
  const status = initialValues?.status?.name;
  const id = initialValues?.id;
  const statusId = initialValues?.status?.id;

  const { open, handleClose, handleOpen } = useModal();

  const handleModerate = async () => {
    if (!id) {
      return;
    }

    await sendToModerate?.({ projectsSendToModerateId: id });
    refetch?.();
  };

  const handleDecline = async () => {
    if (!id) {
      return;
    }

    await decline?.({ projectsSendToModerateId: id, reason });
    refetch?.();
    setIsOpen(false);
  };

  const handlePublish = async () => {
    if (!id) {
      return;
    }

    await publish?.({ projectsSendToModerateId: id });
    refetch?.();
  };
  const moderatorCanPublish = statusId === StatusesExpertise.ON_MODERATION_ID && isModerator;
  const adminCanPublish =
    isAdmin &&
    isFullData &&
    ![StatusesExpertise.ON_PUBLISHING_ID, StatusesExpertise.PUBLISHED_ID].includes(
      statusId as number
    );
  const canPublish = moderatorCanPublish || adminCanPublish;

  const moderateExist =
    !isAdmin &&
    [
      StatusesExpertise.DRAFT_ID,
      StatusesExpertise.DECLINED_ID,
      StatusesExpertise.REMOVED_FROM_PUBLICATION_ID
    ].includes(statusId as StatusesExpertise) &&
    !canNotSendToModerate;

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {!!status && (
          <>
            <Typography variant='h6' fontWeight='bold'>
              {status}
            </Typography>
            {statusId === StatusesExpertise.DECLINED_ID && (
              <Typography variant='h6' fontWeight='bold'>
                {initialValues?.rejectReason}
              </Typography>
            )}
          </>
        )}
        <Box sx={{ display: "flex", gap: 1 }}>
          {moderateExist && (
            <ClickAwayListener onClickAway={handleClose} touchEvent='onTouchStart'>
              <CustomTooltip
                disableHoverListener
                title={
                  <Box className='flex flex-col gap-4'>
                    <Text>Вы уверены, что хотите отправить на модерацию?</Text>
                    <Box className='flex gap-6'>
                      <Button
                        className='px-2 w-full min-w-fit'
                        variant='outlined'
                        color='error'
                        onClick={handleClose}
                      >
                        No
                      </Button>

                      <Button
                        className='px-2 w-full min-w-fit'
                        variant='outlined'
                        color='success'
                        onClick={handleModerate}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                }
                open={open === "moderation"}
                arrow
              >
                <Button variant='outlined' onClick={() => handleOpen("moderation")}>
                  Отправить на модерацию
                </Button>
              </CustomTooltip>
            </ClickAwayListener>
          )}
          {statusId === StatusesExpertise.ON_MODERATION_ID && isModerator && (
            <ClickAwayListener onClickAway={handleClose} touchEvent='onTouchStart'>
              <CustomTooltip
                disableHoverListener
                title={
                  <Box className='flex flex-col gap-4'>
                    <Text>Вы уверены, что хотите отклонить?</Text>
                    <Box className='flex gap-6'>
                      <Button
                        className='px-2 w-full min-w-fit'
                        variant='outlined'
                        color='error'
                        onClick={handleClose}
                      >
                        No
                      </Button>

                      <Button
                        className='px-2 w-full min-w-fit'
                        variant='outlined'
                        color='success'
                        onClick={() => setIsOpen(true)}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                }
                open={open === "decline"}
                arrow
              >
                <Button variant='outlined' onClick={() => handleOpen("decline")}>
                  Отклонить
                </Button>
              </CustomTooltip>
            </ClickAwayListener>
          )}
          {canPublish && (
            <ClickAwayListener onClickAway={handleClose} touchEvent='onTouchStart'>
              <CustomTooltip
                disableHoverListener
                title={
                  <Box className='flex flex-col gap-4'>
                    <Text>Вы уверены, что хотите опубликовать?</Text>
                    <Box className='flex gap-6'>
                      <Button
                        className='px-2 w-full min-w-fit'
                        variant='outlined'
                        color='error'
                        onClick={handleClose}
                      >
                        No
                      </Button>

                      <Button
                        className='px-2 w-full min-w-fit'
                        variant='outlined'
                        color='success'
                        onClick={handlePublish}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                }
                open={open === "publish"}
                arrow
              >
                <Button variant='outlined' onClick={() => handleOpen("publish")}>
                  Опубликовать
                </Button>
              </CustomTooltip>
            </ClickAwayListener>
          )}
        </Box>
      </Box>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 600
          }}
        >
          <Typography id='modal-title' variant='h6' component='h2' marginBottom={2}>
            Причина отказа
          </Typography>
          <div>
            <TextField
              margin='normal'
              fullWidth
              label='Причина'
              rows={4}
              value={reason}
              onChange={(e: any) => {
                setReason(e.target.value);
              }}
            />
            <Box marginTop={2} sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
              <Button variant='outlined' color='primary' onClick={() => setIsOpen(false)}>
                Закрыть
              </Button>
              <Button variant='contained' color='primary' onClick={handleDecline}>
                Подтвердить
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
};
