import { Box, Checkbox, FormControlLabel, IconButton, Modal, TableCell } from "@mui/material";
import React from "react";
import clsx from "clsx";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useModal } from "~/shared/hooks/useModal";
import { Text } from "../Text";
import { Button } from "../Button";

type Checkbox = {
  id: string;
  name: string;
  selected: boolean;
};

type Props = {
  checkboxes: Checkbox[];
  onChange: (checkbox: Checkbox[]) => void;
  isLoading?: boolean;
};

type CheckboxProps = Checkbox & {
  onChange?: (checkbox: Checkbox) => void;
};

type CheckboxMap = Record<Checkbox["id"], Checkbox>;

const CheckBox: React.FC<CheckboxProps> = ({ id, name, selected, onChange }) => {
  const handleChange = () => {
    onChange?.({ id, name, selected: !selected });
  };

  return (
    <Box
      className={clsx("w-full h-fit bg-transparent transition rounded-lg p-1 pl-2", {
        "!bg-primary !bg-opacity-20": selected
      })}
    >
      <FormControlLabel
        className='w-full overflow-hidden flex gap-2'
        control={<Checkbox id={id} name={name} checked={selected} onChange={handleChange} />}
        label={
          <Text component='span' className='whitespace-nowrap'>
            {name}
          </Text>
        }
      />
    </Box>
  );
};

export const TableHeadSettingsCell: React.FC<Props> = ({ checkboxes, onChange }) => {
  const [innerCheckboxes, setInnerCheckboxes] = React.useState(
    checkboxes.reduce((res: CheckboxMap, checkbox) => {
      res[checkbox.id] = checkbox;

      return res;
    }, Object.create(null))
  );

  const { open, handleClose, handleOpen } = useModal();

  const onOpen = () => handleOpen();

  const getChangeHandler = (checkbox: Checkbox) => {
    setInnerCheckboxes((old) => ({ ...old, [checkbox.id]: checkbox }));
  };

  const onSelectAll = () => {
    setInnerCheckboxes(
      checkboxes.reduce((res: CheckboxMap, checkbox) => {
        res[checkbox.id] = { ...checkbox, selected: true };

        return res;
      }, Object.create(null))
    );
  };

  const onRemoveAll = () => {
    setInnerCheckboxes(
      checkboxes.reduce((res: CheckboxMap, checkbox) => {
        res[checkbox.id] = { ...checkbox, selected: false };

        return res;
      }, Object.create(null))
    );
  };

  const onSuccess = () => {
    onChange(Object.values(innerCheckboxes));
    handleClose();
  };

  return (
    <>
      <TableCell className='!w-[57px] !min-w-[57px]'>
        <IconButton onClick={onOpen}>
          <SettingsIcon />
        </IconButton>
      </TableCell>
      <Modal open={!!open} onClose={handleClose} aria-labelledby='control-modal-title'>
        <Box className='flex flex-col bg-white absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-full lg:w-[900px] max-h-[90vh] min-h-[300px] rounded-lg overflow-auto'>
          <Box className='flex items-center justify-between p-4 pb-0'>
            <Text id='control-modal-title' className='font-bold'>
              Настройка списка
            </Text>

            <Button onClick={handleClose} size='small' className='!min-w-fit'>
              <CloseIcon />
            </Button>
          </Box>

          <Box className='p-3 grid sm:grid-cols-2 lg:grid-cols-4 gap-1 justify-between max-h-[60vh] overflow-auto'>
            {Object.values(innerCheckboxes).map((checkbox) => (
              <CheckBox {...checkbox} key={checkbox.id} onChange={getChangeHandler} />
            ))}
          </Box>

          <Box className='flex flex-wrap gap-2 w-full items-center justify-between p-6 mt-auto relative'>
            <Button
              variant='text'
              className='w-fit lg:w-auto order-0 text-sm'
              startIcon={<KeyboardReturnIcon />}
              onClick={onSelectAll}
              textProps={{ className: "text-sm" }}
            >
              По умолчанию
            </Button>
            <Box className='flex justify-between w-full lg:w-auto lg:absolute  lg:left-[50%]  lg:transform  lg:translate-x-[-50%] gap-2 order-1'>
              <Button
                variant='contained'
                className='w-full lg:w-auto'
                color='primary'
                onClick={onSuccess}
                disabled={Object.values(innerCheckboxes).every((checkbox) => !checkbox.selected)}
              >
                Применить
              </Button>
              <Button
                className='w-full lg:w-auto'
                variant='outlined'
                color='error'
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Box>
            <Box className='w-fit lg:w-auto flex gap-2 order-0 lg:order-1'>
              <Button
                className='w-fit slg:w-auto'
                variant='text'
                textProps={{ className: "text-sm underline decoration-dotted" }}
                onClick={onSelectAll}
              >
                Выбрать все
              </Button>
              <Button
                className='w-fit lg:w-auto min-w-fit'
                variant='text'
                textProps={{ className: "text-sm underline decoration-dotted" }}
                onClick={onRemoveAll}
              >
                Отменить все
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
