import React from "react";
import { PageTableTitle } from "~/shared/components/PageTableTitle";
import { PageWrapper } from "~/shared/components/PageWrapper";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { ProjectsTable } from "~/modules/ProjectsTable";

export const Projects: React.FC = () => {
  const { count, isLoading } = usePageLoadingStore((state) => ({
    count: state.count,
    isLoading: state.isLoading
  }));

  return (
    <PageWrapper>
      <PageTableTitle title='Projects' isLoading={isLoading} count={count} />
      <ProjectsTable
        mayCreateAndDeletePermission='management_content_science'
        moderatorRoles={["moderator_content_science"]}
      />
    </PageWrapper>
  );
};
