import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useNavigationBack } from "~/shared/hooks/useBackClick";
import { DetailsHead } from "~/shared/components/DetailsHead";
import { Panel } from "~/shared/components/Panel";
import { PageWrapper } from "~/shared/components/PageWrapper";
import { ExpertisesApplicationsHistory } from "~/modules/ExpertisesApplicationHistory";

export const ExpertisesApplicationHistory: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const handleGoBack = useNavigationBack();

  return (
    <PageWrapper>
      <Panel>
        <Box className='flex p-3 flex-col gap-6 items-center'>
          <DetailsHead
            title={"Детальная история работы с заявкой"}
            onBackClick={handleGoBack}
            formName=''
          />
          <ExpertisesApplicationsHistory id={id} />
        </Box>
      </Panel>
    </PageWrapper>
  );
};
