import { Box } from "@mui/material";
import React from "react";
import { PropsWithChildren } from "react";

export const PageTitle: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className='flex justify-center items-end w-full pt-4'>
      <Box className='w-full flex items-center flex-wrap gap-4'>{children}</Box>
    </Box>
  );
};
