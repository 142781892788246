import React from "react";
import { DeepPartial } from "react-hook-form";
import { Maybe, PracticesGrantWinnersModel } from "~/generated/graphql";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useRole } from "~/entities/Roles";

type Props = {
  model: Maybe<DeepPartial<PracticesGrantWinnersModel>>;
  handleOpenEdit: () => void;
  handleDelete: (id: number) => void;
};

export const Row: React.FC<Props> = ({ model = {}, handleOpenEdit, handleDelete }) => {
  const { hasPermissions } = useRole();

  const canEdit = hasPermissions(["moderator_content_mp_vd"]);

  if (!model?.id) return null;

  return (
    <div className='flex items-center gap-3 text-base'>
      <div className='w-[324px] max-md:hidden text-center'>{model?.name}</div>

      <div
        className='w-[324px] hover:opacity-80  max-md:hidden flex gap-2 justify-center'
        aria-hidden
      >
        {canEdit && <EditIcon onClick={handleOpenEdit} />}
        <DeleteForeverIcon onClick={() => handleDelete(model.id as number)} color='error' />
      </div>
    </div>
  );
};
