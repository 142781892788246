import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Collapse, List, ListItemButton } from "@mui/material";
import { DrawerListItem } from "../DrawerListItem";
import { TPath } from "~/app/providers/Paths";
import clsx from "clsx";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

type Props = {
  paths: TPath[];
  open: boolean;
  activePath: string | null;
  className?: string;
};

export const DrawerList: React.FC<Props> = ({ paths, open, activePath, className = "" }) => {
  const history = useNavigate();

  // This state could be an object if you want to track open status for each menu item
  const [openSubmenus, setOpenSubmenus] = useState<Record<number, boolean>>({});

  // A function to handle click events on menu items
  const handleMenuItemClick = (path: string, hasChildren: boolean, id: number) => {
    if (hasChildren) {
      setOpenSubmenus((prevOpenSubmenus) => ({
        ...prevOpenSubmenus,
        [id]: !prevOpenSubmenus[id]
      }));
    } else {
      history(path);
    }
  };

  return (
    <List className={clsx("overflow-y-auto overflow-x-hidden", className)}>
      {paths.map((item) => (
        <React.Fragment key={item.id + (item.path ?? "")}>
          <ListItemButton
            onClick={() =>
              handleMenuItemClick(
                item?.path || item.children?.[0].path || "/",
                !!item.children,
                item.id
              )
            }
            selected={item.path === activePath}
            sx={{ p: 0 }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <DrawerListItem
                key={item.id + (item.path ?? "")}
                active={item.path === activePath}
                open={open}
                icon={item.icon}
                label={item.label}
                onClick={() => history(item?.path || item.children?.[0].path || "/")}
              />
              {!!item.children &&
                item.children.length > 0 &&
                (openSubmenus[item.id] ? <ExpandLess /> : <ExpandMore />)}
            </div>
          </ListItemButton>
          {item.children && (
            <Collapse in={openSubmenus[item.id]} timeout='auto' sx={{ pl: 2 }}>
              <List component='div' disablePadding>
                {item.children.map((childItem) => (
                  <DrawerListItem
                    key={childItem.id + (childItem.path ?? "")}
                    active={childItem.path === activePath}
                    open={open}
                    icon={childItem.icon}
                    label={childItem.label}
                    onClick={() => history(childItem.path ?? "/")} // Define the onClick handler
                  />
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};
