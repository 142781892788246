import { DeepPartial } from "react-hook-form";
import { DissertationCouncilsModel, DissertationCouncilsInput } from "~/generated/graphql";

export const prepareFormData = async (
  newValues: Partial<DissertationCouncilsModel & DissertationCouncilsInput>,
  values?: DeepPartial<DissertationCouncilsModel> | null,
  { isCreateMode }: { isCreateMode?: boolean } = {}
) => {
  const input: DissertationCouncilsInput = {
    ...(Boolean(values?.id) && { id: values?.id }),
    published: newValues.published,
    city: newValues.city ? { id: newValues.city?.id } : null,
    specialties: newValues.specialties,
    organizer: { id: newValues.organizer?.id },
    slug: newValues.slug,
    name: newValues.name,
    link: newValues?.link,
    commentForModerator: newValues.commentForModerator
  };
  if (isCreateMode) delete input.id;
  return input;
};
