import { formatDateForTable } from "./formatDate";

export const getIdChips = (value: string): string => `ID:${value}`;

export const getDatePublishedChips = (value: string): string => `${formatDateForTable(value)}`;

export const getIsPublishedChips = (): string => "Опубликовано";

export const getCategoryChips = () => "Категория";
export const getTypeChips = () => "Тип";

export const getOnIndexChips = () => "На главной";
