import { omit, pipe, values, isEmpty, map, all, cond, T, not, isNil, F } from "rambda";

const isBoolean = (value: unknown) => typeof value === "boolean";

export const isFullData = (object: unknown, excludedFields: string[] = []): boolean => {
  if (!object) {
    return false;
  }

  return pipe(
    omit(excludedFields),
    values,
    map(
      cond([
        [isBoolean, T],
        [isNil, F],
        [T, pipe(isEmpty, not)]
      ])
    ),
    all(Boolean)
  )(object);
};
