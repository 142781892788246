import { DeepPartial } from "react-hook-form";
import { ScientificJournalsModel, ScientificJournalsInput } from "~/generated/graphql";
import { omit } from "rambda";

export const prepareFormData = async (
  newValues: Partial<ScientificJournalsModel & ScientificJournalsInput>,
  values?: DeepPartial<ScientificJournalsModel> | null,
  { isCreateMode }: { isCreateMode?: boolean } = {}
) => {
  const input: ScientificJournalsInput = {
    ...(Boolean(values?.id) && { id: values?.id }),
    ...omit(["image", "accessFormat"], newValues),
    ...(newValues?.accessFormat
      ? {
          accessFormat: {
            id: Number(newValues?.accessFormat?.id || newValues?.accessFormat) as number
          }
        }
      : { accessFormat: {} }),
    publishedAt: newValues.publishedAt,
    published: newValues.published,
    name: newValues.name,
    link: newValues.link,
    inVAK: newValues.inVAK,
    inRINC: newValues.inRINC,
    inScopus: newValues.inScopus,
    inWebOfScience: newValues.inWebOfScience,
    ...(Boolean(newValues.organizer) && { organizer: { id: newValues.organizer?.id } }),
    inOtherDatabases: newValues.inOtherDatabases,
    ...(Boolean(newValues.image) && {
      image: {
        id: newValues.image?.id
      }
    })
  };
  if (isCreateMode) delete input.id;
  return input;
};
