import { DeepPartial } from "react-hook-form";
import { PracticesModel, PracticesInput } from "~/generated/graphql";
import { omit } from "rambda";

export const prepareFormData = async (
  newValues: Partial<PracticesModel & PracticesInput>,
  values?: DeepPartial<PracticesModel> | null,
  { isCreateMode }: { isCreateMode?: boolean; touchedFields?: Record<string, unknown> } = {}
) => {
  const input: PracticesInput = {
    ...(Boolean(values?.id) && { id: values?.id }),
    ...omit(
      [
        "image",
        "imageDetail",
        "video",
        "isAllCanSee",
        "contactPerson",
        "organizer",
        "annotationImage",
        "presentation",
        "tips",
        "tags",
        "events",
        "publishedAt",
        "region"
      ],
      newValues
    ),
    ...(Boolean(newValues.image) && {
      image: { id: newValues.image?.id }
    }),

    ...(Boolean(newValues.imageDetail) && {
      imageDetail: {
        id: newValues.imageDetail?.id
      }
    }),

    ...(Boolean(newValues.annotationImage) && {
      annotationImage: {
        id: newValues.annotationImage?.id
      }
    }),

    ...(Boolean(newValues.region) && {
      region: {
        id: (newValues.region?.id || newValues.region) as number
      }
    }),

    ...(Boolean(newValues.presentation) && {
      presentation: {
        id: newValues.presentation?.id
      }
    }),

    ...(Boolean(newValues.video) && {
      video: {
        id: newValues.video?.id
      }
    }),
    tips: newValues?.tips?.map((el) => ({ id: el.id, name: el.name })) ?? [],

    isAllCanSee:
      typeof newValues.isAllCanSee === "boolean"
        ? newValues.isAllCanSee
        : (newValues.isAllCanSee as unknown as string) ===
          "для любого зарегистрированного пользователя",
    ...(Boolean(newValues.contactPerson) && {
      contactPerson: {
        id: newValues.contactPerson?.id,
        org: { id: newValues.contactPerson?.org?.id || newValues.organizer?.id }
      }
    }),
    organizer: { id: newValues.organizer?.id || values?.organizer?.id }
  };

  if (isCreateMode) delete input.id;
  return input;
};
