import React, { forwardRef, useEffect, useRef, useState } from "react";
// NOTE self hosted tinymce
import "../../lib/tinymce";
import "../../lib/tinymce/icons/default";
import "../../lib/tinymce/themes/silver";
import "../../lib/tinymce/themes/mobile";

import "../../lib/tinymce/plugins/advlist/plugin.min.js";
import "../../lib/tinymce/plugins/anchor/plugin.min.js";
import "../../lib/tinymce/plugins/autolink/plugin.min.js";
import "../../lib/tinymce/plugins/autoresize/plugin.min.js";
import "../../lib/tinymce/plugins/autosave/plugin.min.js";
import "../../lib/tinymce/plugins/charmap/plugin.min.js";
import "../../lib/tinymce/plugins/code/plugin.min.js";
import "../../lib/tinymce/plugins/codesample/plugin.min.js";
import "../../lib/tinymce/plugins/directionality/plugin.min.js";
import "../../lib/tinymce/plugins/emoticons/plugin.min.js";
import "../../lib/tinymce/plugins/emoticons/js/emojiimages.min.js";
import "../../lib/tinymce/plugins/emoticons/js/emojis.min.js";
import "../../lib/tinymce/plugins/fullscreen/plugin.min.js";
import "../../lib/tinymce/plugins/help/plugin.min.js";
import "../../lib/tinymce/plugins/image/plugin.min.js";
import "../../lib/tinymce/plugins/importcss/plugin.min.js";
import "../../lib/tinymce/plugins/insertdatetime/plugin.min.js";
import "../../lib/tinymce/plugins/link/plugin.min.js";
import "../../lib/tinymce/plugins/lists/plugin.min.js";
import "../../lib/tinymce/plugins/media/plugin.min.js";
import "../../lib/tinymce/plugins/nonbreaking/plugin.min.js";
import "../../lib/tinymce/plugins/pagebreak/plugin.min.js";
import "../../lib/tinymce/plugins/preview/plugin.min.js";
import "../../lib/tinymce/plugins/quickbars/plugin.min.js";
import "../../lib/tinymce/plugins/save/plugin.min.js";
import "../../lib/tinymce/plugins/searchreplace/plugin.min.js";
import "../../lib/tinymce/plugins/table/plugin.min.js";
import "../../lib/tinymce/plugins/template/plugin.min.js";
import "../../lib/tinymce/plugins/visualblocks/plugin.min.js";
import "../../lib/tinymce/plugins/visualchars/plugin.min.js";
import "../../lib/tinymce/plugins/wordcount/plugin.min.js";
import "../../lib/tinymce/plugins/print/plugin.min.js";
import "../../lib/tinymce/plugins/hr/plugin.min.js";
import "../../lib/tinymce/plugins/paste/plugin.min.js";
import "../../lib/tinymce/plugins/imagetools/plugin.min.js";
import "../../lib/tinymce/plugins/textpattern/plugin.min.js";
import "../../lib/tinymce/plugins/textcolor/plugin.min.js";

import "../../lib/tinymce/skins/ui/oxide/content.inline.min.css";
import "../../lib/tinymce/skins/ui/oxide/content.min.css";
import "../../lib/tinymce/skins/ui/oxide/content.mobile.min.css";
import "../../lib/tinymce/skins/ui/oxide/skin.min.css";
import "../../lib/tinymce/skins/ui/oxide/skin.mobile.min.css";
import "../../lib/tinymce/skins/ui/oxide/skin.shadowdom.min.css";

import "../../lib/tinymce/skins/content/default/content.min.css";

import "../../lib/tinymce/langs/ru.js";

import { Editor } from "@tinymce/tinymce-react";
import { RawEditorSettings } from "tinymce";
import { Box, CircularProgress, Modal } from "@mui/material";
import clsx from "clsx";
import { createEvent } from "./lib/createEvent";
import styles from "./ContentEditor.module.scss";
import { getFileFromNative } from "~shared/lib/upload";
import { useModal } from "~/shared/hooks/useModal";
import { Button } from "../Button";
import { FileInput } from "../FileInput";
import { Loader } from "../Loader";
import { useAlertsStore } from "~/shared/stores/alerts";

type EditorEvent = { target: { value: string; name: string } };

export type ContentEditorProps = {
  value: string;
  name: string;
  error?: boolean;
  disabled?: boolean;
  settings?: Omit<RawEditorSettings, "selector" | "target" | "min_height">;
  size?: "small" | "medium";
  onChange?: (event: EditorEvent) => void;
  onBlur?: (event: EditorEvent) => void;
  onFocus?: (event: EditorEvent) => void;
  getUploadedUrl?: (file: File, type?: string) => Promise<string>;
  isUploadLoading?: boolean;
};

const defaultHandler = (_: EditorEvent) => void _;

export const ContentEditor: React.FC<ContentEditorProps> = React.memo(
  forwardRef<React.LegacyRef<Editor>, ContentEditorProps>(
    (
      {
        value,
        name,
        onChange = defaultHandler,
        onBlur = defaultHandler,
        getUploadedUrl,
        isUploadLoading,
        error,
        settings = {},
        size = "medium",
        disabled = false
      },
      ref
    ) => {
      const editorRef = useRef<Record<string, unknown>>();

      const [isLoading, setIsLoading] = useState(true);

      const height = size === "small" ? 200 : 600;

      const { open, handleOpen, handleClose } = useModal();

      const handleChange = (value: string) =>
        onChange?.(
          createEvent(
            value
              ?.replace(/(\.\.\/)/g, "")
              ?.replace(/"(\/)?core\//g, `"${process.env.REACT_APP_FILES_URL}` as string),
            name
          )
        );

      useEffect(() => {
        let isTextExist = false;

        if (typeof editorRef.current?.getBody === "function") {
          isTextExist = !!editorRef.current?.getBody()?.textContent;
        }

        if (typeof editorRef.current?.setContent === "function" && !isTextExist) {
          editorRef.current?.setContent(
            value
              ?.replace(/(\.\.\/)/g, "")
              ?.replace(/"(\/)?core\//g, `"${process.env.REACT_APP_FILES_URL}` as string) ?? ""
          );
        }
      }, [ref, value]);

      return (
        <Box
          className={clsx("relative z-999", styles.editor, {
            "min-h-[200px]": size === "small",
            "min-h-[600px]": size === "medium",
            "text-transparent": isLoading,
            [styles.editor_error]: error
          })}
        >
          {isLoading && (
            <Box className='flex h-[20vh] w-full justify-center items-center'>
              <CircularProgress />
            </Box>
          )}
          <Editor
            ref={ref as React.LegacyRef<Editor>}
            value={value}
            onEditorChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
            init={{
              selector: "textarea" as never,
              setup: (editor) => {
                editor.ui.registry.addButton("custommedia", {
                  icon: "embed",
                  onAction: () => handleOpen()
                });

                editorRef.current = editor as unknown as Record<string, unknown>;
                editor.on("init", () => {
                  setIsLoading(false);
                });
              },
              language: "ru",
              plugins:
                "autoresize print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern textcolor help charmap quickbars emoticons",
              menubar: "file edit view insert format tools table",
              toolbar:
                "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image custommedia link codesample | ltr rtl",
              toolbar_sticky: false,
              autosave_ask_before_unload: false,
              autosave_interval: "30s",
              autosave_prefix: "{path}{query}-{id}-",
              autosave_restore_when_empty: false,
              autosave_retention: "2m",
              image_advtab: true,
              deprecation_warnings: false,
              importcss_append: true,
              images_upload_handler: async (blob, success, failure, progress) => {
                if (!getUploadedUrl) {
                  return;
                }

                progress?.(10);

                const file = new File([blob.blob()], blob.filename(), {
                  type: blob.blob().type
                });
                try {
                  const url = await getUploadedUrl(file);

                  // Для исправления бага бесконечного перерендеринга TinyMCE
                  await new Promise((resolve) => setTimeout(resolve, 10));

                  success(url);
                } catch {
                  failure("Ошибка загрузки", { remove: true });
                } finally {
                  progress?.(100);
                }
              },
              file_picker_callback: async (callback, _value, meta) => {
                if (!getUploadedUrl) {
                  return;
                }

                let accept = "*/*";
                if (meta.filetype == "image") {
                  accept = "image/*";
                } else if (meta.filetype == "media") {
                  accept = "video/*,audio/*";
                }

                const file = await getFileFromNative(accept);

                callback(await getUploadedUrl(file));
              },
              quickbars_selection_toolbar:
                "bold italic | quicklink h1 h2 h3 | fontselect fontsizeselect formatselect | blockquote quickimage quicktable",
              toolbar_mode: "sliding",
              toolbar_drawer: false,
              autoresize_bottom_margin: 20,
              min_height: height,
              contextmenu: "link image imagetools table",
              skin: "oxide",
              content_css: "default",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px } table, th, td { border: 1px solid black }\n.mce-offscreen-selection .mce-preview-object.mce-object-video {display: none;}\n .mce-offscreen-selection video {display: none;}",
              statusbar: false,
              table_default_attributes: {},
              fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 24px 28px 32px 40px 56px",
              ...settings
            }}
          />
          <Modal
            open={!!open}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                height: "fit-content",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "fit-content"
              }}
            >
              <Box
                marginTop={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {isUploadLoading && (
                  <div className='w-[200px] h-[200px] flex items-center justify-center'>
                    <Loader />
                  </div>
                )}
                {!isUploadLoading && (
                  <>
                    <FileInput
                      id='file-input'
                      fileFormats={["mp4"]}
                      onFileChange={(file) => {
                        if (!file) {
                          return;
                        }

                        getUploadedUrl?.(file, "video")
                          .then((url) => {
                            onChange?.(
                              createEvent(
                                value.concat(`<video controls style="width:100%;" src='${url}' />`),
                                name
                              )
                            );
                            handleClose();
                          })
                          .catch(() => {
                            useAlertsStore.getState().addAlert("error", "Ошибка загрузки видео");
                          });
                      }}
                    />
                    <Button
                      className='ml-auto'
                      variant='outlined'
                      color='primary'
                      onClick={handleClose}
                    >
                      Закрыть
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Modal>
        </Box>
      );
    }
  )
);

ContentEditor.displayName = "ContentEditor";
