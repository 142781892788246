import React from "react";
import { DeepPartial } from "react-hook-form";
import clsx from "clsx";
import { ContactPersonsModel } from "~/generated/graphql";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getImageSrc } from "~/shared/lib/getImageSrc";

type Props = {
  user: DeepPartial<ContactPersonsModel>;
  handleOpenEdit: () => void;
  handleDelete: () => void;
};

export const ContactRow: React.FC<Props> = ({ user, handleOpenEdit, handleDelete }) => {
  const { id, name, image, position, email } = user;

  if (!id) return null;

  return (
    <div className='flex items-center gap-3 text-base'>
      <div className={clsx("w-[298px] flex items-center gap-4")}>
        <div className='relative shrink-0 w-10 h-10'>
          <img
            src={getImageSrc(image) || "/empty.png"}
            alt='Аватар'
            className='w-full h-full object-cover rounded-full'
          />
        </div>
        <div>{name}</div>
      </div>
      <div className='w-[324px] max-md:hidden text-center'>{position}</div>
      <div className='w-[324px] max-md:hidden text-center'>{email}</div>

      <div
        className='w-[324px] hover:opacity-80  max-md:hidden flex gap-2 justify-center'
        aria-hidden
      >
        <EditIcon onClick={handleOpenEdit} />
        <DeleteForeverIcon onClick={handleDelete} color='error' />
      </div>
    </div>
  );
};
