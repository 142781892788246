import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { Text } from "~/shared/components/Text";
import { TabPanel } from "~/shared/components/TabPanel";
import { useRole } from "../Roles";
import { Moderation, ModerationProps } from "./Moderation";
import clsx from "clsx";

type Props = {
  forms: {
    tabTitle: string | JSX.Element;
    component: ReactNode;
    validate?: () => Promise<void>;
    isValid?: boolean;
    hasErrors?: boolean;
  }[];
  formName?: string;
  activeStep?: number;
  handleStepChange?: (step: number) => void;
  handleSubmit?: React.FormEventHandler<HTMLFormElement>;
  formProps?: Omit<React.FormHTMLAttributes<HTMLFormElement>, "onSubmit" | "id">;
} & Omit<ModerationProps, "isModerator"> & { sectionModeratorRole?: string };

export const TabsForm: React.FC<Props> = ({
  forms,
  handleStepChange,
  handleSubmit,
  activeStep = 0,
  formName,
  formProps,
  sectionModeratorRole,
  ...moderationProps
}) => {
  const handleTabChange = (_: unknown, tab: number) => handleStepChange?.(tab);

  useEffect(() => {
    forms[activeStep]?.validate?.();
  }, [forms, activeStep]);

  const { rolesQuery, hasPermissions } = useRole();

  const isModerator = sectionModeratorRole
    ? hasPermissions([sectionModeratorRole], { withoutAdmin: true })
    : !!rolesQuery.data?.me?.roles?.find(
        (el) => el.name?.includes("moderator") || el.name?.includes("admin")
      );

  return (
    <form
      {...formProps}
      id={formName}
      className={clsx("w-full", formProps?.className)}
      onSubmit={handleSubmit}
    >
      <Moderation {...moderationProps} isModerator={isModerator} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant='scrollable'
          scrollButtons='auto'
          value={activeStep}
          onChange={handleTabChange}
          aria-label='tabs'
        >
          {forms.map(({ tabTitle, hasErrors }, index) => (
            <Tab
              key={index}
              label={
                <Box className='flex items-center gap-x-2'>
                  {hasErrors && <ErrorIcon color='error' />}
                  {typeof tabTitle === "string" && (
                    <Text className='normal-case' component='span'>
                      {tabTitle}
                    </Text>
                  )}
                  {typeof tabTitle !== "string" && (
                    <Typography className='normal-case' component='span'>
                      {tabTitle}
                    </Typography>
                  )}
                </Box>
              }
              id={`tab-${index}`}
              aria-controls={`tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </Box>
      {forms.map(({ component }, index) => (
        <TabPanel className='mt-10' key={index} value={activeStep} index={index}>
          <Box sx={{ pointerEvents: status === "На модерации" && !isModerator ? "none" : "all" }}>
            {component}
          </Box>
        </TabPanel>
      ))}
    </form>
  );
};
