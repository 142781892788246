import React from "react";

export const CalendarIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_163_8131)'>
        <path
          d='M1.3335 8C1.3335 5.48584 1.3335 4.22876 2.11454 3.44771C2.89559 2.66666 4.15267 2.66666 6.66683 2.66666L9.3335 2.66666C11.8477 2.66666 13.1047 2.66666 13.8858 3.44771C14.6668 4.22876 14.6668 5.48584 14.6668 8L14.6668 9.33333C14.6668 11.8475 14.6668 13.1046 13.8858 13.8856C13.1047 14.6667 11.8477 14.6667 9.3335 14.6667L6.66683 14.6667C4.15267 14.6667 2.89559 14.6667 2.11454 13.8856C1.3335 13.1046 1.3335 11.8475 1.3335 9.33333L1.3335 8Z'
          stroke='#2C303B'
          strokeWidth='1.5'
        />
        <path
          d='M4.6665 2.66666V1.66666'
          stroke='#2C303B'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path
          d='M11.3335 2.66666V1.66666'
          stroke='#2C303B'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path d='M1.6665 6L14.3332 6' stroke='#2C303B' strokeWidth='1.5' strokeLinecap='round' />
        <path
          d='M11.9998 11.3333C11.9998 11.7015 11.7014 12 11.3332 12C10.965 12 10.6665 11.7015 10.6665 11.3333C10.6665 10.9651 10.965 10.6667 11.3332 10.6667C11.7014 10.6667 11.9998 10.9651 11.9998 11.3333Z'
          fill='#2C303B'
        />
        <path
          d='M11.9998 8.66667C11.9998 9.03486 11.7014 9.33333 11.3332 9.33333C10.965 9.33333 10.6665 9.03486 10.6665 8.66667C10.6665 8.29848 10.965 8 11.3332 8C11.7014 8 11.9998 8.29848 11.9998 8.66667Z'
          fill='#2C303B'
        />
        <path
          d='M8.66683 11.3333C8.66683 11.7015 8.36835 12 8.00016 12C7.63197 12 7.3335 11.7015 7.3335 11.3333C7.3335 10.9651 7.63197 10.6667 8.00016 10.6667C8.36835 10.6667 8.66683 10.9651 8.66683 11.3333Z'
          fill='#2C303B'
        />
        <path
          d='M8.66683 8.66667C8.66683 9.03486 8.36835 9.33333 8.00016 9.33333C7.63197 9.33333 7.3335 9.03486 7.3335 8.66667C7.3335 8.29848 7.63197 8 8.00016 8C8.36835 8 8.66683 8.29848 8.66683 8.66667Z'
          fill='#2C303B'
        />
        <path
          d='M5.33333 11.3333C5.33333 11.7015 5.03486 12 4.66667 12C4.29848 12 4 11.7015 4 11.3333C4 10.9651 4.29848 10.6667 4.66667 10.6667C5.03486 10.6667 5.33333 10.9651 5.33333 11.3333Z'
          fill='#2C303B'
        />
        <path
          d='M5.33333 8.66667C5.33333 9.03486 5.03486 9.33333 4.66667 9.33333C4.29848 9.33333 4 9.03486 4 8.66667C4 8.29848 4.29848 8 4.66667 8C5.03486 8 5.33333 8.29848 5.33333 8.66667Z'
          fill='#2C303B'
        />
      </g>
      <defs>
        <clipPath id='clip0_163_8131'>
          <rect width='16' height='16' rx='5' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
