import { FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { Text } from "~/shared/components/Text";

type Props = {
  checked: boolean;
  label: string;
  onChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
};

export const AppSwitch: React.FC<Props> = ({
  checked,
  label,
  onChange,
  className = "",
  disabled = false
}) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
      }
      label={<Text>{label}</Text>}
      className={className}
    />
  );
};
