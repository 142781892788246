import { Box, ButtonProps, ClickAwayListener } from "@mui/material";
import React, { ReactElement } from "react";
import { useModal } from "~/shared/hooks/useModal";
import { Button } from "../Button";
import { Text } from "../Text";
import { CartIcon } from "~shared/components/Icons";
import { CustomTooltip } from "../CustomTooltip";

export const ButtonDelete: React.FC<ButtonProps & { children?: ReactElement }> = ({
  onClick,
  children,
  ...buttonProps
}) => {
  const { open, handleOpen, handleClose } = useModal();

  const handleClick = () => {
    handleOpen();
  };

  const onSuccess: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    onClick?.(e);
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClose} touchEvent='onTouchStart'>
      <CustomTooltip
        disableHoverListener
        title={
          <Box className='flex flex-col gap-4'>
            <Text>Are you sure you want to delete?</Text>
            <Box className='flex gap-6'>
              <Button
                className='px-2 w-full min-w-fit'
                variant='outlined'
                color='error'
                onClick={handleClose}
              >
                No
              </Button>

              <Button
                className='px-2 w-full min-w-fit'
                variant='outlined'
                color='success'
                onClick={onSuccess}
                startIcon={<CartIcon color='#118782' />}
              >
                Yes
              </Button>
            </Box>
          </Box>
        }
        open={!!open}
        arrow
      >
        <Button
          startIcon={!children && <CartIcon />}
          variant='outlined'
          color='error'
          {...buttonProps}
          onClick={handleClick}
        >
          {children || "Delete"}
        </Button>
      </CustomTooltip>
    </ClickAwayListener>
  );
};
