import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type FilterChipsProps = {
  filters: Record<string, string>;
  handleRemove: (key: string) => void;
  additionalData?: Record<string, Record<number, string>>;
  excludedKeys?: string[];
  className?: string;
};

type ChipProps = {
  value: string;
  handleRemove: () => void;
};

const Chip: React.FC<ChipProps> = ({ value, handleRemove }) => {
  const handleRemoveIcon = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleRemove();
  };

  return (
    <Box className='flex items-center justify-center gap-2 bg-primary-10 rounded p-2 mr-1 h-[32px]'>
      <Typography className='whitespace-nowrap' color='black' fontSize={14}>
        {value}
      </Typography>
      <IconButton className='w-[20px] h-[20px]' onClick={handleRemoveIcon}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export const FilterChips: React.FC<FilterChipsProps> = ({ filters, handleRemove, className }) => {
  return (
    <div className={className}>
      {Object.entries(filters).map(([key, value], index) => {
        return <Chip key={index} value={value} handleRemove={() => handleRemove(key)} />;
      })}
    </div>
  );
};
