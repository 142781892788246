import { Box, CircularProgress, Icon, Input, Typography } from "@mui/material";
import React, { ChangeEvent, DragEvent, useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import CancelIcon from "@mui/icons-material/Cancel";
import { Text } from "../Text";
import { Link } from "../Link";
import { CloudUploadIcon } from "../Icons";
import { useAlertsStore } from "~stores/alerts";
import { useTranslation } from "react-i18next";
import { FilesModel } from "~/generated/graphql";
import clsx from "clsx";

type Props = {
  id: string;
  files?: Array<FilesModel> | null;
  onFileChange?: (file?: Array<File> | null) => void;
  onDrop?: (file?: Array<File> | null) => void;
  onDelete?: (index: number) => void;
  withPreview?: boolean;
  fileName?: string;
  label?: string;
  allowedExtensions?: RegExp | null;
  loading?: boolean;
  disabled?: boolean;
};

export const FilesMultipleInput: React.FC<Props> = ({
  id,
  files = [],
  onFileChange,
  onDrop,
  fileName = "",
  label = "Upload or drop file",
  allowedExtensions,
  loading = false,
  onDelete,
  disabled
}) => {
  const [selectedFiles, setSelectedFiles] = useState<Array<FilesModel> | null>(null);
  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const addAlert = useAlertsStore((state) => state.addAlert);
  const { t } = useTranslation();
  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? handlePrepareFiles(e.target.files) : [];
    onFileChange?.(files);
  };
  const dropFile = (e: DragEvent<HTMLDivElement>) => {
    const files = e.dataTransfer.files ? handlePrepareFiles(e.dataTransfer.files) : [];
    onDrop?.(files);
  };

  const handleDeleteFile = (deletedIndex: number) => {
    onDelete?.(deletedIndex);
    if (selectedFiles?.length) {
      setSelectedFiles((state) =>
        state?.length ? [...state.filter((_, index) => index !== deletedIndex)] : state
      );
      setFileUrls((state) =>
        state?.length ? [...state.filter((_, index) => index !== deletedIndex)] : state
      );
    }
  };

  const handlePrepareFiles = (files: FileList | null) => {
    if (!files) {
      return [];
    }

    return Array.from(files).map((file) => {
      if (allowedExtensions && !allowedExtensions.exec(file.name)) {
        addAlert("error", `${t("Image format invalid")} ${file.name}`);
        return file;
      }

      return file;
    });
  };

  const getFileUrls = (files: Array<FilesModel>) => {
    if (!files?.length) {
      return [];
    }
    return files.map((file) =>
      file?.path ? `${process.env.REACT_APP_FILES_URL}${file.path}` : ""
    );
  };

  useEffect(() => {
    if (!files) {
      return;
    }
    setSelectedFiles(files);
    setFileUrls(getFileUrls(files));
  }, [files]);

  return (
    <div className={clsx({ "pointer-events-none": disabled })}>
      {!loading && (
        <>
          <Box className='flex items-center relative transition hover:bg-gray-200 rounded-xl border border-dashed border-[#5229DD] mb-2'>
            <Input
              inputProps={{
                className:
                  "!absolute top-0 left-0 right-0 bottom-0 w-full h-full opacity-0 z-2 p-0",
                multiple: true
              }}
              className='!absolute w-full h-full opacity-0'
              type='file'
              id={id}
              onChange={handleFile}
              onDrop={dropFile}
            />
            <label htmlFor={id} className='w-full flex flex-col items-center cursor-pointer p-4'>
              <Icon className='w-[71px] h-[71px]' component={CloudUploadIcon} />
              <Text className='pt-5 text-center font-medium text-base'>{label}</Text>
            </label>
          </Box>

          {selectedFiles?.map((file, index) => (
            <Box
              className='flex items-center gap-10 w-[310px] shadow-lg p-2 rounded-[12px]'
              key={index}
            >
              <Box className='flex gap-2 items-center w-[80%]'>
                <DescriptionIcon />
                <Link className='w-full pointer-events-auto' to={fileUrls[index]} target='_blank'>
                  <Typography className='text-ellipsis overflow-hidden w-full break-words'>
                    {file?.name ?? fileName}
                  </Typography>
                </Link>
              </Box>
              <CancelIcon
                onClick={() => handleDeleteFile(index)}
                onKeyPress={() => handleDeleteFile(index)}
                tabIndex={0}
                className='text-red-700 right-0 top-0 cursor-pointer outline-1'
              />
            </Box>
          ))}
        </>
      )}
      {loading && (
        <Box className='flex justify-center items-center'>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};
