import { DeepPartial } from "react-hook-form";
import { PostgraduatesModel, PostgraduatesInput } from "~/generated/graphql";
import { TYPE_PAY } from "../static";

export const prepareFormData = async (
  newValues: Partial<PostgraduatesModel & PostgraduatesInput>,
  values?: DeepPartial<PostgraduatesModel> | null,
  { isCreateMode }: { isCreateMode?: boolean } = {}
) => {
  const input: PostgraduatesInput = {
    ...(Boolean(values?.id) && { id: values?.id }),
    ...newValues,
    published: newValues?.published,
    city: newValues?.city ? { id: newValues?.city?.id } : null,
    specialties: newValues?.specialties,
    slug: newValues?.slug,
    isPay:
      typeof newValues?.isPay === "boolean"
        ? newValues?.isPay
        : (newValues?.isPay as unknown as string) === TYPE_PAY[1].name,
    contactPerson: newValues?.contactPerson?.id
      ? {
          id: newValues?.contactPerson?.id,
          org: { id: newValues?.contactPerson?.org?.id || newValues?.organizer?.id }
        }
      : null,
    organizer: newValues?.organizer
      ? { id: newValues?.organizer?.id || values?.organizer?.id }
      : null
  };
  if (isCreateMode) delete input.id;
  return input;
};
