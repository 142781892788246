import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { BaseProtectedLayout } from "~/layouts/BaseProtectedLayout";
import { GuestRoutes } from "~/modules/GuestRoutes";
import * as routes from "~shared/routes";
import { Projects } from "../Science/Projects";
import { ProjectEdit } from "../Science/ProjectEdit";
import { Postgraduates } from "../Science/Postgraduates";
import { PostgraduatesEdit } from "../Science/PostgraduatesEdit";
import { CoApplication } from "../Science/CoApplication";
import { CoApplicationEdit } from "../Science/CoApplicationEdit";
import { DissertationCouncils } from "../Science/DissertationCouncils";
import { DissertationCouncilsEdit } from "../Science/DissertationCouncilsEdit";
import { Labs } from "../Science/Labs";
import { LabsEdit } from "../Science/LabsEdit";
import { Nomus } from "../Science/Nomus";
import { NomusEdit } from "../Science/NomusEdit";
import { Journals } from "../Science/Journals";
import { ScientificJournalsEdit } from "../Science/JournalsEdit";
import { Practices } from "../Science/Practices";
import { ExpertisesApplicationHistory } from "~/pages/ExpertisesApplicationHistory";
import { PracticesEdit } from "../Science/PracticesEdit";
import { useGetMoodleAuth } from "~/api/moodle";
import { useRole } from "~/entities/Roles";

const LazyPagesList = [
  "NoMatch",
  "Login",
  "Registration",
  "News",
  "NewsEdit",
  "InternshipsApplications",
  "InternshipsApplicationsEdit",
  "Home",
  "Internships",
  "InternshipsEdit",
  "Educations",
  "EducationsEdit",
  "Users",
  "UsersEdit",
  "Organizations",
  "OrganizationsEdit",
  "Events",
  "EventsEdit",
  "DigitalResources",
  "DigitalResourcesEdit",
  "ExpertisesApplications",
  "ExpertisesApplicationsEdit",
  "ExpertisesApplicationHistory",
  "ProfessionalOrientations",
  "ProfessionalOrientationsEdit",
  "OutsideResources",
  "OutsideResourcesEdit",
  "PreUnivercityEvents",
  "PreUnivercityEventsEdit",
  "PreUnivercityProjects",
  "PreUnivercityProjectsEdit",
  "PreUnivercityEducations",
  "PreUnivercityEducationsEdit",
  "Dictionaries",
  "DictionariesEdit",
  "YoungPolicyConference",
  "YoungPolicyConferenceEdit",
  "YoungPolicyProjects",
  "YoungPolicyProjectsEdit",
  "YoungPolicyContest",
  "YoungPolicyContestEdit",
  "YoungPolicyEducations",
  "YoungPolicyEducationsEdit",
  "Faq",
  "FaqEdit",
  "Notification",
  "NotificationEdit",
  "NotificationConfirmPage",
  "Pages",
  "PagesEdit",
  "Vacancy",
  "VacancyEdit",
  "Grants",
  "GrantsEdit",
  "Payments",
  "Tips",
  "TipsEdit",
  "Settings"
] as const;
const LazyPages = LazyPagesList.reduce(
  (acc, name) => ({
    ...acc,
    [name]: lazy(() => import(`~/pages/${name}`).then((module) => ({ default: module[name] })))
  }),
  {} as Record<(typeof LazyPagesList)[number], React.LazyExoticComponent<any>>
);

export const Routing: React.FC = () => {
  useGetMoodleAuth();
  const { rolesQuery } = useRole();
  const roles = rolesQuery.data?.me.roles;
  const isDefaultUser = roles?.some((role) => role.name === "default") && roles.length === 1;
  if (isDefaultUser) return <LazyPages.NoMatch />;
  return (
    <Routes>
      <Route
        path={routes.LoginPageRoute}
        element={
          <GuestRoutes>
            <LazyPages.Login />
          </GuestRoutes>
        }
      />
      <Route
        path={routes.HomePageRoute}
        element={
          <BaseProtectedLayout>
            <LazyPages.Home />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.NewsPageRoute}
        element={
          <BaseProtectedLayout permissions={["all", "moderator_content_news"]}>
            <LazyPages.News />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.NewsPageEdit}
        element={
          <BaseProtectedLayout permissions={["all", "moderator_content_news"]}>
            <LazyPages.NewsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.NewsPageCreate}
        element={
          <BaseProtectedLayout permissions={["all", "moderator_content_news"]}>
            <LazyPages.NewsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.ProjectPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <Projects />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.ProjectPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <ProjectEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.ProjectPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <ProjectEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PostgraduatesPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <Postgraduates />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PostgraduatesPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <PostgraduatesEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.PostgraduatesPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <PostgraduatesEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.CoApplicationPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <CoApplication />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.CoApplicationsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <CoApplicationEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.CoApplicationsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <CoApplicationEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.DissertationCouncilsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <DissertationCouncils />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.DissertationCouncilsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <DissertationCouncilsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.DissertationCouncilsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <DissertationCouncilsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.LabsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <Labs />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.LabsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <LabsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.LabsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <LabsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.NomusPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <Nomus />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.NomusPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <NomusEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.NomusPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <NomusEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.JournalsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <Journals />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.JournalsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <ScientificJournalsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.JournalsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "management_content_science",
              "moderator_reviews"
            ]}
          >
            <ScientificJournalsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PracticesPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_mp_vd",
              "management_content_mp_vd",
              "curator_mp_vd"
            ]}
          >
            <Practices />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PracticesPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_mp_vd",
              "management_content_mp_vd",
              "curator_mp_vd"
            ]}
          >
            <PracticesEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PracticesPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_mp_vd",
              "management_content_mp_vd",
              "curator_mp_vd"
            ]}
          >
            <PracticesEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.InternshipsApplicationsPageRoute}
        element={
          <BaseProtectedLayout>
            <LazyPages.InternshipsApplications />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.InternshipsApplicationsPageEdit}
        element={
          <BaseProtectedLayout>
            <LazyPages.InternshipsApplicationsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.InternshipsApplicationsPageCreate}
        element={
          <BaseProtectedLayout>
            <LazyPages.InternshipsApplicationsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.InternshipsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_internship",
              "management_content_internship",
              "moderator_internship",
              "moderator_reviews"
            ]}
          >
            <LazyPages.Internships />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.InternshipsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_internship",
              "management_content_internship",
              "moderator_internship",
              "moderator_reviews"
            ]}
          >
            <LazyPages.InternshipsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.InternshipsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_internship",
              "management_content_internship",
              "moderator_internship",
              "moderator_reviews"
            ]}
          >
            <LazyPages.InternshipsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.EducationsPageRoute}
        element={
          <BaseProtectedLayout>
            <LazyPages.Educations />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.EducationsPageEdit}
        element={
          <BaseProtectedLayout>
            <LazyPages.EducationsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.EducationsPageCreate}
        element={
          <BaseProtectedLayout>
            <LazyPages.EducationsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.ExpertisesApplicationsPageRoute}
        element={
          <BaseProtectedLayout>
            <LazyPages.ExpertisesApplications />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.ExpertisesApplicationsPageEdit}
        element={
          <BaseProtectedLayout>
            <LazyPages.ExpertisesApplicationsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.ExpertiseHistoryPage}
        element={
          <BaseProtectedLayout>
            <ExpertisesApplicationHistory />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.UsersPageRoute}
        element={
          <BaseProtectedLayout route={routes.UsersPageRoute}>
            <LazyPages.Users />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.UsersPageEdit}
        element={
          <BaseProtectedLayout>
            <LazyPages.UsersEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.UsersPageCreate}
        element={
          <BaseProtectedLayout>
            <LazyPages.UsersEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.OrganizationsPageRoute}
        element={
          <BaseProtectedLayout>
            <LazyPages.Organizations />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.OrganizationsPageEdit}
        element={
          <BaseProtectedLayout>
            <LazyPages.OrganizationsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.OrganizationsPageCreate}
        element={
          <BaseProtectedLayout>
            <LazyPages.OrganizationsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.EventsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "moderator_reviews",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.Events />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.EventsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "moderator_reviews",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.EventsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.EventsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "moderator_reviews",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.EventsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.DigitalResourcesPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_digital",
              "moderator_content_digital",
              "view_content_digital"
            ]}
          >
            <LazyPages.DigitalResources />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.DigitalResourcesPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_digital",
              "moderator_content_digital",
              "moderator_reviews"
            ]}
          >
            <LazyPages.DigitalResourcesEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.DigitalResourcesPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_digital",
              "moderator_content_digital",
              "moderator_reviews"
            ]}
          >
            <LazyPages.DigitalResourcesEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.ProfessionalOrientationsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_school",
              "management_content_school",
              "moderator_reviews",
              "curator_school"
            ]}
          >
            <LazyPages.ProfessionalOrientations />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.ProfessionalOrientationsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_school",
              "management_content_school",
              "moderator_reviews",
              "curator_school"
            ]}
          >
            <LazyPages.ProfessionalOrientationsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.ProfessionalOrientationsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_school",
              "management_content_school",
              "moderator_reviews",
              "curator_school"
            ]}
          >
            <LazyPages.ProfessionalOrientationsEdit />
          </BaseProtectedLayout>
        }
      />

      {/* OutsideResources */}
      <Route
        path={routes.OutsideResourcesPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_school",
              "moderator_content_school",
              "curator_school"
            ]}
          >
            <LazyPages.OutsideResources />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.OutsideResourcesPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_school",
              "moderator_content_school",
              "curator_school"
            ]}
          >
            <LazyPages.OutsideResourcesEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.OutsideResourcesPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_school",
              "moderator_content_school",
              "curator_school"
            ]}
          >
            <LazyPages.OutsideResourcesEdit />
          </BaseProtectedLayout>
        }
      />
      {/* PreUnivercityEvents */}
      <Route
        path={routes.PreUnivercityEventsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.PreUnivercityEvents />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PreUnivercityEventsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.PreUnivercityEventsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.PreUnivercityEventsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.PreUnivercityEventsEdit />
          </BaseProtectedLayout>
        }
      />
      {/* PreUnivercityEducations */}
      <Route
        path={routes.PreUnivercityEducationsPageRoute}
        element={
          <BaseProtectedLayout>
            <LazyPages.PreUnivercityEducations />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PreUnivercityEducationsPageEdit}
        element={
          <BaseProtectedLayout>
            <LazyPages.PreUnivercityEducationsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.PreUnivercityEducationsPageCreate}
        element={
          <BaseProtectedLayout>
            <LazyPages.PreUnivercityEducationsEdit />
          </BaseProtectedLayout>
        }
      />
      {/* PreUnivercityProjects */}
      <Route
        path={routes.PreUnivercityProjectsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_content_science",
              "management_content_science"
            ]}
          >
            <LazyPages.PreUnivercityProjects />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PreUnivercityProjectsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_content_science",
              "management_content_science"
            ]}
          >
            <LazyPages.PreUnivercityProjectsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.PreUnivercityProjectsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_content_science",
              "management_content_science"
            ]}
          >
            <LazyPages.PreUnivercityProjectsEdit />
          </BaseProtectedLayout>
        }
      />

      {/* МП и ВД наука-проекты */}

      <Route
        path={routes.YoungPolicyProjectPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "management_content_science",
              "moderator_content_science"
            ]}
          >
            <LazyPages.YoungPolicyProjects />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.YoungPolicyProjectPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "management_content_science",
              "moderator_content_science"
            ]}
          >
            <LazyPages.YoungPolicyProjectsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.YoungPolicyProjectPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "management_content_science",
              "moderator_content_science"
            ]}
          >
            <LazyPages.YoungPolicyProjectsEdit />
          </BaseProtectedLayout>
        }
      />

      {/* МП и ВД конференции */}

      <Route
        path={routes.YoungPolicyConferencePageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.YoungPolicyConference />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.YoungPolicyConferencePageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.YoungPolicyConferenceEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.YoungPolicyConferencePageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.YoungPolicyConferenceEdit />
          </BaseProtectedLayout>
        }
      />
      {/* МП и ВД конкурсы */}

      <Route
        path={routes.YoungPolicyContestPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.YoungPolicyContest />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.YoungPolicyContestPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.YoungPolicyContestEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.YoungPolicyContestPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.YoungPolicyContestEdit />
          </BaseProtectedLayout>
        }
      />

      {/* МП и ВД образование */}

      <Route
        path={routes.YoungPolicyEducationsPageRoute}
        element={
          <BaseProtectedLayout>
            <LazyPages.YoungPolicyEducations />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.YoungPolicyEducationsPageEdit}
        element={
          <BaseProtectedLayout>
            <LazyPages.YoungPolicyEducationsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.YoungPolicyEducationsPageCreate}
        element={
          <BaseProtectedLayout>
            <LazyPages.YoungPolicyEducationsEdit />
          </BaseProtectedLayout>
        }
      />

      {/* МП и ВД гранты */}

      <Route
        path={routes.GrantsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_content_news",
              "management_content_news"
            ]}
          >
            <LazyPages.Grants />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.GrantsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.GrantsEdit />
          </BaseProtectedLayout>
        }
      />
      <Route
        path={routes.GrantsPageCreate}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "management_content_mp_vd",
              "moderator_content_mp_vd",
              "moderator_event",
              "moderator_content_event",
              "management_content_event",
              "curator_event"
            ]}
          >
            <LazyPages.GrantsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.DictionariesPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "moderator_content_event",
              "moderator_content_internship",
              "moderator_content_mp_vd",
              "moderator_content_school",
              "moderator_content_digital"
            ]}
          >
            <LazyPages.Dictionaries />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.DictionariesPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_science",
              "moderator_content_event",
              "moderator_content_internship",
              "moderator_content_mp_vd",
              "moderator_content_school",
              "moderator_content_digital"
            ]}
          >
            <LazyPages.DictionariesEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.TipsPageRoute}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_event",
              "moderator_content_internship",
              "moderator_content_mp_vd",
              "moderator_content_school"
            ]}
          >
            <LazyPages.Tips />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.TipsPageEdit}
        element={
          <BaseProtectedLayout
            permissions={[
              "all",
              "moderator_content_event",
              "moderator_content_internship",
              "moderator_content_mp_vd",
              "moderator_content_school"
            ]}
          >
            <LazyPages.TipsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.FaqPageRoute}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.Faq />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.FaqPageCreate}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.FaqEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.FaqPageEdit}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.FaqEdit />
          </BaseProtectedLayout>
        }
      />

      <Route //Notifications
        path={routes.NotificationPageRoute}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.Notification />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.NotificationPageEdit}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.NotificationEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.NotificationPageConfirm}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.NotificationConfirmPage />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PagesPageRoute}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.Pages />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PagesPageCreate}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.PagesEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PagesPageEdit}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.PagesEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.VacancyPageRoute}
        element={
          <BaseProtectedLayout
            permissions={["all", "moderator_content_science", "management_content_science"]}
          >
            <LazyPages.Vacancy />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.VacancyPageCreate}
        element={
          <BaseProtectedLayout
            permissions={["all", "moderator_content_science", "management_content_science"]}
          >
            <LazyPages.VacancyEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.VacancyPageEdit}
        element={
          <BaseProtectedLayout
            permissions={["all", "moderator_content_science", "management_content_science"]}
          >
            <LazyPages.VacancyEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.PaymentsPageRoute}
        element={
          <BaseProtectedLayout permissions={["all", "lk_organizer"]}>
            <LazyPages.Payments />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={routes.SettingsPage}
        element={
          <BaseProtectedLayout permissions={["all"]}>
            <LazyPages.Settings />
          </BaseProtectedLayout>
        }
      />

      <Route
        path='*'
        element={
          <BaseProtectedLayout>
            <LazyPages.NoMatch />
          </BaseProtectedLayout>
        }
      />
    </Routes>
  );
};
