import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { curry } from "rambda";
import React, { forwardRef } from "react";
import { Text } from "~/shared/components/Text";
import { DatePicker } from "~/shared/components/DatePicker";
import { getEventValueHandler } from "~/shared/lib/events";
import { getCheckedHandler } from "~/shared/lib/getCheckedHandler";
import dayjs from "dayjs";

type Props = {
  params: Record<string, string> | null;
  handleChangeFilter: (name: string, value: unknown) => void;
};

export const FiltersForm: React.FC<Props> = forwardRef<HTMLFormElement, Props>(
  ({ params, handleChangeFilter }, ref) => {
    const getChangeHandler = (cellId: string) =>
      getEventValueHandler((value: unknown) => handleChangeFilter(cellId, value));

    const handleChecked = getCheckedHandler(handleChangeFilter);

    const handleOnPublishedChange = handleChecked("published");

    return (
      <form ref={ref}>
        <Grid spacing={4}>
          <Grid item columns={12} lg={4} xs={12}>
            <TextField
              fullWidth
              type='number'
              inputMode='numeric'
              inputProps={{
                step: 1,
                min: 1
              }}
              value={params?.["id.like"] ?? ""}
              label={<Text>ID</Text>}
              onChange={getChangeHandler("id.like")}
              variant='outlined'
            />
          </Grid>

          <Grid item columns={12} lg={4} xs={12}>
            <DatePicker
              className='w-full mt-4'
              label={<Text>Published at</Text>}
              value={params?.["publishedAt"] ?? null}
              maxDate={dayjs()}
              onChange={(e) => {
                curry(handleChangeFilter)("publishedAt")(e?.toISOString());
              }}
            />
          </Grid>

          <Grid item columns={12} xs={12}>
            <FormControlLabel
              control={<Switch checked={!!params?.published} onChange={handleOnPublishedChange} />}
              label={<Text>Published</Text>}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
);

FiltersForm.displayName = "FiltersForm";
