import { Box, ClickAwayListener, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { useProjectsMassDeleteMutation } from "~/generated/graphql";
import { Button } from "~/shared/components/Button";
import { Text } from "~/shared/components/Text";
import { useRole } from "~/entities/Roles";
import { CustomTooltip } from "~/shared/components/CustomTooltip";
import { useModal } from "~/shared/hooks/useModal";

type Props = {
  ids: Set<number>;
  count?: number;
  setSelected?: (ids: Set<number>) => void;
  refetch: () => void;
};

export const MultipleActions: React.FC<Props> = ({ ids, count = 30, setSelected, refetch }) => {
  const [action, setAction] = useState<keyof typeof actions | null>(null);
  const { hasPermissions } = useRole();
  const mayDelete = hasPermissions(["all", "management_content_science"]);

  const actions = {
    ...(Boolean(mayDelete) && { remove: "Удалить" })
  };

  const client = useGraphqlClient();

  const { mutateAsync: deleteAll } = useProjectsMassDeleteMutation(client);

  const { handleClose, handleOpen, open } = useModal();

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!action) {
      return;
    }
    if (action === "remove") {
      deleteAll({
        where: {
          id: { in: Array.from(ids) }
        }
      })
        .then(() => setSelected?.(new Set([])))
        .finally(refetch);
    }
  };
  if (!ids.size) {
    return null;
  }

  return (
    <Box
      component='form'
      className='sticky bottom-0 p-2 border-box bg-white flex gap-2 justify-between shadow-sm border-t-2'
      onSubmit={onSubmit}
    >
      <Box className='flex gap-4 flex-1 md:flex-row flex-col'>
        <TextField
          select
          className='w-[300px]'
          variant='outlined'
          label={<Text>Выберите действие</Text>}
          SelectProps={{
            value: action ?? "",
            onChange: (e) => setAction(e.target.value as keyof typeof actions)
          }}
        >
          {Object.entries(actions).map(([action, name]) => (
            <MenuItem key={action} value={action}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <ClickAwayListener onClickAway={handleClose} touchEvent='onTouchStart'>
          <CustomTooltip
            disableHoverListener
            title={
              <Box className='flex flex-col gap-4'>
                <Text>
                  {`Вы уверены, что хотите
                    ${action ? actions[action]?.toLowerCase() ?? "" : "массовое действие"}?`}
                </Text>
                <Box className='flex gap-6'>
                  <Button
                    className='px-2 w-full min-w-fit'
                    variant='outlined'
                    color='error'
                    onClick={handleClose}
                  >
                    No
                  </Button>

                  <Button
                    className='px-2 w-full min-w-fit'
                    variant='outlined'
                    color='success'
                    onClick={onSubmit}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            }
            open={!!open}
            arrow
          >
            <Button
              onClick={() => handleOpen()}
              variant='contained'
              className='shrink-0'
              color='primary'
              disabled={!action}
            >
              Применить
            </Button>
          </CustomTooltip>
        </ClickAwayListener>
      </Box>
      <Box className='flex items-center'>
        <Text>Отмечено</Text>&nbsp;
        <Typography className='font-bold'>
          {ids.size} / {count}
        </Typography>
      </Box>
    </Box>
  );
};
