import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";
import { CustomCheckbox } from "~/shared/components/CustomCheckbox";

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, "value"> & {
  value?: boolean;
  label?: string | null;
  withoutMobileStyles?: boolean;
  labelClassName?: string;
};

export const AppCheckbox: React.FC<Props> = ({
  label,
  withoutMobileStyles = false,
  labelClassName = "",
  className = "",
  disabled = false,
  ...props
}) => {
  return (
    <label
      className={clsx(
        "flex gap-2 items-center",
        { "hover:cursor-pointer": !disabled, "max-md:gap-1": !withoutMobileStyles },
        className
      )}
    >
      <div
        className={clsx("relative flex items-center justify-center w-6 h-6 p-[3px] shrink-0", {
          "max-md:w-5 max-md:h-5 max-md:p-[2.5px]": !withoutMobileStyles
        })}
      >
        <CustomCheckbox checked={props.checked} onChange={props.onChange} />
      </div>
      {label && (
        <span
          className={clsx(
            "text-base font-normal select-none mt-[1px] break-word",
            { "text-gray": disabled, "max-md:text-sm max-md:mt-0": !withoutMobileStyles },
            labelClassName
          )}
        >
          {label}
        </span>
      )}
    </label>
  );
};
