import { StatusesExpertise } from "~/shared/types/statusesExpertise";
import { useRole } from "./Role";

export const useCanPublish = (statusId: StatusesExpertise, moderatorsRoles: string[]) => {
  const { hasPermissions } = useRole();

  const canPublish =
    [StatusesExpertise.PUBLISHED_ID].includes(statusId) ||
    ([StatusesExpertise.REMOVED_FROM_PUBLICATION_ID, StatusesExpertise.ON_PUBLISHING_ID].includes(
      statusId
    ) &&
      hasPermissions(moderatorsRoles));

  return canPublish;
};
