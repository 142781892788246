import dayjs from "dayjs";

export const dateToISOString = (date?: Date) => {
  if (!dayjs(date).isValid()) {
    return undefined;
  }

  return date?.toISOString();
};

export const incrementDate = (dateString: string) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  const incrementedDateString = date.toISOString();

  return incrementedDateString;
};

export const incrementHours = (dateString: string, hours: number) => {
  const date = new Date(dateString);
  const oneHour = 3600 * 1000;
  date.setTime(date.getTime() + oneHour * hours);
  const incrementedDateString = date.toISOString();

  return incrementedDateString;
};
