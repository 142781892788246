import { prop } from "rambda";

export type FieldError = {
  field: string;
  message: string;
  code: string;
};

export type GraphqlError = {
  error: string;
  fields: FieldError[];
};

type Errors = unknown[] | undefined;

export function getFieldsErrors(errors: unknown): GraphqlError {
  const result: GraphqlError = {
    error: "unknown error",
    fields: []
  };

  const errs: Errors = prop("errors", prop("response", errors)) as Errors;

  if (!Array.isArray(errs)) {
    return result;
  }

  errs.forEach((err: unknown) => {
    if (err && typeof err === "object" && "message" in err && err?.message) {
      result.error = (err.message as string) ?? "";
    }

    const messages: Errors = prop(
      "message",
      prop("originalError", prop("extensions", err))
    ) as Errors;

    if (!Array.isArray(messages)) {
      return;
    }

    messages.forEach((fieldErr: unknown) => {
      const keys = Object.keys(prop("constraints", fieldErr) ?? {});
      keys.forEach((key) => {
        result.fields.push({
          field: prop("property", fieldErr) ?? "",
          message: prop(key, prop("constraints", fieldErr)) ?? "",
          code: key
        });
      });
    });
  });

  return result;
}
