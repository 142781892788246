export enum StatusesExpertise {
  DRAFT_ID = 1,
  ON_TECH_EXPERTISE_ID = 10,
  ON_CONTENT_EXPERTISE_ID = 20,
  ON_PUBLISHING_ID = 30,
  PUBLISHED_ID = 40,
  REMOVED_FROM_PUBLICATION_ID = 50,
  ON_MODERATION_ID = 60,
  DECLINED_ID = 5,
  DECLINED_NO_RETRY_ID = 2
}
