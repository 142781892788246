import { Box, ClickAwayListener } from "@mui/material";
import React from "react";
import { LinkButton } from "../LinkButton";
import { SaveButton } from "../SaveButton";
import { Text } from "../Text";
import { useModal } from "~/shared/hooks/useModal";
import { Button } from "../Button";
import { CustomTooltip } from "~/shared/components/CustomTooltip";

type Props = {
  backHref?: string;
  onBackClick?: () => void;
  isLoading?: boolean;
  formName?: string;
  hideSaveButton?: boolean;
  hasChanges?: { current: () => Promise<boolean> };
};

export const DetailsFormFooter: React.FC<Props> = ({
  hideSaveButton,
  onBackClick,
  isLoading,
  formName,
  hasChanges
}) => {
  const { open, handleOpen, handleClose } = useModal();

  const handleClick = () => {
    if (!hasChanges?.current) {
      onBackClick?.();
      return;
    }

    hasChanges?.current?.().then((isChanged) => {
      if (isChanged) {
        handleOpen();
        return;
      }

      onBackClick?.();
    });
  };

  const onSuccess = () => {
    onBackClick?.();
    handleClose();
  };

  return (
    <Box className='sticky w-full p-3 bottom-0 rounded-b-lg bg-white flex gap-2 justify-between border-t-2 z-[100]'>
      <Box className='flex gap-4 flex-1 md:flex-row flex-col'>
        <ClickAwayListener onClickAway={handleClose} touchEvent='onTouchStart'>
          <CustomTooltip
            disableHoverListener
            title={
              <Box className='flex flex-col gap-4'>
                <Text>Вы уверены, что хотите выйти без сохранения?</Text>
                <Box className='flex gap-6'>
                  <Button
                    className='px-2 w-full min-w-fit'
                    variant='outlined'
                    color='error'
                    onClick={handleClose}
                  >
                    No
                  </Button>

                  <Button
                    className='px-2 w-full min-w-fit'
                    variant='outlined'
                    color='success'
                    onClick={onSuccess}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            }
            open={!!open}
            arrow
          >
            <LinkButton
              className='order-first w-full md:w-fit'
              onClick={handleClick}
              variant='outlined'
            >
              Back
            </LinkButton>
          </CustomTooltip>
        </ClickAwayListener>

        <Box className='flex gap-2 md:ml-auto flex-wrap justify-end w-full md:w-fit'>
          {!hideSaveButton && (
            <SaveButton className='w-full md:w-fit' disabled={isLoading} formName={formName} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
