import { useState, useEffect, useCallback } from "react";

const storageKey = "__drawer-open";

export const useDrawer = () => {
  const [open, setOpen] = useState(() => {
    const storageValue = localStorage.getItem(storageKey);
    return Boolean(storageValue);
  });

  const handleToggle = useCallback(() => {
    setOpen((value) => !value);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    localStorage.setItem(storageKey, open ? "1" : "");
  }, [open]);

  return {
    open,
    handleToggle,
    handleClose
  };
};
