import { List, ListItem } from "@mui/material";
import React, { SyntheticEvent, useCallback, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePaths } from "~/app/providers/Paths";
import { HeaderTab } from "~/shared/components/HeaderTab";

export const HeaderTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [activePath, setActivePath] = useState("/");

  const history = useNavigate();

  const location = useLocation();

  const paths = usePaths();

  const pathsResolve = paths.reduce((res, tab, i) => {
    if (tab.path) {
      res[tab.path] = i;
    }

    tab.children?.forEach((child) => {
      if (!child.path) {
        return;
      }
      res[child.path] = i;
    });

    return res;
  }, Object.create(null));

  const handleTabChange = useCallback(
    (_: SyntheticEvent<Element, Event> | null, value: number) => {
      setActiveTab(value);

      if (!paths[value].path) {
        return;
      }

      history(paths[value].path ?? "");
    },
    [history, setActiveTab, paths]
  );

  const handleSelectTab = useCallback(
    (value: number) => {
      handleTabChange(null, value);
    },
    [handleTabChange]
  );

  useLayoutEffect(() => {
    const path = location.pathname.match(/^\/([^?/]*)/g)?.[0] ?? location.pathname;
    const initialValue = pathsResolve[path];

    setActiveTab(initialValue);
    setActivePath(path);
  }, [location.pathname, setActiveTab, paths, pathsResolve]);

  return (
    <List className='flex flex-col gap-2'>
      {paths.map((tab, i) => (
        <ListItem key={tab.id} disablePadding className='grow w-full'>
          <HeaderTab
            key={tab.id}
            activePath={activePath}
            activeTab={activeTab === i}
            value={i}
            tab={tab}
            handleSelect={handleSelectTab}
          />
        </ListItem>
      ))}
    </List>
  );
};
