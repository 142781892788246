import React from "react";

export const MpvdIcon = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.5 11.75C18.59 8.94 21.5 6.31 21.5 4.05C21.5 2.2 20.05 0.75 18.2 0.75C17.16 0.75 16.15 1.24 15.5 2C14.84 1.24 13.84 0.75 12.8 0.75C10.95 0.75 9.5 2.2 9.5 4.05C9.5 6.31 12.41 8.94 15.5 11.75ZM12.8 2.75C13.24 2.75 13.69 2.96 13.98 3.3L15.5 5.09L17.02 3.3C17.31 2.96 17.76 2.75 18.2 2.75C18.94 2.75 19.5 3.31 19.5 4.05C19.5 5.17 17.46 7.22 15.5 9.04C13.54 7.22 11.5 5.16 11.5 4.05C11.5 3.31 12.06 2.75 12.8 2.75Z'
        fill='#5229DD'
      />
      <path
        d='M18.5 14.75H16.5C16.5 13.55 15.75 12.47 14.63 12.05L8.47 9.75H0.5V20.75H6.5V19.31L13.5 21.25L21.5 18.75V17.75C21.5 16.09 20.16 14.75 18.5 14.75ZM2.5 18.75V11.75H4.5V18.75H2.5ZM13.47 19.16L6.5 17.23V11.75H8.11L13.93 13.92C14.27 14.05 14.5 14.38 14.5 14.75C14.5 14.75 12.51 14.7 12.2 14.6L9.82 13.81L9.19 15.71L11.57 16.5C12.08 16.67 12.61 16.76 13.15 16.76H18.5C18.89 16.76 19.24 16.99 19.4 17.32L13.47 19.16Z'
        fill='#5229DD'
      />
    </svg>
  );
};
