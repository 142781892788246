import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { DetailsHead } from "~/shared/components/DetailsHead";
import { Panel } from "~/shared/components/Panel";
import { PageWrapper } from "~/shared/components/PageWrapper";
import { useNavigationBack } from "~/shared/hooks/useBackClick";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { usePostgraduatesByIdQuery, usePostgraduatesDeleteMutation } from "~/generated/graphql";
import { DetailsFormFooter } from "~/shared/components/DetailsFormFooter";
import { PostgraduateDetailsForm } from "~/modules/PostgraduatesDetailForm";
import { useRole } from "~/entities/Roles";
import { useChangesRef } from "~/shared/hooks/useChangesRef";

export const PostgraduatesEdit: React.FC = () => {
  const { hasPermissions } = useRole();
  const mayDelete = hasPermissions(["all", "management_content_science"]);
  const { id } = useParams<{ id: string }>();

  const isEdit = Number.isInteger(Number(id));

  const goBack = useNavigationBack();

  const client = useGraphqlClient();

  const { isSaveLoading } = usePageLoadingStore((state) => ({
    isSaveLoading: state.isSaveLoading
  }));

  const { mutateAsync: deletePostgraduate } = usePostgraduatesDeleteMutation(client, {
    onSuccess: goBack
  });

  const { data } = usePostgraduatesByIdQuery(client, { id: Number(id) }, { enabled: isEdit });

  const { setRef, hasChangesCallback } = useChangesRef();

  const handleDelete = () => {
    if (!id) {
      return;
    }

    deletePostgraduate({ id: Number(id) });
  };

  return (
    <PageWrapper>
      <Panel>
        <Box className='flex p-3 flex-col gap-6 items-center'>
          <DetailsHead
            title={isEdit ? "Редактирование аспирантуры" : "Создание аспирантуры"}
            onBackClick={goBack}
            onRemove={isEdit && mayDelete ? handleDelete : undefined}
            formName='newsForm'
            isLoading={isSaveLoading}
            hasChanges={hasChangesCallback}
            clientUrl={
              data?.postgraduatesById?.published
                ? `science/postgraduates/${data?.postgraduatesById?.slug}`
                : ""
            }
          />
          <PostgraduateDetailsForm id={Number(id)} formName='newsForm' changesGetterRef={setRef} />
        </Box>
        <DetailsFormFooter
          onBackClick={goBack}
          formName='newsForm'
          isLoading={isSaveLoading}
          hasChanges={hasChangesCallback}
        />
      </Panel>
    </PageWrapper>
  );
};
