import React from "react";
import { FiltersModel } from "~/generated/graphql";
import { TParams } from "../../Filters";
import { MultipleFilter } from "../MultipleFilter";
import clsx from "clsx";

type Props = {
  params: TParams | null;
  filters?: FiltersModel[] | null;
  setFilters: (filters: TParams | ((newFilters: TParams | null) => TParams | null) | null) => void;
  rangeDateOptions?: {
    startDatePlaceholder: string;
    endDatePlaceholder: string;
    startDateKey: string;
    endDateKey: string;
  };
  withoutLine?: boolean;
};

type PropsAppLine = {
  className?: string;
};

export const AppLine: React.FC<PropsAppLine> = ({ className }) => {
  return <div className={clsx("w-full h-[2px] bg-gray-4 rounded-full", className)} />;
};

export const FiltersContent: React.FC<Props> = ({
  params,
  filters,
  setFilters,
  withoutLine = false
}) => {
  if (!filters || filters.length === 0) {
    return null;
  }

  return (
    <>
      {filters?.map((filter) => {
        const filterOptions = filter.options || [];

        return filterOptions.length !== 0 ? (
          <React.Fragment key={filter.field}>
            <MultipleFilter
              className='pt-4'
              key={filter.name}
              inputType={filter.type || ""}
              options={filterOptions}
              queryKey={filter.field as keyof TParams}
              title={filter.name || ""}
              plural={filter.plural || ""}
              params={params}
              onCategoryChange={setFilters}
            />
            {!withoutLine && <AppLine />}
          </React.Fragment>
        ) : null;
      })}
    </>
  );
};
