import React, { PropsWithChildren } from "react";
import { ProtectedRoutes, ProtectedRoutesProps } from "~/modules/ProtectedRoutes";
import { BaseLayout } from "./BaseLayout";

export const BaseProtectedLayout: React.FC<PropsWithChildren<ProtectedRoutesProps>> = ({
  children,
  permissions,
  route
}) => {
  return (
    <ProtectedRoutes permissions={permissions} route={route}>
      <BaseLayout>{children}</BaseLayout>
    </ProtectedRoutes>
  );
};
