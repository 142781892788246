import { compose, prop } from "rambda";
import { useCallback, useState } from "react";

export const useSelected = (total: number, rows: { id?: number | null }[]) => {
  const [selected, setSelected] = useState<Set<number>>(new Set([]));

  const indeterminate = selected.size > 0 && selected.size < total;

  const allChecked = total > 0 && selected.size === total;

  const getCheckedHandler = useCallback(
    (id: number) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSelected((cur) => {
        const newSelected = new Set(cur);

        if (checked) {
          newSelected.add(id);
        } else {
          newSelected.delete(id);
        }

        return newSelected;
      });
    },
    []
  );
  const clearSelected = useCallback(() => {
    setSelected(new Set([]));
  }, []);

  const onSelectAllClick = useCallback(() => {
    if (allChecked) {
      clearSelected();
      return;
    }

    setSelected(new Set(rows?.map(compose(Number, prop("id"))) ?? []));
  }, [allChecked, rows, clearSelected]);

  // useEffect(() => {
  //   const rowIds = new Set(rows?.map((row) => row?.id));
  //   setSelected((currentSelected) => {
  //     const newSelected = new Set(currentSelected);

  //     currentSelected.forEach((selecetedId) => {
  //       if (!rowIds.has(selecetedId)) {
  //         newSelected.delete(selecetedId);
  //       }
  //     });

  //     return newSelected;
  //   });
  // }, [rows]);

  return {
    selected,
    setSelected,
    indeterminate,
    allChecked,
    hasChecked: selected.size,
    getCheckedHandler,
    onSelectAllClick,
    clearSelected
  };
};
