import React, { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import {
  ScientificJournalsModel,
  useScientificJournalsByIdQuery,
  useScientificJournalsDeclineMutation,
  useScientificJournalsPublishMutation,
  useScientificJournalsUpsertMutation,
  useScientificJournalssSendToModerateMutation
} from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { TabsForm } from "~/entities/TabsForm";
import { initFormValues } from "~/shared/lib/initFormValues";
import { useNavigationBack } from "~/shared/hooks/useBackClick";
import { GeneralForm } from "./components/GeneralForm";
import { prepareFormData } from "./lib/prepareFormData";
import { either, has, isEmpty } from "rambda";
import { useAlertsStore } from "~/shared/stores/alerts";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { useRole } from "~/entities/Roles";
import { Loader } from "~/shared/components/Loader";
import { useValidationError } from "~/shared/hooks/useValidationError";
import { isFullData } from "~/shared/lib/isFullData";
import { useInputStatus } from "~/shared/hooks/useInputStatus";
import { useCanEditByStatus } from "~/entities/Roles/useCanEditOnModeration";
import { getBaseRefInitializer } from "~/shared/hooks/useChangesRef";

type Props = {
  id?: number;
  formName?: string;
  changesGetterRef?: (callback: () => Promise<boolean>) => void;
};

export const JournalsDetailForm: React.FC<Props> = ({ id, formName, changesGetterRef }) => {
  const [step, setStep] = useState(0);

  const isCreateMode = !Number.isInteger(id);

  const client = useGraphqlClient();

  const { setIsSaveLoading } = usePageLoadingStore((state) => ({
    setIsSaveLoading: state.setIsSaveLoading
  }));
  const addAlert = useAlertsStore((state) => state.addAlert);

  const { mutateAsync: sendToModerate } = useScientificJournalssSendToModerateMutation(client);
  const { mutateAsync: decline } = useScientificJournalsDeclineMutation(client);
  const { mutateAsync: publish } = useScientificJournalsPublishMutation(client);

  const { data, refetch } = useScientificJournalsByIdQuery(
    client,
    { id: Number(id) },
    {
      enabled: !isCreateMode,
      refetchOnMount: "always",
      cacheTime: 0
    }
  );

  const initialValues = data?.scientificJournalsById as ScientificJournalsModel;

  const goBack = useNavigationBack();

  const formMethods = useForm({ mode: "all" });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    watch
  } = formMethods;

  const onError = useValidationError(setError);

  const { mutateAsync: updateScientificJournal, isLoading } = useScientificJournalsUpsertMutation(
    client,
    {
      onSuccess: goBack,
      onError
    }
  );

  const values = data?.scientificJournalsById;

  const isGeneralFormError = either(has("name"), has("content"))(errors);

  changesGetterRef?.(() =>
    getBaseRefInitializer({
      isCreate: isCreateMode,
      value: prepareFormData(values as never),
      newValue: prepareFormData(getValues())
    })
  );

  const { hasPermissions } = useRole();

  const { prepareInputStatus, setInitialValue } = useInputStatus({
    isModerator: hasPermissions(["moderator_content_science"]),
    currentStatusId: initialValues?.status?.id
  });

  const onSubmit = handleSubmit((newValues) => {
    prepareFormData(newValues, values, { isCreateMode }).then((input) =>
      updateScientificJournal({ input: prepareInputStatus(input) })
    );
  });

  const getIsFullDataFunction = (value: unknown): boolean => {
    return isFullData(value, ["rejectReason", "publishedAt", "specialties"]);
  };

  const canEdit =
    useCanEditByStatus({
      isModerator: hasPermissions(["moderator_content_science"]),
      currentStatusId: initialValues?.status?.id
    }) && !watch("published");

  const forms = useMemo(
    () => [
      {
        tabTitle: "General data",
        hasErrors: isGeneralFormError,
        component: <GeneralForm initialValues={initialValues} canEdit={canEdit} />
      }
    ],
    [isGeneralFormError, initialValues, canEdit]
  );

  useEffect(() => {
    setValue("publishedAt", isCreateMode ? dayjs().toISOString() : values?.publishedAt ?? null);
    if (isCreateMode) {
      return;
    }

    initFormValues(
      [
        "name",
        "published",
        "image",
        "publishedAt",
        "accessFormat",
        "inVAK",
        "inRINC",
        "organizer",
        "inScopus",
        "inWebOfScience",
        "inOtherDatabases"
      ],
      setValue,
      values
    );

    setValue("specialties", values?.specialties ?? []);

    prepareFormData(getValues(), values, { isCreateMode }).then(setInitialValue);
  }, [values, isCreateMode, setValue, getValues]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      addAlert("error", "Fill in all required fields");
    }
  }, [errors, step, addAlert]);

  useEffect(() => {
    setIsSaveLoading(isLoading);
  }, [isLoading, setIsSaveLoading]);
  const getIsFullData = getIsFullDataFunction(initialValues);

  if (!isCreateMode && !values) {
    return <Loader />;
  }

  return (
    <FormProvider {...formMethods}>
      <TabsForm
        handleSubmit={onSubmit}
        handleStepChange={setStep}
        activeStep={step}
        formName={formName}
        forms={forms}
        initialValues={initialValues}
        sendToModerate={sendToModerate}
        decline={decline}
        refetch={refetch}
        publish={publish}
        isFullData={getIsFullData}
        sectionModeratorRole='moderator_content_science'
        canNotSendToModerate={!(hasPermissions(["management_content_science"]) && getIsFullData)}
      />
    </FormProvider>
  );
};
