import clsx from "clsx";
import React from "react";
import {
  Maybe,
  PracticesCategoriesModel,
  usePracticesCategoriesQuery,
  usePracticesCategoriesUpsertMutation
} from "~/generated/graphql";
import { FormActions } from "./components/FormActions";
import { Table } from "./components/Table";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { DeepPartial, useFormContext } from "react-hook-form";
import { useAlertsStore } from "~/shared/stores/alerts";
import { Box } from "@mui/material";

type Props = {
  className?: string;
  refetch?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
};

export const CategoriesForm: React.FC<Props> = ({ className, refetch, isLoading, disabled }) => {
  const { watch, setValue } = useFormContext();

  const state: Maybe<DeepPartial<PracticesCategoriesModel>> = watch("category");

  const client = useGraphqlClient();

  const { data, refetch: refetchItems } = usePracticesCategoriesQuery(client);

  const addAlert = useAlertsStore((state) => state.addAlert);

  const items = data?.practicesCategories?.data ?? [];

  const { mutateAsync: upsert } = usePracticesCategoriesUpsertMutation(client);

  const addItem = (input: any): Promise<void> => {
    if (input?.id) {
      setValue("category", input);
      return Promise.resolve();
    }

    return upsert({ input: { ...input } })
      .then((data): void => {
        addAlert("success", "Успешно");
        setValue("category", { ...input, id: data?.practicesCategoriesUpsert?.id as number });
        refetch?.();
        refetchItems();
      })
      .catch(() => {
        addAlert("error", "Ошибка");
      });
  };

  const removeItem = () => {
    setValue("category", null);
  };

  return (
    <Box
      sx={{ pointerEvents: disabled ? "none" : "all" }}
      className={clsx("flex flex-col gap-8", className)}
    >
      <div className='flex items-center justify-between'></div>

      <div className='card-base flex flex-col gap-8'>
        <div className='flex max-md:flex-col items-start justify-between max-md:gap-4 '>
          <div className='flex md:gap-6 gap-4 max-md:flex-col max-md:w-full'>
            <FormActions items={items} addItem={addItem} refetch={refetchItems} />
          </div>
        </div>
        <Table
          items={[state]?.filter(Boolean) as Maybe<DeepPartial<PracticesCategoriesModel>>[]}
          addItem={addItem}
          onRemove={removeItem}
          isLoading={!!isLoading}
        />
      </div>
    </Box>
  );
};
