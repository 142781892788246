import { Box, CircularProgress, Icon, Input, InputProps } from "@mui/material";
import React, { ChangeEvent, forwardRef, useId, useState, DragEvent } from "react";
import { Text } from "../Text";
import { CloudUploadIcon } from "~shared/components/Icons";
import { clsx } from "clsx";

type Props = {
  loading: boolean;
  onChange: (files?: File[] | null) => void;
} & Omit<InputProps, "onChange">;

export const MultipleFilesInput = forwardRef<HTMLDivElement, Props>(
  ({ loading, onChange, className, ...other }, ref) => {
    const [dragActive, setDragActive] = useState(false);

    const id = useId();

    const handlePrepareFiles = (files: FileList | null) => {
      if (!files) {
        return [];
      }

      // const allowedExtensions = /(\.mp4)$/i;
      return Array.from(files).reduce((res: File[], file) => {
        // if (allowedExtensions.exec(file.name)) {
        //   addAlert("error", `${t("Image format invalid")} ${file.name}`);
        //   return res;
        // }

        res.push(file);
        return res;
      }, []);
    };

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(handlePrepareFiles(e.target.files));
      e.target.value = "";
    };

    const handleDragLeave = () => {
      setDragActive(false);
    };

    const handleDragOver = (e: DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(true);
    };

    const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      onChange(handlePrepareFiles(e.dataTransfer.files));
    };

    return (
      <Box
        ref={ref}
        className={clsx(
          className,
          "h-[200px] rounded-xl border border-dashed border-primary relative overflow-hidden"
        )}
      >
        <Input
          inputProps={{
            multiple: true
          }}
          className='!sr-only'
          type='file'
          id={id}
          onChange={onChangeInput}
          {...other}
        />
        {!loading && (
          <label
            htmlFor={id}
            className={clsx(
              "h-full flex flex-col items-center gap-4 cursor-pointer p-9 transition hover:bg-gray-200 active:bg-gray-300",
              { "bg-gray-300": dragActive }
            )}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <Icon className='w-[71px] h-[71px]' component={CloudUploadIcon} />
            <Text className='lg:w-[220px] text-center font-medium text-base'>
              Upload or drop file
            </Text>
          </label>
        )}
        {loading && (
          <Box className='absolute inset-0 flex justify-center items-center'>
            <CircularProgress />
          </Box>
        )}
      </Box>
    );
  }
);

MultipleFilesInput.displayName = "MultipleFilesInput";
