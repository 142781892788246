import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  TextareaAutosize
} from "@mui/material";
import { PracticesEventsModel } from "~/generated/graphql";
import { Text } from "~/shared/components/Text";
import { getErrorMessage } from "~/shared/lib/getError";
import { HelperText } from "~/shared/components/HelperText";
import { DeepPartial } from "react-hook-form";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => Promise<void>;
  title: string;
  initialValue?: DeepPartial<PracticesEventsModel> | null;
};

export const CreateModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  initialValue
}) => {
  const [values, setValues] = useState<DeepPartial<PracticesEventsModel> | null>();
  const [errors, setErrors] = useState<Record<string, { message: string }>>({});

  const getError = getErrorMessage(errors);

  const create = () => {
    const errors = Object.entries(values ?? {}).map(([key, value]) => {
      if (["name", "description"].includes(key) && !value) {
        return [key, "Обязательное поле"];
      }

      return;
    });

    if (errors?.filter(Boolean)?.length) {
      errors?.map((error) =>
        setErrors((errors) => ({ ...errors, [error?.[0] ?? ""]: { message: error?.[1] ?? "" } }))
      );
      return;
    }

    onSubmit(values)
      .then(onClose)
      .then(() => setValues(null));
  };

  useEffect(() => {
    setValues(initialValue);
  }, [initialValue]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          height: "50vh",
          overflow: "auto",
          width: 600
        }}
      >
        <Typography id='modal-title' variant='h6' component='h2' marginBottom={2}>
          {title}
        </Typography>

        <div className='flex flex-col gap-4 mt-4'>
          <FormControl fullWidth>
            <TextField
              label={<Text>Название</Text>}
              error={!!getError("name")}
              value={values?.name}
              onChange={(e) => {
                setErrors((cur) => {
                  delete cur.name;
                  return { ...cur };
                });
                setValues((cur) => ({ ...cur, name: e.target.value }));
              }}
            />
            <HelperText error={getError("name")} />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label={<Text>Описание</Text>}
              error={!!getError("description")}
              value={values?.description}
              multiline
              onChange={(e) => {
                setErrors((cur) => {
                  delete cur.description;
                  return { ...cur };
                });
                setValues((cur) => ({ ...cur, description: e.target.value }));
              }}
              InputProps={{
                inputComponent: TextareaAutosize
              }}
            />
            <HelperText error={getError("description")} />
          </FormControl>
        </div>

        <Box marginTop={2} sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
          <Button variant='outlined' color='primary' onClick={onClose}>
            Закрыть
          </Button>
          <Button variant='contained' color='primary' onClick={create}>
            {initialValue ? "Сохранить" : "Создать"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
