import { AlertColor, Box } from "@mui/material";
import React, { PropsWithChildren, ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import { FilesModel } from "~/generated/graphql";
import { ImageInput } from "~shared/components/ImageInput";
import { getImageSrc } from "~shared/lib/getImageSrc";

type ImageFormFields = {
  image?: Partial<FilesModel>;
  imageDetail?: Partial<FilesModel>;
};

type Props = {
  control?: Control<ImageFormFields, unknown>;
  onChange: (file?: File | null, isDetail?: boolean) => void;
  onDelete: (isDetail?: boolean) => void;
  addAlert: (severity: AlertColor, message: string) => void;
  asideChildren?: ReactNode | undefined;
  single?: boolean;
  shouldShowFirstImage?: boolean;
  imageFieldName?: string;
  disabled?: boolean;
  imageProps?: {
    title: string;
    name: string;
  };
};

export const ImageForm: React.FC<PropsWithChildren<Props>> = ({
  control,
  onChange,
  onDelete,
  addAlert,
  children,
  single,
  imageFieldName = "imageDetail",
  disabled = false,
  imageProps = { title: "Изображение в списке", name: "image" },
  asideChildren,
  shouldShowFirstImage = true
}) => {
  return (
    <div className='flex flex-col lg:flex-row gap-10'>
      <div className='grow space-y-10 max-lg:order-last'>{children}</div>
      <div className='w-full space-y-2 lg:w-[300px] lg:shrink-0'>
        {shouldShowFirstImage && (
          <>
            <span>{imageProps.title}</span>
            <Controller
              control={control}
              name={imageProps.name as "image"}
              render={({ field }) => (
                <ImageInput
                  disabled={disabled}
                  addAlert={addAlert}
                  url={getImageSrc(field?.value) ?? ""}
                  value={field?.value}
                  onUpdate={field.onChange}
                  onChange={(image: any) => {
                    onChange(image);
                  }}
                  onDelete={onDelete}
                />
              )}
            />
          </>
        )}
        {!single && (
          <Box sx={{ mt: 2 }}>
            <div style={{ marginBottom: "12px" }}>Изображение для карточки</div>
            <Controller
              control={control}
              name={imageFieldName as "imageDetail"}
              render={({ field }) => {
                return (
                  <ImageInput
                    disabled={disabled}
                    addAlert={addAlert}
                    url={getImageSrc(field?.value) ?? ""}
                    value={field?.value}
                    onUpdate={field.onChange}
                    onChange={(image: any) => {
                      onChange(image, true);
                    }}
                    onDelete={() => onDelete(true)}
                  />
                );
              }}
            />
          </Box>
        )}
        {asideChildren}
      </div>
    </div>
  );
};
