import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "~/shared/stores/auth";

class MoodleClient {
  private readonly baseUrl: string;
  private readonly apikey: string;

  constructor({ baseUrl, apikey }: { baseUrl: string; apikey: string }) {
    this.baseUrl = baseUrl;
    this.apikey = apikey;
  }

  request<Params extends Record<string, string>, Return = unknown>(
    path: `/${string}.php`,
    {
      withApiKey,
      params,
      headers
    }: { withApiKey?: boolean; params?: Params; headers?: Record<string, string> } = {}
  ): Promise<Return> {
    let url = `${this.baseUrl}${path}`;
    const searchParams = new URLSearchParams({
      ...params,
      ...(Boolean(withApiKey) && { apikey: this.apikey })
    }).toString();

    if (searchParams) {
      url += `?${searchParams}`;
    }
    return fetch(url, { headers })
      .then((res) => {
        try {
          return res.json();
        } catch (e) {
          return { data: "" };
        }
      })
      .catch(console.log);
  }
}

const moodleClient = new MoodleClient({
  baseUrl: process.env.REACT_APP_MOODLE_URL ?? "",
  apikey: process.env.REACT_APP_MOODLE_API_KEY ?? ""
});

export const useGetUserCoursesQuery = (params: { id: string }) =>
  useQuery(["useGetUserCoursesQuery", params], () =>
    moodleClient.request("/local/simpleapi/get_user_courses.php", {
      withApiKey: true,
      params: { ...params, field: "idnumber", value: params.id }
    })
  );

export const useGetUserCourseModules = (params: { id: string }) =>
  useQuery(["useGetUserCourseModules", params], () =>
    moodleClient.request("/local/simpleapi/get_course_modules.php", {
      withApiKey: true,
      params: { ...params, field: "idnumber", value: params.id }
    })
  );

export const useGetCourse = (
  params: { id: string },
  { enabled, permanentCache }: { enabled: boolean; permanentCache?: boolean }
) =>
  useQuery(
    ["useGetCourse", params],
    () =>
      moodleClient.request("/local/simpleapi/get_course.php", {
        withApiKey: true,
        params: { ...params, field: "idnumber", value: params.id }
      }),
    {
      enabled,
      ...(permanentCache
        ? { cacheTime: Number.MAX_SAFE_INTEGER, staleTime: Number.MAX_SAFE_INTEGER }
        : {})
    }
  );

export const fetchMoodleAuth = () =>
  fetch(`${process.env.REACT_APP_REST_API_URL}/auth`, {
    headers: {
      Authorization: `Bearer ${useAuthStore.getState().token}`
    }
  });

export const useGetMoodleAuth = ({ enabled } = { enabled: false }) =>
  useQuery(["useGetMoodleAuth"], fetchMoodleAuth, {
    enabled: enabled || !!useAuthStore.getState().token,
    staleTime: 10 * 1000 * 60
  });
