import React, { useId, useState } from "react";
import { DeepPartial } from "react-hook-form";
import { useModal } from "~/shared/hooks/useModal";
import { PracticesCategoriesModel, Maybe } from "~/generated/graphql";
import { Rows } from "../Rows";
import { CreateModal } from "../CreateModal";

type Props = {
  items: Maybe<DeepPartial<PracticesCategoriesModel>>[];
  addItem: (input: any) => Promise<void>;
  onRemove: (id: number) => void;
  isLoading: boolean;
};

export const Table: React.FC<Props> = ({ items, addItem, isLoading, onRemove }) => {
  const [activeRow, setActiveRow] = useState<DeepPartial<PracticesCategoriesModel> | null>(null);

  const { open, handleOpen, handleClose } = useModal();

  const onCLose = () => {
    setActiveRow(null);
    handleClose();
  };

  const keyData = useId();

  const handleUpdate = async (formData: any) => {
    await addItem(formData).then(() => {
      onCLose();
    });
  };

  const openModalHandler = (item: DeepPartial<PracticesCategoriesModel> | null) => {
    setActiveRow(item);
    handleOpen();
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-1'>
        <div className='flex gap-3 text-base font-medium items-center'>
          <div className='w-[324px] max-md:hidden text-center'>Название</div>
        </div>
      </div>

      <Rows
        key={keyData}
        items={items}
        isLoading={isLoading}
        openModal={openModalHandler}
        removeHandler={onRemove}
      />
      <CreateModal
        isOpen={!!open}
        onClose={onCLose}
        initialValue={activeRow}
        onSubmit={handleUpdate}
        title='Редактировать'
      />
    </div>
  );
};
