import React from "react";
import { clsx } from "clsx";
import { useLocation } from "react-router-dom";
import { usePaths } from "~/app/providers/Paths";
import { DrawerHeader } from "./components/DrawerHeader";
import { DrawerList } from "./components/DrawerList";
import { DrawerToggle } from "./components/DrawerToggle";
import { getFirstSegmentUrl } from "~shared/lib/getFirstSegmentUrl";
import { useDrawer } from "./hooks/useDrawer";

type Props = {
  className?: string;
};

export const AppDrawer: React.FC<Props> = ({ className = "" }) => {
  const { open, handleToggle } = useDrawer();

  const paths = usePaths();

  const width = open ? 280 : 70;

  const location = useLocation();

  const activePathName = getFirstSegmentUrl(location.pathname) ?? location.pathname;

  return (
    <div
      className={clsx(
        "flex flex-col gap-1 bg-white transition-[width] duration-300 relative shadow pt-[10px]",
        className
      )}
      style={{ width }}
    >
      <DrawerHeader
        className={clsx("transition-transform duration-300", {
          "-translate-y-[6px]": open
        })}
      />
      <DrawerList paths={paths} open={open} activePath={activePathName} />
      <DrawerToggle
        open={open}
        onClick={handleToggle}
        className='absolute top-[20px] left-[calc(100%+10px)]'
      />
    </div>
  );
};
