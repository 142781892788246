import React from "react";
import { Control, Controller } from "react-hook-form";
import { ConnectedFilesInput } from "~/entities/ConnectedFilesInput";
import { DigitalResourcesModel, FilesModel, Maybe } from "~/generated/graphql";

type GalleryFormFields = {
  "documentsGallery.files": Partial<FilesModel>[];
};

type Props = {
  isNew?: boolean;
  control?: Control<GalleryFormFields, unknown>;
  initialValues?: Maybe<DigitalResourcesModel>;
  disabled?: boolean;
};

export const FilesForm: React.FC<Props> = ({ control, initialValues, disabled }) => {
  return (
    <Controller
      control={control}
      defaultValue={initialValues?.documentsGallery?.files as undefined}
      name='documentsGallery.files'
      render={({ field }) => (
        <ConnectedFilesInput
          value={field?.value ?? []}
          onChange={field.onChange}
          disabled={disabled}
        />
      )}
    />
  );
};
