import React, { useMemo } from "react";
import { ConfigType } from "dayjs";
import { TitleValue } from "~shared/components/TitleValue";
import { formatDateToHumanReadable } from "~shared/lib/formatDate";

type Props = {
  date: ConfigType;
  title: string;
  className?: string;
};

export const DateTitleValue: React.FC<Props> = ({ date, title, className = "" }) => {
  const formattedDate = useMemo(() => formatDateToHumanReadable(date), [date]);

  return <TitleValue title={title} value={formattedDate} className={className} />;
};
