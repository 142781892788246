import { Box, ButtonProps, ClickAwayListener } from "@mui/material";
import React from "react";
import { Button } from "../Button";
import { SaveIcon } from "../Icons";
import { useModal } from "~/shared/hooks/useModal";
import { Text } from "../Text";
import clsx from "clsx";
import { CustomTooltip } from "~/shared/components/CustomTooltip";

type Props = ButtonProps & {
  formName?: string;
};

export const SaveButton: React.FC<Props> = ({ formName, ...props }) => {
  const { open, handleOpen, handleClose } = useModal();

  const ref = React.useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    handleOpen();
  };

  const onSuccess = () => {
    ref?.current?.click?.();
    handleClose();
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose} touchEvent='onTouchStart'>
        <CustomTooltip
          disableHoverListener
          title={
            <Box className='flex flex-col gap-4'>
              <Text>Вы уверены, что хотите сохранить?</Text>
              <Box className='flex gap-6'>
                <Button
                  className='px-2 w-full min-w-fit'
                  variant='outlined'
                  color='error'
                  onClick={handleClose}
                >
                  No
                </Button>

                <Button
                  className='px-2 w-full min-w-fit'
                  variant='outlined'
                  color='success'
                  onClick={onSuccess}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          }
          open={!!open}
          arrow
        >
          <Button
            startIcon={<SaveIcon />}
            variant='contained'
            color='primary'
            {...props}
            onClick={(e) => {
              handleClick();
              props?.onClick?.(e);
            }}
            className={clsx(props.className)}
          >
            Save
          </Button>
        </CustomTooltip>
      </ClickAwayListener>
      <Button
        ref={ref}
        startIcon={<SaveIcon />}
        type='submit'
        variant='contained'
        color='primary'
        form={formName}
        {...props}
        className={"hidden"}
      >
        Save
      </Button>
    </>
  );
};
