import React from "react";
import { Link } from "react-router-dom";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { LabsModel, useVacanciesQuery } from "~/generated/graphql";
import { getImageSrc } from "~/shared/lib/getImageSrc";
import { VacancyPageEdit } from "~/shared/routes";
import styles from "./Vacancy.module.scss";
import clsx from "clsx";

type Props = {
  initialValues?: Partial<LabsModel>;
};

export const VacancyTab: React.FC<Props> = ({ initialValues }) => {
  const client = useGraphqlClient();

  const { data } = useVacanciesQuery(client, {
    where: { laboratoryId: { eq: initialValues?.id } }
  });

  const vacancies = data?.vacancies?.data ?? [];

  return (
    <div className='flex gap-4 flex-wrap items-stretch'>
      {vacancies?.map((vacancy) => (
        <Link
          key={vacancy?.id}
          className='flex gap-4 w-full border rounded-md p-2'
          to={`${VacancyPageEdit}`.replace(":id", String(vacancy?.id))}
        >
          {vacancy?.image && (
            <div className='w-[250px] h-fit'>
              <img className='h-auto w-full' src={getImageSrc(vacancy?.image) ?? ""} />
            </div>
          )}

          <div className={clsx("flex flex-col gap-1", styles.description)}>
            <span className='hover:underline text-xl'>
              <span className='font-semibold'>{vacancy?.id}. </span>
              {vacancy?.name}
            </span>

            {vacancy?.salary && (
              <div>
                <span>Зарплата: </span>
                <span>{vacancy?.salary}</span>
              </div>
            )}
            {vacancy?.experience && (
              <div>
                <span>Опыт работы: </span>
                <span dangerouslySetInnerHTML={{ __html: vacancy?.experience }} />
              </div>
            )}
            {vacancy?.conditions && (
              <div>
                <span>Условия: </span>
                <span dangerouslySetInnerHTML={{ __html: vacancy?.conditions }} />
              </div>
            )}
            {vacancy?.responsibilities && (
              <div>
                <span>Обязанности: </span>
                <span dangerouslySetInnerHTML={{ __html: vacancy?.responsibilities }} />
              </div>
            )}
            {vacancy?.requirements && (
              <div>
                <span>Требования: </span>
                <span dangerouslySetInnerHTML={{ __html: vacancy?.requirements }} />
              </div>
            )}
          </div>
        </Link>
      ))}
    </div>
  );
};
