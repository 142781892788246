import React, { ReactNode } from "react";
import { Box, Tab, List, ListItem, Collapse } from "@mui/material";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { Text } from "~/shared/components/Text";

export type HeaderTab = {
  id: number;
  label: ReactNode;
  path?: string;
  children?: HeaderTab[];
};

type HeaderTabProps = {
  tab: HeaderTab;
  value: number;
  handleSelect?: (value: number) => void;
  activePath?: string;
  activeTab?: boolean;
};

export const HeaderTab: React.FC<HeaderTabProps> = ({
  tab,
  value,
  handleSelect,
  activePath,
  activeTab
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const open = Boolean(anchorEl);

  const id = open ? String(tab.id) : undefined;

  const itemClassName = clsx("text-[16px] leading-[19px] text-left", {
    "text-primary": activeTab,
    "text-secondaryText": !activeTab
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = () => {
    handleClose();
    handleSelect?.(value);
  };

  if (!tab.children) {
    return (
      <Tab
        className='w-full text-left items-start'
        sx={{ opacity: 1 }}
        onClick={handleItemClick}
        value={value}
        label={
          typeof tab.label === "string" ? (
            <Text className={itemClassName}>{tab.label}</Text>
          ) : (
            <Box className={itemClassName}>{tab.label}</Box>
          )
        }
      />
    );
  }

  return (
    <Box className='grow'>
      <Tab
        sx={{ opacity: 1 }}
        className='flex w-full'
        onClick={handleClick}
        id={id}
        aria-controls={id}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        disableRipple
        value={value}
        label={
          <Box className='flex gap-4 w-full'>
            {typeof tab.label === "string" ? (
              <Text className={itemClassName}>{tab.label}</Text>
            ) : (
              <Box className={itemClassName}>{tab.label}</Box>
            )}
            <ExpandMoreIcon
              className={clsx(
                "transition-transform duration-500 ease-in-out transform text-secondaryText",
                {
                  "rotate-0": menuOpen,
                  "-rotate-90": !menuOpen
                }
              )}
            />
          </Box>
        }
      />
      <Collapse in={menuOpen} timeout='auto'>
        <List className='pl-4'>
          {tab.children.map((tabInner) => {
            const isActive = activePath && activePath === tabInner.path;
            return (
              <ListItem key={tabInner.id} disablePadding>
                <Link
                  className={clsx("w-full px-4 py-2 text-base", {
                    "text-primary": isActive
                  })}
                  to={tabInner.path ?? ""}
                >
                  {typeof tabInner.label === "string" ? (
                    <Text className='text-[16px] leading-[19px]'>{tabInner.label}</Text>
                  ) : (
                    <Box className='text-[16px] leading-[19px]'>{tabInner.label}</Box>
                  )}
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </Box>
  );
};
