import clsx from "clsx";
import React from "react";
import { useAlertsStore } from "~/shared/stores/alerts";
import { Box } from "@mui/material";
import { omit } from "rambda";
import {
  ContactPersonsInput,
  ContactPersonsModel,
  useContactPersonsQuery,
  useContactPersonsUpsertMutation
} from "~/generated/graphql";
import { FormActions } from "./components/FormActions";
import { ContactPersonsTable } from "./components/ContactPersonsTable";
import { useUserOrganization } from "~/entities/UserOrganization";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { DeepPartial, useFormContext } from "react-hook-form";

type Props = {
  initialValues: {
    contactPerson?: DeepPartial<ContactPersonsModel> | null;
    orgId?: number;
    isLeaderPresentation?: boolean;
    isLinkExist?: boolean;
  };
  name?: string;
  className?: string;
  refetch?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
};

export const ContactPersonForm: React.FC<Props> = ({
  className,
  name = "contactPerson",
  initialValues,
  refetch,
  isLoading,
  disabled
}) => {
  const { watch, setValue } = useFormContext();

  const contactPerson = watch(name);

  const myOrgId = useUserOrganization();

  const orgId = (initialValues?.orgId || myOrgId) as number;

  const client = useGraphqlClient();

  const { data, refetch: refetchPersons } = useContactPersonsQuery(client, {
    where: { orgId: { eq: orgId } }
  });

  const addAlert = useAlertsStore((state) => state.addAlert);

  const contactPersons = data?.contactPersons?.data ?? [];

  const { mutateAsync: upsert } = useContactPersonsUpsertMutation(client);

  const addItem = (input: ContactPersonsInput): Promise<void> => {
    if (!input.firstName || !input.lastName) {
      return Promise.resolve();
    }

    return upsert({
      input: {
        ...omit(["createdAt", "updatedAt"], input),
        image: input?.image ? { id: input?.image?.id } : null,
        ...(Boolean(orgId) && { org: { id: orgId } })
      }
    })
      .then((data): void => {
        addAlert("success", "Успешно");
        setValue(name, data?.contactPersonsUpsert);
        refetch?.();
        refetchPersons();
      })
      .catch(() => {
        addAlert("error", "Ошибка");
      });
  };

  const removeItem = () => {
    setValue(name, null);
  };

  return (
    <Box
      sx={{ pointerEvents: disabled ? "none" : "all" }}
      className={clsx("flex flex-col gap-8", className)}
    >
      <div className='flex items-center justify-between'></div>

      <div className='card-base flex flex-col gap-8'>
        <div className='flex max-md:flex-col items-start justify-between max-md:gap-4 '>
          <div className='flex md:gap-6 gap-4 max-md:flex-col max-md:w-full'>
            <FormActions
              contactPersons={contactPersons}
              addItem={addItem}
              isLeader={!!initialValues?.isLeaderPresentation}
              isLinkExist={!!initialValues?.isLinkExist}
            />
          </div>
        </div>
        <ContactPersonsTable
          orgId={orgId}
          contactPerson={contactPerson}
          addItem={addItem}
          onRemove={removeItem}
          isLoading={!!isLoading}
          isLeader={!!initialValues?.isLeaderPresentation}
          isLinkExist={!!initialValues?.isLinkExist}
        />
      </div>
    </Box>
  );
};
