import React from "react";

import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow placement='bottom-end' classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000"
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F3F4F6",
    padding: "10px",
    boxShadow: "10px 5px 5px gray",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    border: "1px solid black",
    fontSize: theme.typography.pxToRem(12)
  }
}));
