import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Text } from "~shared/components/Text";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer } from "@mui/material";
import { HeaderTabs } from "~/modules/Header/components/HeaderTabs";
import { useAuthStore } from "~stores/auth";
import { LoginPageRoute } from "~shared/routes";
import { FilesModel, useProfileQuery } from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { User, useUserStore } from "~/shared/stores/user";
import { getImageSrc } from "~/shared/lib/getImageSrc";
import { queryCacheClient } from "~/app/providers/CustomQueryClientProvider";
import { Link } from "~/shared/components/Link";
import { useRole } from "~/entities/Roles";
import LinkIcon from "@mui/icons-material/Link";
import { Loader } from "~/shared/components/Loader";

export const AppToolbar: React.FC = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const [menuOpen, setMenuOpen] = React.useState(false);

  const unAuth = useAuthStore((state) => state.unAuth);

  const user = useUserStore((state) => state.user);

  const name = [user?.firstName, user?.lastName].filter(Boolean).join(" ");

  const client = useGraphqlClient();

  const { isLoading } = useProfileQuery(
    client,
    {},
    {
      onSuccess: (data) => {
        useUserStore.getState().setUser(data?.me as User);
      },
      cacheTime: 0,
      staleTime: 0
    }
  );

  const history = useNavigate();

  const handleLogout = () => {
    unAuth();
    history(LoginPageRoute);
    queryCacheClient.invalidateQueries();
    queryCacheClient.refetchQueries();
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setMenuOpen(open);
  };

  const { hasPermissions } = useRole();

  const isExpert = hasPermissions(["expertise_technics_courses"], { withoutAdmin: true });

  React.useEffect(() => {
    if (window.matchMedia("(max-width:1024px)")) {
      setMenuOpen(false);
    }
  }, [location.pathname, location.search]);

  return (
    <AppBar position='static' className='bg-white rounded-xl shadow-lg w-full mx-auto'>
      <Box className='flex justify-between items-center sm-hidden'>
        <IconButton className='lg:opacity-0 lg:pointer-events-none' onClick={toggleDrawer(true)}>
          <MenuIcon className='text-4xl' />
        </IconButton>
        {isExpert && (
          <Link
            className='flex gap-1 text-md items-center shrink-0'
            target='_blank'
            to={process.env.REACT_APP_MOODLE_URL ?? ""}
          >
            <LinkIcon />
            Перейти в СДО
          </Link>
        )}

        <Box className='flex items-center justify-end gap-2 w-full'>
          {!isLoading && <Text className='text-primary'>{name}</Text>}
          <Toolbar>
            <Box className='ml-auto'>
              {!isLoading ? (
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={name} src={getImageSrc(user?.image as FilesModel) || "/empty.png"} />
                </IconButton>
              ) : (
                <Loader className='!h-4 !w-4' />
              )}
              <Menu
                sx={{ mt: "45px" }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleLogout}>
                  <Text>Logout</Text>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Box>
        <Drawer anchor='top' open={menuOpen} onClose={toggleDrawer(false)}>
          <HeaderTabs />
        </Drawer>
      </Box>
    </AppBar>
  );
};
