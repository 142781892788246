import { create } from "zustand";
import { Maybe, UsersModel } from "~/generated/graphql";

export type User = Pick<UsersModel, "email" | "firstName" | "lastName" | "id" | "parent"> & {
  image?: { path?: string | null } | null;
};

export type UserState = {
  user: Maybe<User>;

  setUser: (user: Maybe<User>) => void;
};

export const useUserStore = create<UserState>(
  (set): UserState => ({
    user: null,
    setUser: (user: Maybe<User>) => set(() => ({ user }))
  })
);
