export const TYPE_ANSWER = [
  { id: 1, name: "да", value: "да" },
  { id: 2, name: "нет", value: "нет" }
];

export const TYPE_RETRY = [
  { id: 1, name: "да", value: true },
  { id: 2, name: "нет", value: false }
];

export enum Answer {
  null = 0,
  да = 1,
  нет = 2
}
