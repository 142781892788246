import React, { Fragment, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "~shared/stores/auth";
import { LoginPageRoute, UsersPageRoute } from "~shared/routes";
import { useRole } from "~/entities/Roles";
import { Loader } from "~/shared/components/Loader";

export type ProtectedRoutesProps = PropsWithChildren<{ permissions?: string[]; route?: string }>;

export const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({
  permissions = [],
  route,
  children
}) => {
  const token = useAuthStore((state) => state.token);

  const {
    rolesQuery: { isLoading },
    hasPermissions
  } = useRole();

  const { rolesQuery } = useRole();

  let isPermitted = hasPermissions(permissions);

  if (route === UsersPageRoute && rolesQuery?.data?.me?.isOrg) {
    isPermitted = true;
  }

  if (!token) {
    return <Navigate to={LoginPageRoute} replace />;
  }

  if (isLoading && permissions.length) {
    return (
      <>
        <Loader />
      </>
    );
  }

  if (!isLoading && !isPermitted && permissions.length) {
    return <Navigate to='/404' />;
  }

  return <Fragment>{children}</Fragment>;
};
