import { DeepPartial } from "react-hook-form";
import { LabsModel, LabsInput } from "~/generated/graphql";
import { omit } from "rambda";

export const prepareFormData = async (
  newValues: Partial<LabsModel & LabsInput>,
  values?: DeepPartial<LabsModel> | null,
  { isCreateMode }: { isCreateMode?: boolean } = {}
) => {
  const input: LabsInput = {
    ...(Boolean(values?.id) && { id: values?.id }),
    published: newValues.published,
    ...omit(
      [
        "image",
        "imageDetail",
        "city",
        "organizer",
        "contactPerson",
        "publishedAt",
        "firstname",
        "lastName",
        "surName",
        "position",
        "phone",
        "email"
      ],
      newValues
    ),
    image: newValues.image ? { id: newValues.image?.id } : null,

    imageDetail: newValues.imageDetail
      ? {
          id: newValues.imageDetail?.id
        }
      : null,

    city: newValues.city ? { id: newValues.city?.id } : null,

    ...(Boolean(newValues.organizer) && {
      organizer: {
        id: newValues.organizer?.id
      }
    }),
    contactPerson: newValues.contactPerson
      ? {
          id: newValues.contactPerson?.id,
          org: { id: newValues?.contactPerson?.org?.id || newValues.organizer?.id }
        }
      : null,

    directions: newValues.directions,
    description: newValues.description
  };
  if (isCreateMode) delete input.id;
  return input;
};
