import {
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
  TextField,
  TextareaAutosize
} from "@mui/material";
import React, { Fragment, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import {
  useImageDeleteMutation,
  NomusModel,
  useUploadImageMutation,
  useCitiesQuery,
  useOrganisersQuery
} from "~/generated/graphql";
import { HelperText } from "~/shared/components/HelperText";
import { ImageForm } from "~/shared/components/ImageForm";
import { Text } from "~/shared/components/Text";
import { DateTimePicker } from "~shared/components/DateTimePicker";
import { getErrorMessage } from "~/shared/lib/getError";
import { baseRequired, baseDateValidation, getBaseUrlValidation } from "~/shared/lib/validation";
import { useAlertsStore } from "~/shared/stores/alerts";
import { EntityInfo } from "~shared/components/EntityInfo";
import { AppSwitch } from "~shared/components/AppSwitch";
import { StatusesExpertise } from "~/shared/types/statusesExpertise";
import { useMyOrganisersQuery } from "~/entities/Organizers/lib/useMyOrganisersQuery";
import { LinkInput } from "~/shared/components/LinkInput";
import { useRole } from "~/entities/Roles";
import { useCanPublish } from "~/entities/Roles/useCanPublish";
import { unifyName } from "~/shared/lib/unifyName";

type Props = {
  canEdit: boolean;
  initialValues?: Partial<NomusModel>;
};

export const GeneralForm: React.FC<Props> = ({ canEdit, initialValues }) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext();

  const getError = getErrorMessage(errors);

  const addAlert = useAlertsStore((state) => state.addAlert);

  const client = useGraphqlClient();

  const isCreateMode = !Number.isInteger(initialValues?.id);

  const { hasPermissions } = useRole();

  const isAdmin = hasPermissions(["all"]);

  const { data: organizersQueryData } = useMyOrganisersQuery({ enabled: isCreateMode || isAdmin });

  const { data: allOrganizersQueryData } = useOrganisersQuery(
    client,
    {
      where: { id: { eq: initialValues?.organizer?.id } }
    },
    { enabled: !(isCreateMode || isAdmin) }
  );

  const organisersResponse = isCreateMode || isAdmin ? organizersQueryData : allOrganizersQueryData;

  const organizations = useMemo(
    () => unifyName(organisersResponse?.organisers.data ?? []),
    [organisersResponse]
  );

  const { mutateAsync: uploadImageMutation } = useUploadImageMutation(client, {
    onError: () => {
      useAlertsStore.getState().addAlert("error", "Ошибка при загрузке файла");
    }
  });

  const { mutateAsync: deleteMutation } = useImageDeleteMutation(client);

  const { data: citiesQuery } = useCitiesQuery(client);
  const cities = citiesQuery?.cities.data ?? [];

  const uploadImageHandler: (image?: File | null, isDetail?: boolean) => void = (
    image,
    isDetail
  ) => {
    uploadImageMutation({ image }).then((url) => {
      const path = isDetail ? "imageDetail" : "image";

      setValue(path, {
        id: url.imageUpload.id
      });
    });
  };

  const deleteImageHandler = async (isDetail?: boolean) => {
    const path = isDetail ? "imageDetail" : "image";
    const imageId = getValues(path)?.id;
    await deleteMutation({ id: Number(imageId) });
    setValue(path, null);
  };

  const canChangePublished = useCanPublish(initialValues?.status?.id as StatusesExpertise, [
    "moderator_content_science"
  ]);

  useEffect(() => {
    if (isCreateMode && organizations.length === 1) {
      setValue("organizer", organizations.at(0));
    }
  }, [isCreateMode, organizations]);

  return (
    <ImageForm
      addAlert={addAlert}
      onChange={uploadImageHandler}
      onDelete={deleteImageHandler}
      disabled={!canEdit}
      asideChildren={
        <EntityInfo createdAt={initialValues?.createdAt} updatedAt={initialValues?.updatedAt} />
      }
    >
      <div className='flex flex-wrap gap-2'>
        <Controller
          name='published'
          defaultValue={initialValues?.published || false}
          render={({ field }) => (
            <AppSwitch
              checked={!!field.value}
              disabled={!canChangePublished}
              label='Published'
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name='publishedAt'
          rules={baseDateValidation}
          defaultValue={initialValues?.publishedAt}
          render={({ field }) => (
            <div className='max-sm:grow'>
              <DateTimePicker
                disabled
                label={<Text>Published at</Text>}
                value={field.value ?? null}
                className='w-full'
                onChange={field.onChange}
              />
              <HelperText error={getError("publishedAt")} />
            </div>
          )}
        />
      </div>

      <Controller
        name='commentForModerator'
        defaultValue={initialValues?.commentForModerator}
        render={({ field: { value, ...other } }) => (
          <FormControl fullWidth>
            <TextField
              label={<Text>Комментарий для модератора</Text>}
              value={value ?? ""}
              error={!!getError("commentForModerator")}
              {...other}
              multiline
              InputProps={{
                inputComponent: TextareaAutosize
              }}
              disabled={!canEdit}
            />

            <HelperText error={getError("commentForModerator")} />
          </FormControl>
        )}
      />

      <Controller
        name='name'
        defaultValue={initialValues?.name}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              label={<Text>Название</Text>}
              value={value ?? ""}
              disabled={!canEdit}
              error={!!getError("name")}
              {...register("name", {
                ...baseRequired,
                maxLength: { value: 500, message: "Max length text field 500" }
              })}
            />

            <HelperText id='name' error={getError("name")} />
          </FormControl>
        )}
      />

      <Controller
        name='organizer'
        rules={baseRequired}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              // key={value?.name}
              id='organizer'
              freeSolo
              disabled={!canEdit}
              className='w-full'
              defaultValue={
                isCreateMode && organizations.length === 1
                  ? organizations.at(0)
                  : initialValues?.organizer
              }
              options={organizations}
              getOptionLabel={(option: any) => option?.name || ""}
              noOptionsText={<Text>No options</Text>}
              size='small'
              value={value}
              onChange={(_: any, value: any) => {
                setValue("organizer", value);
              }}
              renderOption={(props: any, option: any) => (
                <Fragment key={option.id}>
                  <MenuItem {...props} id={option.id}>
                    {option.name}
                  </MenuItem>
                </Fragment>
              )}
              renderInput={(params: any) => (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!canEdit}
                    multiline
                    label={<Text>Организация</Text>}
                    error={getError("organizer")}
                  />
                </Box>
              )}
            />
            <HelperText error={getError("organizer")} />
          </FormControl>
        )}
      />

      <Controller
        name='city'
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id='city'
              freeSolo
              className='w-full'
              defaultValue={initialValues?.city}
              options={cities}
              getOptionLabel={(option) => option?.name || ""}
              noOptionsText={<Text>No options</Text>}
              size='small'
              disabled={!canEdit}
              value={value}
              onChange={(_, value) => {
                setValue("city", value);
              }}
              renderOption={(props, option) => (
                <Fragment key={option.id}>
                  <MenuItem {...props} id={option.id}>
                    {option.name}
                  </MenuItem>
                </Fragment>
              )}
              renderInput={(params) => (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!canEdit}
                    multiline
                    label={<Text>City</Text>}
                  />
                </Box>
              )}
            />
            <HelperText id='city' error={getError("city")} />
          </FormControl>
        )}
      />

      <Controller
        name='link'
        defaultValue={initialValues?.link}
        render={({ field }) => (
          <FormControl fullWidth>
            <LinkInput
              label={<Text>Link</Text>}
              {...field}
              disabled={!canEdit}
              {...register("link", getBaseUrlValidation({ required: false }))}
              value={field.value ?? ""}
              fullWidth
              error={!!getError("link")}
            />
            <HelperText error={getError("link")} />
          </FormControl>
        )}
      />
    </ImageForm>
  );
};
