import { NewsResolver_News_SortingInputType, SortType } from "~/generated/graphql";

export const createOrder = (order: {
  [key: string]: SortType;
}): NewsResolver_News_SortingInputType[] => {
  return Object.entries(order).reduce((res: NewsResolver_News_SortingInputType[], [key, value]) => {
    res.push({ [key]: value });
    return res;
  }, []);
};
