export function getFilesFromNative(accept = "*/*"): Promise<File[]> {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = accept;

  input.click();

  return new Promise((resolve) => {
    input.onchange = (e) => {
      if (e.target && "files" in e.target) {
        return resolve(e.target.files as File[]);
      }

      resolve([]);
    };

    window.onfocus = () => {
      setTimeout(() => {
        if (input.files?.length === 0) {
          return resolve([]);
        }
      }, 300);
    };
  });
}

export function getFileFromNative(accept = "*/*"): Promise<File> {
  return getFilesFromNative(accept).then((files) => files[0] ?? null);
}
