import { useCallback, useEffect, useMemo } from "react";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { useProfileRolesQuery } from "~/generated/graphql";
import { createCallableCtx } from "~/shared/lib/context";
import { useAuthStore } from "~/shared/stores/auth";

const useHook = () => {
  const client = useGraphqlClient();
  const rolesQuery = useProfileRolesQuery(
    client,
    {},
    { cacheTime: 0, staleTime: 0, refetchOnMount: "always" }
  );

  const allPermissions: Set<string> = useMemo(
    () =>
      new Set(
        rolesQuery?.data?.me.roles?.reduce((acc, cur) => {
          acc = acc.concat(cur.permissions?.map((permission) => permission.name) ?? []);
          return acc;
        }, [] as string[]) ?? []
      ),
    [rolesQuery]
  );

  const hasPermissions = useCallback(
    (permissions: string[], { withoutAdmin }: { withoutAdmin?: boolean } = {}): boolean => {
      if (allPermissions.has("all") && !withoutAdmin) {
        return true;
      }

      return permissions.some((permission) => allPermissions.has(permission));
    },
    [allPermissions]
  );

  const result = useMemo(
    () => ({ rolesQuery, hasPermissions, permissions: allPermissions }),
    [rolesQuery, hasPermissions, allPermissions]
  );

  useEffect(() => {
    useAuthStore.subscribe(() => {
      rolesQuery.refetch();
    });
  }, [rolesQuery.refetch]);

  return result;
};

export const [useRole, RoleContextProvider] = createCallableCtx(useHook, {
  name: "RoleContextProvider"
});
