import React, { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import {
  DissertationCouncilsModel,
  useDissertationCouncilsByIdQuery,
  useDissertationCouncilsDeclineMutation,
  useDissertationCouncilsPublishMutation,
  useDissertationCouncilsSendToModerateMutation,
  useDissertationCouncilsUpsertMutation
} from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { TabsForm } from "~/entities/TabsForm";
import { initFormValues } from "~/shared/lib/initFormValues";
import { useNavigationBack } from "~/shared/hooks/useBackClick";
import { GeneralForm } from "./components/GeneralForm";
import { prepareFormData } from "./lib/prepareFormData";
import { either, has, isEmpty } from "rambda";
import { useAlertsStore } from "~/shared/stores/alerts";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { useRole } from "~/entities/Roles";
import { Loader } from "~/shared/components/Loader";
import { useValidationError } from "~/shared/hooks/useValidationError";
import { isFullData } from "~/shared/lib/isFullData";
import { useInputStatus } from "~/shared/hooks/useInputStatus";
import { useCanEditByStatus } from "~/entities/Roles/useCanEditOnModeration";
import { getBaseRefInitializer } from "~/shared/hooks/useChangesRef";

type Props = {
  id?: number;
  formName?: string;
  changesGetterRef?: (callback: () => Promise<boolean>) => void;
};

export const DissertationCouncilsDetailForm: React.FC<Props> = ({
  id,
  formName,
  changesGetterRef
}) => {
  const [step, setStep] = useState(0);

  const isCreateMode = !Number.isInteger(id);

  const client = useGraphqlClient();

  const { setIsSaveLoading } = usePageLoadingStore((state) => ({
    setIsSaveLoading: state.setIsSaveLoading
  }));

  const addAlert = useAlertsStore((state) => state.addAlert);

  const { mutateAsync: sendToModerate } = useDissertationCouncilsSendToModerateMutation(client);
  const { mutateAsync: decline } = useDissertationCouncilsDeclineMutation(client);
  const { mutateAsync: publish } = useDissertationCouncilsPublishMutation(client);

  const { data, refetch } = useDissertationCouncilsByIdQuery(
    client,
    { id: Number(id) },
    {
      enabled: !isCreateMode,
      refetchOnMount: "always",
      cacheTime: 0
    }
  );

  const { hasPermissions } = useRole();

  const initialValues = data?.dissertationCouncilsById as DissertationCouncilsModel;

  const goBack = useNavigationBack();

  const formMethods = useForm({ mode: "all" });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    watch
  } = formMethods;

  const onError = useValidationError(setError);

  const { mutateAsync: updateDissertationCouncil, isLoading } =
    useDissertationCouncilsUpsertMutation(client, {
      onSuccess: goBack,
      onError
    });

  const values = data?.dissertationCouncilsById;

  const isGeneralNewsFormError = either(has("name"), has("content"))(errors);

  const { prepareInputStatus, setInitialValue } = useInputStatus({
    isModerator: hasPermissions(["moderator_content_science"]),
    currentStatusId: values?.status?.id
  });

  const onSubmit = handleSubmit((newValues) => {
    prepareFormData(newValues, values, { isCreateMode }).then((input) => {
      updateDissertationCouncil({ input: prepareInputStatus(input) });
    });
  });

  changesGetterRef?.(() =>
    getBaseRefInitializer({
      isCreate: isCreateMode,
      value: prepareFormData(values as never),
      newValue: prepareFormData(getValues())
    })
  );

  const canEdit =
    useCanEditByStatus({
      isModerator: hasPermissions(["moderator_content_science"]),
      currentStatusId: initialValues?.status?.id
    }) && !watch("published");

  const getIsFullDataFunction = (value: unknown): boolean => {
    return isFullData(value, ["rejectReason", "publishedAt", "commentForModerator"]);
  };

  const forms = useMemo(
    () => [
      {
        tabTitle: "General data",
        hasErrors: isGeneralNewsFormError,
        component: <GeneralForm initialValues={initialValues} canEdit={canEdit} />
      }
    ],
    [isGeneralNewsFormError, initialValues, canEdit]
  );

  useEffect(() => {
    setValue("publishedAt", isCreateMode ? dayjs().toISOString() : values?.publishedAt ?? null);
    if (isCreateMode) {
      return;
    }

    initFormValues(
      ["name", "city", "published", "link", "organizer", "commentForModerator"],
      setValue,
      values
    );
    setValue("specialties", values?.specialties ?? []);

    prepareFormData(getValues(), values, { isCreateMode }).then(setInitialValue);
  }, [values, isCreateMode, setValue, getValues]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      addAlert("error", "Fill in all required fields");
    }
  }, [errors, step, addAlert]);

  useEffect(() => {
    setIsSaveLoading(isLoading);
  }, [isLoading, setIsSaveLoading]);
  const getIsFullData = getIsFullDataFunction(initialValues);
  if (!isCreateMode && !values) {
    return <Loader />;
  }

  return (
    <FormProvider {...formMethods}>
      <TabsForm
        handleSubmit={onSubmit}
        handleStepChange={setStep}
        activeStep={step}
        formName={formName}
        forms={forms}
        initialValues={initialValues}
        sendToModerate={sendToModerate}
        decline={decline}
        refetch={refetch}
        publish={publish}
        isFullData={getIsFullData}
        sectionModeratorRole='moderator_content_science'
        canNotSendToModerate={!(hasPermissions(["management_content_science"]) && getIsFullData)}
      />
    </FormProvider>
  );
};
