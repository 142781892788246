import {
  Box,
  FormControl,
  MenuItem,
  TextField,
  Autocomplete as MuiAutocomplete,
  TextareaAutosize,
  FormLabel,
  InputLabel,
  Select
} from "@mui/material";
import React, { Fragment, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import {
  useImageDeleteMutation,
  PracticesModel,
  useUploadImageMutation,
  usePracticesTipsQuery,
  useRegionsQuery,
  TipsModel,
  FilesModel,
  useUploadDocumentMutation,
  useDocumentDeleteMutation,
  useOrganisersQuery,
  useMassUploadVideoMutation,
  useVideoDeleteMutation
} from "~/generated/graphql";
import { HelperText } from "~/shared/components/HelperText";
import { Text } from "~/shared/components/Text";
import { getErrorMessage } from "~/shared/lib/getError";
import { baseRequired, baseDateValidation } from "~/shared/lib/validation";
import { useAlertsStore } from "~/shared/stores/alerts";
import { AppSwitch } from "~shared/components/AppSwitch";
import Autocomplete from "~/shared/components/Autocomplete/Autocomplete";
import { StatusesExpertise } from "~/shared/types/statusesExpertise";
import { FilesMultipleInput } from "~/shared/components/FilesMultipleInput";
import { ImageInput } from "~/shared/components/ImageInput";
import { getImageSrc } from "~/shared/lib/getImageSrc";
import { useRole } from "~/entities/Roles";
import { useMyOrganisersQuery } from "~/entities/Organizers/lib/useMyOrganisersQuery";
import { ContentEditorInput } from "~/entities/ContentEditorInput";
import { getEventValueHandler } from "~/shared/lib/events";
import { ImageForm } from "~/shared/components/ImageForm";
import { TYPE_FORMAT } from "../../static";
import { useCanPublish } from "~/entities/Roles/useCanPublish";
import { unifyName } from "~/shared/lib/unifyName";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { incrementHours } from "~/shared/lib/dateToISOString";
import { formatDateCard } from "~/shared/lib/formatDate";

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  initialValues?: Partial<PracticesModel>;
  canEdit?: boolean;
};

export const GeneralForm: React.FC<Props> = ({ initialValues, canEdit }) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    control
  } = useFormContext();

  const getError = getErrorMessage(errors);

  const addAlert = useAlertsStore((state) => state.addAlert);

  const client = useGraphqlClient();

  const { data: tips } = usePracticesTipsQuery(client, {}, { refetchOnMount: "always" });
  const { data: regions } = useRegionsQuery(client, {}, { refetchOnMount: "always" });

  const isCreateMode = !Number.isInteger(initialValues?.id);

  const { hasPermissions } = useRole();

  const isAdmin = hasPermissions(["all"]);

  const { data: organizersQueryData } = useMyOrganisersQuery({ enabled: isCreateMode || isAdmin });

  const { data: allOrganizersQueryData } = useOrganisersQuery(
    client,
    {
      where: { id: { eq: initialValues?.organizer?.id } }
    },
    { enabled: !(isCreateMode || isAdmin) }
  );

  const organisersResponse = isCreateMode || isAdmin ? organizersQueryData : allOrganizersQueryData;

  const organizations = useMemo(
    () =>
      unifyName(
        (organisersResponse?.organisers.data ?? []).sort((a, b) =>
          (a.name ?? "").localeCompare(b.name ?? "")
        )
      ),
    [organisersResponse]
  );

  const { mutateAsync: uploadImageMutation } = useUploadImageMutation(client, {
    onError: () => {
      useAlertsStore.getState().addAlert("error", "Ошибка при загрузке файла");
    }
  });

  const { mutateAsync: deleteMutation } = useImageDeleteMutation(client);

  const { mutateAsync: massUploadVideoMutation } = useMassUploadVideoMutation(client);

  const { mutateAsync: deleteVideoMutation } = useVideoDeleteMutation(client);

  const { mutateAsync: uploadDocumentMutation } = useUploadDocumentMutation(client);

  const { mutateAsync: deleteDocumentMutation } = useDocumentDeleteMutation(client);

  const uploadVideoHandler: (file?: Array<File> | null) => void = (file) => {
    massUploadVideoMutation({ videos: file }).then(({ videoMassUpload }) => {
      setValue("video", videoMassUpload?.[0]);
    });
  };

  const deleteVideoHandler = async () => {
    const currentFile = getValues("video");
    await deleteVideoMutation({ id: Number(currentFile?.id) });
    setValue("video", null);
  };

  const uploadDocumentHandler: (name: string) => (document?: File[] | null) => void =
    (name: string) => (document) => {
      const file = Array.isArray(document) ? document?.[0] : document;
      uploadDocumentMutation({ document: file }).then((url) =>
        setValue(name, {
          id: url.documentUpload.id,
          name: url.documentUpload?.name
        })
      );
    };

  const deletDocumentHandler = (name: string) => async () => {
    const imageId = getValues(name)?.id;
    await deleteDocumentMutation({ id: Number(imageId) });
    setValue(name, null);
  };

  const uploadImageHandler: (image?: File | null) => void = (image, isDetail?: boolean) => {
    const fieldName = isDetail ? "imageDetail" : "image";

    uploadImageMutation({ image }).then((url) =>
      setValue(fieldName, {
        id: url.imageUpload.id
      })
    );
  };

  const deleteImageHandler = async (isDetail?: boolean) => {
    const fieldName = isDetail ? "imageDetail" : "image";
    const imageId = getValues(fieldName)?.id;
    await deleteMutation({ id: Number(imageId) });
    setValue(fieldName, null);
  };

  const isPublishSwitherEnabled = useCanPublish(initialValues?.status?.id as StatusesExpertise, [
    "moderator_content_mp_vd"
  ]);

  useEffect(() => {
    if (isCreateMode && organizations.length === 1) {
      setValue("organizer", organizations.at(0));
    }
  }, [isCreateMode, organizations]);

  return (
    <ImageForm
      disabled={!canEdit}
      addAlert={addAlert}
      onChange={uploadImageHandler}
      onDelete={deleteImageHandler}
      single
    >
      <div className='flex flex-wrap gap-2'>
        <Controller
          name='published'
          defaultValue={initialValues?.published || false}
          render={({ field }) => (
            <AppSwitch
              disabled={!isPublishSwitherEnabled}
              checked={!!field.value}
              label='Published'
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          name='publishedAt'
          rules={baseDateValidation}
          render={({ field }) => (
            <div className='max-sm:grow'>
              <TextField
                label={<Text>Published at</Text>}
                value={
                  field.value
                    ? formatDateCard(incrementHours(field.value, 3), "ru", "DD.MM.YYYY HH:mm")
                    : null
                }
                className='w-full'
                disabled
                onChange={field.onChange}
              />
              <HelperText error={getError("publishedAt")} />
            </div>
          )}
        />
      </div>

      <Controller
        name='commentForModerator'
        defaultValue={initialValues?.commentForModerator}
        render={({ field: { value, ...other } }) => (
          <FormControl fullWidth>
            <TextField
              label={<Text>Комментарий для модератора</Text>}
              value={value ?? ""}
              error={!!getError("commentForModerator")}
              {...other}
              multiline
              InputProps={{
                inputComponent: TextareaAutosize
              }}
              disabled={!canEdit}
            />

            <HelperText error={getError("commentForModerator")} />
          </FormControl>
        )}
      />

      <Controller
        name='name'
        defaultValue={initialValues?.name}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              disabled={!canEdit}
              label={<Text>Наименование практики</Text>}
              value={value ?? ""}
              error={!!getError("name")}
              {...register("name", {
                ...baseRequired,
                maxLength: { value: 500, message: "Max length text field 500" }
              })}
            />

            <HelperText error={getError("name")} />
          </FormControl>
        )}
      />
      <Controller
        name='description'
        defaultValue={initialValues?.description}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              disabled={!canEdit}
              label={<Text>Цель практики</Text>}
              value={value ?? ""}
              error={!!getError("description")}
              {...register("description", {
                maxLength: { value: 500, message: "Max length text field 500" }
              })}
              multiline
              InputProps={{
                inputComponent: TextareaAutosize
              }}
            />

            <HelperText error={getError("description")} />
          </FormControl>
        )}
      />

      <Controller
        name='annotation'
        defaultValue={initialValues?.annotation}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              disabled={!canEdit}
              label={<Text>Annotation</Text>}
              value={value ?? ""}
              multiline
              fullWidth
              error={!!getError("annotation")}
              InputProps={{
                inputComponent: TextareaAutosize
              }}
              {...register("annotation")}
            />
            <HelperText error={getError("annotation")} />
          </FormControl>
        )}
      />

      <FormControl fullWidth>
        <FormLabel>Презентация</FormLabel>
        <Controller
          control={control}
          name='presentation'
          render={({ field }) => (
            <FilesMultipleInput
              disabled={!canEdit}
              id='document-input'
              label={"Upload or drop documents"}
              files={(Array.isArray(field?.value)
                ? field.value
                : ([field?.value] as Array<FilesModel>)
              ).filter(Boolean)}
              onFileChange={uploadDocumentHandler("presentation")}
              onDelete={deletDocumentHandler("presentation")}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>Изображение аннотации</FormLabel>
        <Controller
          control={control}
          name='annotationImage'
          render={({ field }) => (
            <ImageInput
              disabled={!canEdit}
              addAlert={addAlert}
              url={getImageSrc(field?.value) ?? ""}
              value={field?.value}
              onUpdate={field.onChange}
              onChange={(image: any) => {
                uploadDocumentHandler("annotationImage")(image);
              }}
              onDelete={deletDocumentHandler("annotationImage")}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>Видео аннотации</FormLabel>
        <Controller
          name='video'
          defaultValue={initialValues?.video}
          render={({ field: { value } }) => (
            <FilesMultipleInput
              disabled={!canEdit}
              id='video-input'
              label={"Upload or drop video files"}
              files={(Array.isArray(value) ? value : [value]).filter(Boolean)}
              allowedExtensions={/(\.avi|\.mp4|\.mkv|\.mov|\.wmv|\.flv|\.webm)$/i}
              onFileChange={uploadVideoHandler}
              onDelete={deleteVideoHandler}
            />
          )}
        />
      </FormControl>

      <Controller
        name='tasks'
        defaultValue={initialValues?.tasks}
        render={({ field: { value, ...other } }) => (
          <FormControl error={!!getError("content")} fullWidth>
            <Text className='text-mainText font-medium mb-1'>Задачи проекта</Text>
            <ContentEditorInput
              disabled={!canEdit}
              error={!!getError("tasks")}
              {...other}
              value={value ?? ""}
              onChange={getEventValueHandler((value) =>
                setValue("tasks", value, { shouldTouch: true })
              )}
              size='small'
            />

            <HelperText error={getError("tasks")} />
          </FormControl>
        )}
      />

      <Controller
        name='audienceTargets'
        defaultValue={initialValues?.audienceTargets}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              disabled={!canEdit}
              label={<Text>Целевая аудитория проекта</Text>}
              value={value ?? ""}
              error={!!getError("audienceTargets")}
              {...register("audienceTargets", {
                maxLength: { value: 500, message: "Max length text field 500" }
              })}
              multiline
              InputProps={{
                inputComponent: TextareaAutosize
              }}
            />

            <HelperText error={getError("audienceTargets")} />
          </FormControl>
        )}
      />

      <Controller
        name='realisation'
        defaultValue={initialValues?.realisation}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              disabled={!canEdit}
              label={<Text>Механизм реализации проекта</Text>}
              value={value ?? ""}
              error={!!getError("realisation")}
              {...register("realisation", {
                maxLength: { value: 500, message: "Max length text field 500" }
              })}
              multiline
              InputProps={{
                inputComponent: TextareaAutosize
              }}
            />

            <HelperText error={getError("realisation")} />
          </FormControl>
        )}
      />

      <Controller
        name='quantitativeResults'
        defaultValue={initialValues?.quantitativeResults}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              disabled={!canEdit}
              label={<Text>Количественные результаты проекта</Text>}
              value={value ?? ""}
              error={!!getError("quantitativeResults")}
              {...register("quantitativeResults", {
                maxLength: { value: 500, message: "Max length text field 500" }
              })}
              multiline
              InputProps={{
                inputComponent: TextareaAutosize
              }}
            />

            <HelperText error={getError("quantitativeResults")} />
          </FormControl>
        )}
      />

      <Controller
        name='qualitativeResults'
        defaultValue={initialValues?.qualitativeResults}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              disabled={!canEdit}
              label={<Text>Качественные результаты проекта</Text>}
              value={value ?? ""}
              error={!!getError("qualitativeResults")}
              {...register("qualitativeResults", {
                maxLength: { value: 500, message: "Max length text field 500" }
              })}
              multiline
              InputProps={{
                inputComponent: TextareaAutosize
              }}
            />

            <HelperText error={getError("qualitativeResults")} />
          </FormControl>
        )}
      />

      <Controller
        name='tips'
        defaultValue={initialValues?.tips}
        render={({ field: { value = [] } }) => (
          <MuiAutocomplete
            disablePortal
            multiple
            id='tips'
            freeSolo
            disabled={!canEdit}
            options={tips?.practicesTips?.data ?? []}
            getOptionLabel={(option) => (option as TipsModel).name || ""}
            noOptionsText={<Text>No options</Text>}
            size='small'
            value={value}
            onChange={(_, value) => {
              setValue("tips", value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={!canEdit}
                multiline
                label={<Text>Ключевые слова</Text>}
              />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name='region'
        defaultValue={initialValues?.region?.id}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel htmlFor='region'>
              <Text>Region</Text>
            </InputLabel>
            <Select
              id='region'
              variant='outlined'
              disabled={!canEdit}
              error={!!getError("region")}
              defaultValue={initialValues?.region?.id}
              {...field}
            >
              <MenuItem key={"empty"} value={""}>
                <Text>Not selected</Text>
              </MenuItem>
              {regions?.regions?.data.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </Select>
            <HelperText error={getError("region")} />
          </FormControl>
        )}
      />

      <Controller
        name='organizer'
        rules={baseRequired}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              // key={value?.name}
              id='organizer'
              disabled={!canEdit}
              freeSolo
              className='w-full'
              options={organizations}
              defaultValue={
                isCreateMode && organizations.length === 1
                  ? organizations.at(0)
                  : initialValues?.organizer
              }
              getOptionLabel={(option: any) => option?.name || ""}
              noOptionsText={<Text>No options</Text>}
              size='small'
              value={value}
              onChange={(_: any, value: any) => {
                setValue("organizer", value);
              }}
              renderOption={(props: any, option: any) => (
                <Fragment key={option.id}>
                  <MenuItem {...props} id={option.id}>
                    {option.name}
                  </MenuItem>
                </Fragment>
              )}
              renderInput={(params: any) => (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    multiline
                    disabled={!canEdit}
                    error={getError("organizer")}
                    label={<Text>ВУЗ</Text>}
                  />
                </Box>
              )}
            />
            <HelperText error={getError("organizer")} />
          </FormControl>
        )}
      />

      {/* <Controller
        name='circulation'
        defaultValue={initialValues?.circulation}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              label={<Text>Тиражируемость</Text>}
              type='number'
              value={value ?? ""}
              error={!!getError("circulation")}
              {...register("circulation", {
                ...baseRequired,
                maxLength: { value: 500, message: "Max length text field 500" }
              })}
            />

            <HelperText error={getError("circulation")} />
          </FormControl>
        )}
      /> */}

      <Controller
        name='isAllCanSee'
        control={control}
        defaultValue={
          initialValues?.isAllCanSee ? "для любого зарегистрированного пользователя" : "для вузов"
        }
        render={({ field: { value, ...other } }) => (
          <FormControl fullWidth>
            <InputLabel htmlFor='isAllCanSee'>
              <Text>Уровень доступности</Text>
            </InputLabel>
            <Select
              id='isAllCanSee'
              disabled={!canEdit}
              defaultValue={
                initialValues?.isAllCanSee
                  ? "для любого зарегистрированного пользователя"
                  : "для вузов"
              }
              variant='outlined'
              {...other}
              value={value}
            >
              {TYPE_FORMAT.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </ImageForm>
  );
};
