import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "~shared/lib/debounce";
import { createOrder } from "~shared/lib/createOrder";
import { formatFilters } from "~shared/lib/formatFilters";
import { ActiveOrder } from "~shared/types/ActiveOrder";
import { useTablePagination } from "./useTablePagination";
// import { useSearchParams } from "react-router-dom";
// import { isEmpty, not } from "rambda";
import { useRole } from "~/entities/Roles";
import { useUserOrganization } from "~/entities/UserOrganization";

const prepareFilters = <T>(
  filters: Params | null,
  filterFormats?: FiltersFormat<T>
): Partial<T> => {
  const newFilters = { ...filters };
  Object.entries(filterFormats ?? {}).forEach(([key, format]) => {
    newFilters[key] = format(newFilters[key] as any) as any;
  });

  return formatFilters<T>(newFilters);
};

export type Params = Record<string, string>;
type Queries = {
  activeOrder: ActiveOrder | null;
  filters: Params | null;
  params: Params | null;
  title: string;
  page: number | null;
};
export type FiltersFormat<T> = Record<string, (value: T[keyof T]) => T[keyof T]>;

export const useRequestState = <T>(
  fastSearchFieldId: string,
  {
    filterFormats,
    notUseMyOrganizationFilter
  }: { filterFormats?: FiltersFormat<T>; notUseMyOrganizationFilter?: boolean } = {}
) => {
  notUseMyOrganizationFilter =
    typeof notUseMyOrganizationFilter !== "undefined" ? notUseMyOrganizationFilter : false;

  const { pagination, handleChangePage, handleChangeRowsPerPage, resetPagination } =
    useTablePagination({
      page: 1,
      perPage: 30
    });

  const initialQueries: Queries = useMemo(
    () => ({
      activeOrder: null,
      filters: null,
      params: null,
      title: "",
      page: pagination.page || 1
    }),
    [pagination.page]
  );

  const [queries, setQueries] = useState<Queries>(initialQueries);

  const orgId = useUserOrganization();
  const { hasPermissions } = useRole();

  const isAdmin = hasPermissions(["all"]);

  const variables: any = {
    ...(queries.activeOrder
      ? {
          orderBy: [...createOrder(queries.activeOrder)]
        }
      : {}),
    ...{
      where: {
        ...(Boolean(!notUseMyOrganizationFilter && !isAdmin) && {
          organizerId: {
            eq: orgId
          }
        }),
        ...(Boolean(queries.filters) && prepareFilters<T>(queries.filters, filterFormats))
      }
    },
    paginate: { page: pagination.page, per_page: pagination.perPage }
  };

  const setActiveOrder = useCallback((newOrder: ActiveOrder | null) => {
    setQueries((cur) => ({ ...cur, activeOrder: newOrder }));
  }, []);

  const setFilters = useCallback(
    (filters: Params | null | ((newFilters: Params | null) => Params | null)) => {
      if (typeof filters === "function") {
        setQueries((cur) => ({ ...cur, filters: filters(cur.filters) }));
        return;
      }
      setQueries((cur) => ({ ...cur, filters }));
    },
    []
  );

  const setTitle = useCallback((title: string) => {
    setQueries((cur) => ({ ...cur, title }));
  }, []);

  const handleChangeOrder = useCallback(
    (order: ActiveOrder) => {
      setQueries((cur) => ({ ...cur, activeOrder: order }));
      resetPagination();
    },
    [resetPagination]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchTitle = useCallback(
    debounce<[string]>((newTitle: string) => {
      setQueries((cur) => {
        const newQueries = {
          ...cur,
          filters: {
            ...cur.filters,
            [fastSearchFieldId]: newTitle || (null as never)
          }
        };

        if (!newTitle) {
          delete newQueries.filters[fastSearchFieldId];
          newQueries.title = "";
        }

        return newQueries;
      });
      resetPagination();
    }, 300),
    [resetPagination, fastSearchFieldId]
  );

  const handleTitleChange = useCallback(
    (newTitle: string) => {
      setQueries((cur) => ({ ...cur, title: newTitle }));
      handleSearchTitle(newTitle);
    },
    [handleSearchTitle]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilter = useCallback(
    debounce<[Params]>((newParams: Params) => {
      setQueries((cur) => ({
        ...cur,
        filters: {
          ...cur.filters,
          [fastSearchFieldId]: cur.title,
          ...newParams
        } as Params
      }));

      resetPagination();
    }, 300),
    [resetPagination, fastSearchFieldId]
  );

  const handleFilterChange = useCallback(
    (cellId: string, value: unknown) => {
      const newParams = { ...queries.params, [cellId]: value } as Params;
      setQueries((cur) => ({
        ...cur,
        params: newParams,
        ...(Boolean(cellId === fastSearchFieldId) && { title: value as string })
      }));
    },
    [queries, fastSearchFieldId]
  );

  const removeFilter = useCallback(
    (key: string) => {
      setQueries((curQueries) => {
        const newQueries = { ...curQueries };

        delete (newQueries.params ?? {})[key];

        newQueries.filters = { ...newQueries.params, [fastSearchFieldId]: queries.title };

        return newQueries;
      });
    },
    [fastSearchFieldId, queries]
  );

  const resetTitle = useCallback(() => {
    handleSearchTitle("");
  }, [handleSearchTitle]);

  const resetFilters = useCallback(() => {
    setQueries(initialQueries);
    resetPagination();
  }, [resetPagination, initialQueries]);

  const handleSubmit = () => {
    handleSearchTitle(queries.title);
    handleFilter(queries.params as Params);
  };

  useEffect(() => {
    setQueries((state) => ({
      ...state,
      page: pagination.page
    }));
  }, [pagination]);

  return {
    variables,
    activeOrder: queries.activeOrder,
    filters: queries.filters,
    title: queries.title,
    pagination,
    params: queries.params,

    setActiveOrder,
    setFilters,
    setTitle,
    handleChangePage,
    handleChangeRowsPerPage,
    resetPagination,
    handleChangeOrder,
    handleTitleChange,
    handleSearchTitle,
    handleFilterChange,
    handleSubmit,
    removeFilter,
    resetFilters,
    resetTitle
  };
};
