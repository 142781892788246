import React from "react";
import { clsx } from "clsx";
import { Logo } from "~shared/components/Logo";

type Props = {
  className?: string;
};

export const DrawerHeader: React.FC<Props> = ({ className = "" }) => (
  <a
    href={process.env.REACT_APP_FRONT_URL}
    className={clsx("flex justify-center items-center px-1 min-h-[50px]", className)}
    target='_blank'
    rel='noreferrer'
  >
    <Logo className='w-full grow max-w-[183px]' />
  </a>
);
