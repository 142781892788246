import React from "react";
import { PageTableTitle } from "~/shared/components/PageTableTitle";
import { PageWrapper } from "~/shared/components/PageWrapper";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { JournalsTable } from "~/modules/JournalsTable/JournalsTable";

export const Journals: React.FC = () => {
  const { count, isLoading } = usePageLoadingStore((state) => ({
    count: state.count,
    isLoading: state.isLoading
  }));

  return (
    <PageWrapper>
      <PageTableTitle title='Journals' isLoading={isLoading} count={count} />
      <JournalsTable />
    </PageWrapper>
  );
};
