import { DeepPartial } from "react-hook-form";
import { NomusModel, NomusInput } from "~/generated/graphql";

export const prepareFormData = async (
  newValues: Partial<NomusModel & NomusInput>,
  values?: DeepPartial<NomusModel> | null,
  { isCreateMode }: { isCreateMode?: boolean } = {}
) => {
  const input: NomusInput = {
    ...(Boolean(values?.id) && { id: values?.id }),
    ...newValues,
    contactPerson: newValues.contactPerson
      ? {
          id: newValues.contactPerson?.id,
          org: { id: newValues.contactPerson?.org?.id || newValues.organizer?.id }
        }
      : null,
    leaderPerson: newValues.leaderPerson
      ? {
          id: newValues.leaderPerson?.id,
          org: { id: newValues.leaderPerson?.org?.id || newValues.organizer?.id }
        }
      : null,

    organizer: newValues.organizer ? { id: newValues.organizer?.id } : null,
    city: newValues.city ? { id: newValues.city?.id } : null,
    ...(Boolean(newValues.image) && {
      image: {
        id: newValues.image?.id
      }
    }),
    ...(Boolean(newValues.imageDetail) && {
      imageDetail: {
        id: newValues.imageDetail?.id
      }
    })
  };
  if (isCreateMode) delete input.id;
  return input;
};
