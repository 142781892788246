import React from "react";

export const ScienceIcon = () => {
  return (
    <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.9992 7.33L14.9992 14H2.9992L7.9992 7.33V2H9.9992V7.33ZM12.9592 0H5.0392C4.6192 0 4.3892 0.48 4.6492 0.81L5.9992 2.5V6.67L0.199199 14.4C-0.290801 15.06 0.179199 16 0.999199 16H16.9992C17.8192 16 18.2892 15.06 17.7992 14.4L11.9992 6.67V2.5L13.3492 0.81C13.6092 0.48 13.3792 0 12.9592 0Z'
        fill='#5229DD'
      />
    </svg>
  );
};
