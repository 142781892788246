import {
  Box,
  FormControl,
  MenuItem,
  TextField,
  Autocomplete as MuiAutocomplete,
  InputLabel,
  Select,
  TextareaAutosize
} from "@mui/material";
import React, { Fragment, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import {
  CoApplicationsModel,
  useSpecialtiesQuery,
  useCitiesQuery,
  SpecialtiesModel,
  useOrganisersQuery
} from "~/generated/graphql";
import { HelperText } from "~/shared/components/HelperText";
import { Form } from "~/shared/components/Form";
import { Text } from "~/shared/components/Text";
import { DateTimePicker } from "~shared/components/DateTimePicker";
import { getErrorMessage } from "~/shared/lib/getError";
import { baseDateValidation, baseRequired, getBaseUrlValidation } from "~/shared/lib/validation";
import { AppSwitch } from "~shared/components/AppSwitch";
import Autocomplete from "~/shared/components/Autocomplete/Autocomplete";
import { useMyOrganisersQuery } from "~/entities/Organizers/lib/useMyOrganisersQuery";
import { StatusesExpertise } from "~/shared/types/statusesExpertise";
import { LinkInput } from "~/shared/components/LinkInput";
import { useRole } from "~/entities/Roles";
import { TYPE_PAY } from "../../static";
import { useCanPublish } from "~/entities/Roles/useCanPublish";
import { unifyName } from "~/shared/lib/unifyName";

type Props = {
  canEdit: boolean;
  initialValues?: Partial<CoApplicationsModel>;
};

export const GeneralForm: React.FC<Props> = ({ initialValues, canEdit }) => {
  const {
    setValue,
    formState: { errors },
    control,
    register
  } = useFormContext();

  const getError = getErrorMessage(errors);

  const client = useGraphqlClient();

  const isCreateMode = !Number.isInteger(initialValues?.id);

  const { hasPermissions } = useRole();

  const isAdmin = hasPermissions(["all"]);

  const { data: organizersQueryData } = useMyOrganisersQuery({ enabled: isCreateMode || isAdmin });

  const { data: allOrganizersQueryData } = useOrganisersQuery(
    client,
    {
      where: { id: { eq: initialValues?.organizer?.id } }
    },
    { enabled: !(isCreateMode || isAdmin) }
  );

  const organisersResponse = isCreateMode || isAdmin ? organizersQueryData : allOrganizersQueryData;

  const organizations = useMemo(
    () => unifyName(organisersResponse?.organisers.data ?? []),
    [organisersResponse]
  );

  const { data: citiesQuery } = useCitiesQuery(client);
  const cities = citiesQuery?.cities.data ?? [];

  const { data: specialtiesQueryData } = useSpecialtiesQuery(client, {
    where: { isNir: { eq: true } }
  });
  const specialties = specialtiesQueryData?.specialties?.data?.filter((item) => item?.parent) ?? [];

  const canChangePublished = useCanPublish(initialValues?.status?.id as StatusesExpertise, [
    "moderator_content_science"
  ]);

  useEffect(() => {
    if (isCreateMode && organizations.length === 1) {
      setValue("organizer", organizations.at(0));
    }
  }, [isCreateMode, organizations]);

  return (
    <Form>
      <div className='flex flex-wrap gap-2'>
        <Controller
          name='published'
          defaultValue={initialValues?.published || false}
          render={({ field }) => (
            <AppSwitch
              checked={!!field.value}
              disabled={!canChangePublished}
              label='Published'
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name='publishedAt'
          rules={baseDateValidation}
          render={({ field }) => (
            <div className='max-sm:grow'>
              <DateTimePicker
                label={<Text>Published at</Text>}
                value={field.value ?? null}
                className='w-full'
                onChange={field.onChange}
                disabled
              />
              <HelperText id='publishedAt' error={getError("publishedAt")} />
            </div>
          )}
        />
      </div>

      <Controller
        name='commentForModerator'
        defaultValue={initialValues?.commentForModerator}
        render={({ field: { value, ...other } }) => (
          <FormControl fullWidth>
            <TextField
              label={<Text>Комментарий для модератора</Text>}
              value={value ?? ""}
              error={!!getError("commentForModerator")}
              {...other}
              multiline
              InputProps={{
                inputComponent: TextareaAutosize
              }}
              disabled={!canEdit}
            />

            <HelperText error={getError("commentForModerator")} />
          </FormControl>
        )}
      />

      <Controller
        name='isPay'
        defaultValue={TYPE_PAY[Number(Boolean(initialValues?.isPay))].name}
        render={({ field: { value = [], ...other } }) => (
          <FormControl className='basis-1/2'>
            <InputLabel htmlFor='isPay'>
              <Text>Оплата</Text>
            </InputLabel>
            <Select id='isPay' variant='outlined' value={value} disabled={!canEdit} {...other}>
              {TYPE_PAY.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name='organizer'
        rules={baseRequired}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              // key={value?.name}
              id='organizer'
              freeSolo
              className='w-full'
              defaultValue={
                isCreateMode && organizations.length === 1
                  ? organizations.at(0)
                  : initialValues?.organizer
              }
              options={organizations}
              getOptionLabel={(option: any) => option?.name || ""}
              noOptionsText={<Text>No options</Text>}
              size='small'
              disabled={!canEdit}
              value={value}
              onChange={(_: any, value: any) => {
                setValue("organizer", value);
              }}
              renderOption={(props: any, option: any) => (
                <Fragment key={option.id}>
                  <MenuItem {...props} id={option.id}>
                    {option.name}
                  </MenuItem>
                </Fragment>
              )}
              renderInput={(params: any) => (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!canEdit}
                    multiline
                    error={getError("organizer")}
                    label={<Text>Организация</Text>}
                  />
                </Box>
              )}
            />
            <HelperText error={getError("organizer")} />
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name='specialties'
        defaultValue={Array.isArray(initialValues?.specialties) ? initialValues?.specialties : []}
        render={({ field: { onChange } }) => (
          <MuiAutocomplete
            defaultValue={
              Array.isArray(initialValues?.specialties) ? initialValues?.specialties : []
            }
            multiple
            disabled={!canEdit}
            disableCloseOnSelect
            options={(Array.isArray(specialties) ? specialties : []) as SpecialtiesModel[]}
            getOptionLabel={(option: SpecialtiesModel) => option.name}
            onChange={(_: any, values: SpecialtiesModel[]) => {
              onChange(values);
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                multiline
                disabled={!canEdit}
                label={<Text>Specialties</Text>}
                helperText={errors.specialties?.message?.toString()}
                error={!!errors.specialties}
              />
            )}
          />
        )}
      />

      <Controller
        name='city'
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id='city'
              disabled={!canEdit}
              freeSolo
              className='w-full'
              defaultValue={initialValues?.city}
              options={cities}
              getOptionLabel={(option: any) => option?.name || ""}
              noOptionsText={<Text>No options</Text>}
              size='small'
              value={value}
              onChange={(_: any, value: any) => {
                setValue("city", value);
              }}
              renderOption={(props: any, option: any) => (
                <Fragment key={option.id}>
                  <MenuItem {...props} id={option.id}>
                    {option.name}
                  </MenuItem>
                </Fragment>
              )}
              renderInput={(params: any) => (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!canEdit}
                    multiline
                    label={<Text>City</Text>}
                  />
                </Box>
              )}
            />
            <HelperText id='city' error={getError("city")} />
          </FormControl>
        )}
      />

      <Controller
        name='link'
        defaultValue={initialValues?.link}
        render={({ field }) => (
          <FormControl fullWidth>
            <LinkInput
              label={<Text>Link</Text>}
              {...field}
              disabled={!canEdit}
              {...register("link", getBaseUrlValidation({ required: false }))}
              value={field.value ?? ""}
              fullWidth
              error={!!getError("link")}
            />
            <HelperText error={getError("link")} />
          </FormControl>
        )}
      />
    </Form>
  );
};
