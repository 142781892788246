import { useCallback, useState } from "react";
import { StatusesExpertise } from "../types/statusesExpertise";
import { omit } from "rambda";

export const isObjectEquals = (object1: any, object2: any) => {
  const strings = [object1, object2].map((obj) =>
    JSON.stringify(omit(["published", "publishedAt", "rejectReason"], obj))
  );

  return strings[0] === strings[1];
};

type FormValues = {
  id?: number | null;
  status?: { id?: number | null } | null;
  published?: boolean | null;
};

export const useInputStatus = ({
  isModerator,
  currentStatusId
}: {
  isModerator: boolean;
  currentStatusId?: number | null;
}) => {
  const [initialValue, setInitialValue] = useState<FormValues>();

  const prepareInputStatus = useCallback(
    (newValue: FormValues) => {
      const canChangePublication = [
        StatusesExpertise.ON_PUBLISHING_ID,
        StatusesExpertise.PUBLISHED_ID,
        StatusesExpertise.REMOVED_FROM_PUBLICATION_ID
      ].includes(currentStatusId as StatusesExpertise);

      if (!initialValue?.id || !canChangePublication) {
        return newValue;
      }

      const hasChangedValue = !isObjectEquals(initialValue, newValue);

      const isOnlyPublishedChange = initialValue.published !== newValue.published;

      if (!hasChangedValue && isOnlyPublishedChange) {
        const statusId = newValue.published
          ? StatusesExpertise.PUBLISHED_ID
          : StatusesExpertise.REMOVED_FROM_PUBLICATION_ID;
        return {
          ...newValue,
          published: newValue.published,
          status: { id: statusId }
        };
      }

      if (!hasChangedValue) {
        return newValue;
      }

      let statusId: number;

      if (isModerator) {
        statusId = newValue?.published
          ? StatusesExpertise.PUBLISHED_ID
          : StatusesExpertise.REMOVED_FROM_PUBLICATION_ID;
      } else {
        statusId = hasChangedValue
          ? StatusesExpertise.DRAFT_ID
          : StatusesExpertise.REMOVED_FROM_PUBLICATION_ID;
      }

      const canPublishWithoutChanges = isModerator || !hasChangedValue;

      const shouldPublishAfterChanges = isModerator && newValue?.published;

      const isPublished = canPublishWithoutChanges
        ? newValue?.published
        : shouldPublishAfterChanges;

      return {
        ...newValue,
        published: statusId === StatusesExpertise.PUBLISHED_ID && isPublished,
        status: { id: statusId }
        // published: true,
        // status: { id: StatusesExpertise.PUBLISHED_ID }
      };
    },
    [initialValue]
  );

  return { prepareInputStatus, setInitialValue };
};
