import { create } from "zustand";

export type PageState<T> = {
  pageData: T[];
  setPageData: (data: T[]) => void;
  resetPageData: () => void;
};

type T = Record<string, unknown>;

export const usePageStore = create<PageState<T>>((set) => ({
  pageData: [],
  setPageData: (data) => set({ pageData: data }),
  resetPageData: () => set({ pageData: [] })
}));
