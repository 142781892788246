import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { useEffect } from "react";
import {
  NewsResolver_News_FilterInputType,
  useDissertationCouncilsQuery
} from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { DissertationCouncilsPageCreate } from "~/shared/routes";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { useRequestState } from "~/shared/hooks/useRequestState";
import { TablePagination } from "~/shared/components/TablePagination";
import { TableActions } from "~/shared/components/TableActions";
import { EmptyView } from "~/shared/components/EmptyView";
import { getEventValueHandler } from "~/shared/lib/events";
import { useColumns } from "./lib/useColumns";
import { CustomCheckbox } from "~shared/components/CustomCheckbox";
import { TableWrapper } from "~shared/components/TableWrapper";
import { useSelected } from "~/shared/hooks/useSelected";
import { FiltersForm } from "./components/FiltersForm";
import { TableHeadSettingsCell } from "~/shared/components/TableHeadSettingsCell";
import { useCheckboxes } from "~/shared/hooks/useCheckboxes";
import { TableCellSortable } from "~/shared/components/SortableTable/TableCellSortable";
import { MultipleActions } from "./components/MultipleActions";

import { useChips } from "~shared/hooks/useChips";
import { usePageStore } from "~/shared/stores/pageStore";
import { Loader } from "~/shared/components/Loader";
import { useRole } from "~/entities/Roles";
import dayjs from "dayjs";

export const DissertationCouncilsTable: React.FC = () => {
  const { hasPermissions } = useRole();

  const {
    title,
    params,
    activeOrder,
    pagination,
    variables,
    handleTitleChange,
    handleChangePage,
    handleFilterChange,
    handleChangeOrder,
    resetFilters,
    resetTitle,
    removeFilter,
    handleSubmit
  } = useRequestState<NewsResolver_News_FilterInputType>("name", {
    filterFormats: {
      name: (value) => ({ like: `%${value || ""}%` } as never),
      published: (value) => (value ? { eq: !!value } : undefined) as never,
      publishedAt: (value) =>
        ({
          ...(Boolean(value) && {
            between: [
              dayjs(value as string).format("YYYY-MM-DDT00:00:00.000[Z]"),
              dayjs(value as string).format("YYYY-MM-DDT23:59:59.999[Z]")
            ]
          })
        } as never)
    },
    notUseMyOrganizationFilter: hasPermissions(["moderator_content_science"])
  });

  const client = useGraphqlClient();

  const { setCount, setLoading } = usePageLoadingStore((state) => ({
    setLoading: state.setLoading,
    setCount: state.setCount
  }));

  const { setPageData } = usePageStore((state) => ({
    setPageData: state.setPageData
  }));

  const { data, isLoading, refetch } = useDissertationCouncilsQuery(client, variables, {
    refetchOnMount: "always",
    cacheTime: 0
  });

  const dissertationCouncils = data?.dissertationCouncils;

  const total = dissertationCouncils?.data.length ?? 0;

  const totalPages = Math.ceil((dissertationCouncils?.count ?? 0) / pagination.perPage);

  const { columns } = useColumns(activeOrder, handleChangeOrder);

  const { checkboxes, handleChangeSelectedColumns, selectedColumns } = useCheckboxes({
    columns,
    storageKey: "dissertationCouncils_columns_selected"
  });

  const prepareFilters = useChips(params);

  const {
    selected,
    allChecked,
    indeterminate,
    getCheckedHandler,
    onSelectAllClick,
    clearSelected
  } = useSelected(total, dissertationCouncils?.data ?? []);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  useEffect(() => {
    setCount(dissertationCouncils?.count || 0);
    if (dissertationCouncils) setPageData(dissertationCouncils.data);
  }, [setCount, dissertationCouncils, setPageData]);

  const mayCreateAndDelete = hasPermissions(["all", "management_content_science"]);

  return (
    <TableWrapper>
      <Box className='w-full flex flex-col'>
        <TableActions
          searchProps={{
            searchValue: title,
            searchChange: getEventValueHandler(handleTitleChange),
            resetTitle
          }}
          addButtonProps={
            mayCreateAndDelete ? { addHref: DissertationCouncilsPageCreate } : undefined
          }
          resetFilters={resetFilters}
          filters={prepareFilters}
          removeFilter={removeFilter}
          excludedChipsKeys={["category"]}
          handleSubmit={handleSubmit}
          filterModalInnerForm={
            <FiltersForm params={params} handleChangeFilter={handleFilterChange} />
          }
        />

        <TableContainer>
          <Table aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableHeadSettingsCell
                  checkboxes={checkboxes}
                  onChange={handleChangeSelectedColumns}
                  isLoading={isLoading}
                />

                {mayCreateAndDelete && (
                  <TableCell padding='checkbox'>
                    <CustomCheckbox
                      color='primary'
                      indeterminate={indeterminate}
                      checked={allChecked}
                      onChange={onSelectAllClick}
                      inputProps={{
                        "aria-label": "select all dissertationCouncils"
                      }}
                    />
                  </TableCell>
                )}

                {columns.map((column) => {
                  if (!selectedColumns.has(column.id)) {
                    return null;
                  }

                  return (
                    <TableCellSortable
                      key={column.id}
                      id={column.id}
                      isHandleMove
                      isActiveOrder={!!activeOrder?.[column.id]}
                      style={column.style}
                    >
                      {column.label}
                    </TableCellSortable>
                  );
                })}
              </TableRow>
            </TableHead>

            {!isLoading && (
              <TableBody>
                {dissertationCouncils?.data.map((row) => {
                  return (
                    <TableRow hover role='row' tabIndex={-1} key={row.id}>
                      <TableCell />

                      {mayCreateAndDelete && (
                        <TableCell padding='checkbox'>
                          <CustomCheckbox
                            onChange={getCheckedHandler(row.id as number)}
                            color='primary'
                            checked={selected.has(row.id as number)}
                            inputProps={{
                              "aria-labelledby": String(row.id)
                            }}
                          />
                        </TableCell>
                      )}

                      {columns.map((column) => {
                        if (!selectedColumns.has(column.id)) {
                          return null;
                        }

                        const value = row[column.id];

                        return (
                          <TableCellSortable
                            id={column.id}
                            key={column.id}
                            isActiveOrder={!!activeOrder?.[column.id]}
                            className={column.className}
                            style={column.style}
                            align={column.align}
                          >
                            {/* eslint-disable-next-line */}
                            {/* @ts-ignore */}
                            {column.render?.(value, row) ?? value}
                          </TableCellSortable>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>

          {!dissertationCouncils?.data.length && !isLoading && <EmptyView />}

          {isLoading && <Loader />}
        </TableContainer>
        <MultipleActions
          ids={selected}
          count={total}
          refetch={refetch}
          setSelected={clearSelected}
        />
        {!isLoading && (
          <TablePagination
            totalPages={totalPages}
            page={pagination.page}
            onChangePagination={handleChangePage}
          />
        )}
      </Box>
    </TableWrapper>
  );
};
