import { Validate } from "react-hook-form";
import { isValidUrl } from "./isValidUrl";
import { matchIsValidTel } from "mui-tel-input";
import dayjs, { ConfigType } from "dayjs";

type BaseValidate<FormFields> =
  | Validate<string | undefined, Partial<FormFields>>
  | Record<string, Validate<string | undefined, Partial<FormFields>>>
  | undefined;

export const emailValidation = (value: string) =>
  /^[\w.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?(?:\.[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?)*$/.test(
    value
  );

export const baseRequired = { required: "Обязательное поле" };

export const baseMaxLength = {
  maxLength: {
    value: 255,
    message: "Max length 255 character"
  }
};
export const baseMaxLengthRequired = {
  ...baseRequired,
  ...baseMaxLength
};

export const baseMinLengthText = {
  minLength: {
    value: 1,
    message: "Обязательное поле"
  }
};

export const baseBooleanRequired = {
  validate: {
    boolean: <T>(value: T): true | string => {
      return typeof value === "boolean" ? true : "Обязательное поле";
    }
  }
};

export const baseIdRequired = {
  validate: {
    id: <T>(value: T): true | string => {
      return (typeof value === "number" && value) ||
        (value && "id" in ((value ?? {}) as any) && (value as any)?.id)
        ? true
        : "Обязательное поле";
    }
  }
};

export const baseArrayItemsRequired = {
  validate: {
    id: <T>(value: T): true | string => {
      return Array.isArray(value) && value?.length ? true : "Обязательное поле";
    }
  }
};

export const baseRequiredTextValidation = {
  ...baseRequired,
  ...baseMinLengthText,
  validate: {
    whiteSpace: <T>(value: T): true | string => {
      return typeof value === "string" ? value.trim().length > 0 || "Обязательное поле" : true;
    }
  }
};

export const baseDateValidation = {
  validate: {
    isValidDate: (value: ConfigType) => {
      return !value || dayjs(value).isValid() || "Invalid date";
    },
    isValidMinDate: (value: ConfigType) =>
      !value || dayjs(value).isAfter(dayjs("1900-01-01T00:00:00.000")) || "Invalid min date",
    isValidMaxDate: (value: ConfigType) =>
      !value || dayjs(value).isBefore(dayjs("2100-01-01T00:00:00.000")) || "Invalid max date"
  }
};

export const isEmpty = (value: string) => value.replace(/\s/gi, "").length === 0;

export const isContainsNumbers = (value: string) => /\d/.test(value);

export const getBaseEmailValidation = <FormFields>(
  { required }: { required: boolean } = { required: false }
) => ({
  ...(Boolean(required) && baseRequired),
  validate: {
    validEmail: (value: string) => (value && !emailValidation(value) ? "Invalid email" : true)
  } as BaseValidate<FormFields>
});

export const getBaseUrlValidation = <FormFields>(
  { required }: { required: boolean } = { required: false }
) => ({
  ...(Boolean(required) && baseRequired),
  validate: {
    validUrl: (value: string) => (value && !isValidUrl(value) ? "Некорректная ссылка" : true)
  } as BaseValidate<FormFields>
});

export const getBasePasswordValidation = (required = true) => ({
  ...(Boolean(required) && baseRequired),
  minLength: { value: 6, message: "Min length of password is 6" }
});

export const getPhoneValidation = (required = true) => ({
  ...(Boolean(required) && baseRequired),
  validate: (value: string) => {
    const phone = `+${value}`.replace(/\+\+/g, "+");
    return !value || matchIsValidTel(phone) || "Invalid contact phone";
  }
});

export const getFullNameValidation = <FormFields>(
  { required }: { required: boolean } = { required: false }
) => ({
  ...(Boolean(required) && baseRequired),
  validate: {
    checkEmpty: (value: string) => (value && isEmpty(value) ? "Empty field" : true),
    checkNumbers: (value: string) =>
      value && isContainsNumbers(value) ? "Field contains numbers" : true
  } as BaseValidate<FormFields>
});

export const getJSONValidation = () => ({
  validate: {
    isValidJSONObject: (value: string) => {
      if (!value) {
        return true;
      }
      try {
        const parsedValue = JSON.parse(value);
        return (
          (!Array.isArray(parsedValue) && typeof parsedValue === "object") || "Invalid format JSON"
        );
      } catch {
        return "Invalid JSON";
      }
    }
  }
});
