import React from "react";

export const HomeIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 3.19L14 7.69V15.5H12V9.5H6V15.5H4V7.69L9 3.19ZM9.66896 1.10207C9.28866 0.759794 8.71134 0.759794 8.33103 1.10207L0.936995 7.7567C0.255777 8.3698 0.689474 9.5 1.60596 9.5H2V16.5C2 17.0523 2.44772 17.5 3 17.5H7C7.55228 17.5 8 17.0523 8 16.5V11.5H10V16.5C10 17.0523 10.4477 17.5 11 17.5H15C15.5523 17.5 16 17.0523 16 16.5V9.5H16.394C17.3105 9.5 17.7442 8.3698 17.063 7.75671L9.66896 1.10207Z'
        fill='#5229DD'
      />
    </svg>
  );
};
