import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import clsx from "clsx";

type Props = PropsWithChildren & {
  className?: string;
};

export const Panel: React.FC<Props> = ({ children, className }) => {
  return (
    <Box className={clsx("flex flex-col m-8 mt-4 rounded-lg bg-mainBg w-full", className)}>
      {children}
    </Box>
  );
};
