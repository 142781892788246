import { AudienceTargetsModel } from "~/generated/graphql";

const audienceFilter = (category: number) => (row: AudienceTargetsModel) => {
  if (!category) {
    return true;
  }
  return !!row.categories?.find((cat) => cat.id == category);
};

export const audienceFilterByName = (categoryName: string) => (row: AudienceTargetsModel) => {
  if (!categoryName) {
    return true;
  }
  return !!row.categories?.find((cat) => cat.name == categoryName);
};

export default audienceFilter;
