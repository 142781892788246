import { SortType } from "~/generated/graphql";
import { ActiveOrder } from "~/shared/types/ActiveOrder";

export const getClickSortHandler =
  (name: string, handleChangeOrder?: (order: ActiveOrder) => void, activeOrder?: ActiveOrder) =>
  () => {
    if (activeOrder?.[name] && activeOrder[name] === SortType.Desc) {
      return handleChangeOrder?.(null);
    }

    const direction = activeOrder?.[name] === SortType.Asc ? SortType.Desc : SortType.Asc;

    return handleChangeOrder?.({ [name]: direction });
  };

export const getActiveSortProps = (
  name: string,
  activeOrder?: ActiveOrder
): {
  active: boolean;
  direction: "asc" | "desc";
} => ({
  active: !!activeOrder?.[name],
  direction: activeOrder?.[name]
    ? (activeOrder[name].toLocaleLowerCase() as "asc" | "desc")
    : "desc"
});
