import { Box, CircularProgress, Link, Typography } from "@mui/material";
import React from "react";
import { PageTitle } from "../PageTitle";
import { Text } from "../Text";
import { ExternalLinkIcon } from "~shared/components/Icons";

type Props = {
  title: string;
  count?: number;
  isLoading?: boolean;
  sitePath?: string;
  counterWithTitle?: string;
};

export const PageTableTitle: React.FC<Props> = ({
  title,
  count,
  isLoading,
  sitePath,
  counterWithTitle
}) => {
  const isWithCount = typeof count !== "undefined";

  return (
    <PageTitle>
      <Text className='text-mainText font-bold text-[24px]' component='p'>
        {title}
      </Text>
      {isWithCount && counterWithTitle && (
        <div className='flex items-center gap-2 font-semibold font-base md:text-xl mb-6 md:mb-12 w-full'>
          {counterWithTitle}
          {isLoading ? (
            <CircularProgress className='!w-[30px] !h-[30px]' />
          ) : (
            <span>{count || 0}</span>
          )}
        </div>
      )}
      {isWithCount && !counterWithTitle && (
        <Box component='p' className='flex items-end pb-[2px]'>
          <Text className='text-secondaryText pt-[4px]' component='span'>
            Count
          </Text>
          <Typography className='text-secondaryText pt-[4px]' component='span'>
            :&nbsp;&nbsp;
          </Typography>
          {isLoading && (
            <Text className='text-secondaryText pt-[4px]' component='span'>
              ...
            </Text>
          )}
          {!isLoading && (
            <Text className='text-secondaryText pt-[4px]' component='span'>{`${count || 0}`}</Text>
          )}
        </Box>
      )}
      {sitePath && (
        <Link
          className='flex gap-1 items-center hover:opacity-80 cursor:pointer'
          href={`${process.env.REACT_APP_SITE_URL}${sitePath === "/" ? "" : sitePath}`}
          target='_blank'
        >
          <ExternalLinkIcon />
          <Text className='underline text-blueText font-medium'>Page on the site</Text>
        </Link>
      )}
    </PageTitle>
  );
};
