import React, { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  PracticesModel,
  usePracticesByIdQuery,
  usePracticesDeclineMutation,
  usePracticesEventsMassDeleteMutation,
  usePracticesEventsMassUpsertMutation,
  usePracticesPublishMutation,
  usePracticesSendToModerateMutation,
  usePracticesUpsertMutation
} from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { TabsForm } from "~/entities/TabsForm";
import { initFormValues } from "~/shared/lib/initFormValues";
import { useNavigationBack } from "~/shared/hooks/useBackClick";
import { GeneralForm } from "./components/GeneralForm";
import { prepareFormData } from "./lib/prepareFormData";
import { either, has, isEmpty, omit, prop } from "rambda";
import { useAlertsStore } from "~/shared/stores/alerts";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { useValidationError } from "~/shared/hooks/useValidationError";
import { ContactPersonForm } from "~/entities/ContactPersonFrom";
import { EventsFrom } from "./components/EventsFrom";
import { DirectionsForm } from "./components/DirectionsForm";
import { CategoriesForm } from "./components/CategoriesForm";
import { GrantWinnerForm } from "./components/GrantWinnerForm";
import { Loader } from "~/shared/components/Loader";
import { useRole } from "~/entities/Roles";
import { isFullData } from "~/shared/lib/isFullData";
import { useInputStatus } from "~shared/hooks/useInputStatus";
import { useCanEditByStatus } from "~/entities/Roles/useCanEditOnModeration";
import { getBaseRefInitializer } from "~/shared/hooks/useChangesRef";

type Props = {
  id?: number;
  formName?: string;
  changesGetterRef?: (callback: () => Promise<boolean>) => void;
};

export const PracticeDetailsForm: React.FC<Props> = ({ id, formName, changesGetterRef }) => {
  const [step, setStep] = useState(0);

  const isCreateMode = !Number.isInteger(id);

  const { hasPermissions } = useRole();

  const client = useGraphqlClient();

  const { mutateAsync: sendToModerate } = usePracticesSendToModerateMutation(client);
  const { mutateAsync: decline } = usePracticesDeclineMutation(client);
  const { mutateAsync: publish } = usePracticesPublishMutation(client);

  const { setIsSaveLoading } = usePageLoadingStore((state) => ({
    setIsSaveLoading: state.setIsSaveLoading
  }));

  const addAlert = useAlertsStore((state) => state.addAlert);

  const { data, refetch } = usePracticesByIdQuery(
    client,
    { id: Number(id) },
    {
      enabled: !isCreateMode,
      refetchOnMount: "always",
      cacheTime: 0
    }
  );

  const initialValues = data?.practicesById as PracticesModel;

  const goBack = useNavigationBack();

  const formMethods = useForm({ mode: "all" });
  const {
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    setError,
    getValues,
    watch
  } = formMethods;

  const onError = useValidationError(setError);

  const { mutateAsync: updatePractice, isLoading: isUpsertLoading } = usePracticesUpsertMutation(
    client,
    {
      onError
    }
  );

  const { mutateAsync: upsertEvents, isLoading: isEventsUpsertLoading } =
    usePracticesEventsMassUpsertMutation(client);

  const { mutateAsync: deleteEvents, isLoading: isEventsDeleteLoading } =
    usePracticesEventsMassDeleteMutation(client);

  const isLoading = isUpsertLoading || isEventsUpsertLoading || isEventsDeleteLoading;

  const isGeneralNewsFormError = either(has("name"), has("content"))(errors);

  const { prepareInputStatus, setInitialValue } = useInputStatus({
    isModerator: hasPermissions(["moderator_content_mp_vd"]),
    currentStatusId: initialValues?.status?.id
  });

  changesGetterRef?.(() =>
    getBaseRefInitializer({
      isCreate: isCreateMode,
      value: prepareFormData(initialValues as never),
      newValue: prepareFormData(getValues())
    })
  );

  const onSubmit = handleSubmit((newValues) => {
    prepareFormData(newValues, initialValues, { isCreateMode, touchedFields }).then(
      async (input) => {
        try {
          const practice = await updatePractice({ input: prepareInputStatus(input) });
          goBack();
          const eventsIdsNewValues = new Set(newValues?.events?.map(prop("id")));
          const deleteIdsItems = initialValues?.events
            ?.map(prop("id"))
            ?.filter((id) => !eventsIdsNewValues.has(id));
          await Promise.all([
            upsertEvents({
              input:
                newValues?.events?.map((event: any) => ({
                  id: event?.id,
                  practice: { id: practice?.practicesUpsert?.id }
                })) ?? []
            }),

            deleteIdsItems?.length
              ? deleteEvents({
                  where: { id: { in: deleteIdsItems as unknown as Array<number> } }
                })
              : Promise.resolve()
          ]);
        } catch (e) {
          console.error(e);
        }
      }
    );
  });

  const canEdit =
    useCanEditByStatus({
      isModerator: hasPermissions(["moderator_content_mp_vd"]),
      currentStatusId: initialValues?.status?.id
    }) && !watch("published");

  const getIsFullDataFunction = (value: unknown): boolean => {
    return isFullData(value, [
      "rejectReason",
      "circulation",
      "publishedAt",
      "sort",
      "trophy",
      "commentForModerator",
      "imageDetail"
    ]);
  };
  const forms = useMemo(
    () => [
      {
        tabTitle: "General data",
        hasErrors: isGeneralNewsFormError,
        component: <GeneralForm initialValues={initialValues} canEdit={canEdit} />
      },
      {
        tabTitle: "Contact person",
        component: (
          <ContactPersonForm
            initialValues={{
              contactPerson: initialValues?.contactPerson,
              orgId: initialValues?.organizer?.id
            }}
            disabled={!canEdit}
          />
        )
      },
      {
        tabTitle: (
          <>
            Мероприятия,
            <br /> планируемые в ходе
            <br /> реализации проекта
          </>
        ),
        component: (
          <EventsFrom
            initialValues={{
              items: initialValues?.events,
              practiceId: initialValues?.id
            }}
            refetch={refetch}
            disabled={!canEdit}
          />
        )
      },
      {
        tabTitle: "Направление",
        component: <DirectionsForm disabled={!canEdit} />
      },
      {
        tabTitle: "Category",
        component: <CategoriesForm disabled={!canEdit} />
      },
      {
        tabTitle: "Победители грантовых конкурсов",
        component: <GrantWinnerForm disabled={!canEdit} />
      }
    ],
    [canEdit, isGeneralNewsFormError, initialValues, refetch]
  );

  useEffect(() => {
    if (isCreateMode || !initialValues) {
      return;
    }

    initFormValues(
      Object.keys(omit(["isAllCanSee", "region"], initialValues)),
      setValue,
      initialValues
    );
    setValue(
      "isAllCanSee",
      initialValues?.isAllCanSee ? "для любого зарегистрированного пользователя" : "для вузов"
    );
    setValue("region", initialValues?.region?.id);

    prepareFormData(getValues(), initialValues, { isCreateMode }).then(setInitialValue);
  }, [initialValues, isCreateMode, setValue]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      addAlert("error", "Fill in all required fields");
    }
  }, [errors, step, addAlert]);

  useEffect(() => {
    setIsSaveLoading(isLoading);
  }, [isLoading, setIsSaveLoading]);

  const getIsFullData = getIsFullDataFunction(initialValues);

  if (!isCreateMode && !initialValues) {
    return <Loader />;
  }

  return (
    <FormProvider {...formMethods}>
      <TabsForm
        handleSubmit={onSubmit}
        handleStepChange={setStep}
        activeStep={step}
        formName={formName}
        forms={forms}
        initialValues={initialValues}
        sendToModerate={sendToModerate}
        decline={decline}
        refetch={refetch}
        publish={publish}
        isFullData={getIsFullData}
        sectionModeratorRole='moderator_content_mp_vd'
        canNotSendToModerate={!(hasPermissions(["management_content_mp_vd"]) && getIsFullData)}
      />
    </FormProvider>
  );
};
