import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemProps,
  ImageListProps,
  BoxProps
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import React, { MouseEventHandler, PropsWithChildren, useMemo } from "react";
import { FilesModel } from "~/generated/graphql";
import { resortArray } from "~/shared/lib/resortArray";
import { clsx } from "clsx";
import { updateRowSortByIndex } from "~/shared/lib/resortArray";
import { MultipleFilesInput } from "../MultipleFilesInput";
import { DocumentCard } from "../DocumentCard";
import { getFileFormat } from "~/shared/lib/getFileFormat";
import { Link } from "react-router-dom";
import { getFilePath, getImageSrc } from "~/shared/lib/getImageSrc";

const DragHandle = SortableHandle<BoxProps>(({ className, ...props }: BoxProps) => (
  <Box {...props} className={clsx("bg-white p-1 cursor-move hover:bg-gray-100", className)}>
    <DragIndicatorIcon />
  </Box>
));

const SortableItem = SortableElement<PropsWithChildren<ImageListItemProps>>(
  ({ children, ...props }: PropsWithChildren<ImageListItemProps>) => (
    <ImageListItem {...props}>{children}</ImageListItem>
  )
);

const SortableList = SortableContainer<PropsWithChildren<ImageListProps>>(
  ({ children, ...props }: PropsWithChildren<ImageListProps>) => {
    return <ImageList {...props}>{children}</ImageList>;
  }
);

export type GalleryInputProps = {
  value: Partial<FilesModel>[];
  loading: boolean;
  onChange: (images?: Partial<FilesModel>[]) => void;
  onDelete: (id: number) => Promise<unknown>;
  onUpload: (files: (File | Blob)[]) => Promise<FilesModel[]>;
  disabled?: boolean;
};

export const GalleryFilesInput: React.FC<GalleryInputProps> = ({
  value,
  loading,
  onChange,
  onUpload,
  onDelete,
  disabled
}) => {
  const sortedValue = useMemo(
    () => [...value].sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0)),
    [value]
  );

  const getDeleteHandler =
    (image?: Partial<FilesModel> | null): MouseEventHandler<Element> =>
    async (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (image?.id) {
        await onDelete(image.id);
      }
      onChange(value.filter((item) => item.id !== image?.id));
    };

  const onAddImages = async (files?: File[] | null) => {
    if (!files) {
      return;
    }

    const images = await onUpload(files);
    onChange(value.concat(images).map(updateRowSortByIndex));
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    onChange(resortArray(oldIndex, newIndex, sortedValue));
  };

  const imageExtensions = ["jpg", "png", "gif", "jpeg", "webp", "svg", "bmp"];

  return (
    <Box className={"flex flex-col gap-6 pt-2"}>
      <MultipleFilesInput
        loading={loading}
        onChange={onAddImages}
        className={clsx({ "pointer-events-none": disabled })}
      />
      <SortableList
        cols={8}
        rowHeight={162}
        gap={10}
        lockToContainerEdges={true}
        lockOffset={0}
        onSortEnd={onSortEnd}
        axis='xy'
        useDragHandle
      >
        {sortedValue.map((item, i) => (
          <SortableItem
            index={i}
            key={item.id}
            className={clsx(
              { "pointer-events-none": disabled },
              "relative overflow-hidden w-[162px]"
            )}
          >
            <DragHandle className='absolute top-0 left-0 z-20' />
            <IconButton
              onClick={getDeleteHandler(item)}
              color='error'
              disableRipple
              className='absolute top-0 right-0 bg-red-100 hover:bg-red-200 z-20 active:bg-red-300 rounded-md'
            >
              <CloseIcon />
            </IconButton>
            <Link
              target='_blank'
              className='pointer-events-auto'
              to={`${
                imageExtensions.includes(getFileFormat(item.path ?? ""))
                  ? getImageSrc(item)
                  : getFilePath(item.path)
              }`}
            >
              <DocumentCard title={item.name ?? ""} format={getFileFormat(item.path ?? "")} />
            </Link>
          </SortableItem>
        ))}
      </SortableList>
    </Box>
  );
};
