type Filters = Record<string, unknown>;

const isObject = (item: unknown): boolean =>
  !!item && typeof item === "object" && !Array.isArray(item);

const mergeDeep = <T extends Filters>(target: T, ...sources: Filters[]): T => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key] as T, source[key] as T);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

export const formatFilters = <T>(filters: Filters): T => {
  let formattedFilters = Object.create(null);

  Object.entries(filters).forEach(([key, value]) => {
    const paths = key.split(".");
    const lastPathIndex = paths.length - 1;

    if (paths.length === 1) {
      formattedFilters[key] = value;
      return;
    }

    const dir = Object.create(null);

    paths.reduce((dir, path, i) => {
      dir[path] = {};

      if (i === lastPathIndex) {
        dir[path] = value;
      }

      return dir[path];
    }, dir);

    formattedFilters = mergeDeep(formattedFilters, dir);
  });

  return formattedFilters;
};
