import { DeepPartial } from "react-hook-form";
import { ProjectsModel, ProjectsInput } from "~/generated/graphql";
import { omit } from "rambda";

export const prepareFormData = async (
  newValues: ProjectsInput,
  values?: DeepPartial<ProjectsModel> | null,
  { isCreateMode }: { isCreateMode?: boolean; touchedFields?: Record<string, unknown> } = {}
) => {
  const input: ProjectsInput = {
    id: values?.id,
    ...omit(
      [
        "city",
        "presentation",
        "audienceTarget",
        "annotationImage",
        "slug",
        "rejectReason",
        "publishedAt",
        "leaderDescription"
      ],
      newValues
    ),
    isVrMp: !!newValues?.isVrMp,
    isCollaboration: !!newValues?.isCollaboration,
    video: newValues.video ? { id: newValues.video?.id } : null,
    image: newValues.image
      ? {
          id: newValues.image?.id
        }
      : null,
    annotationImage: newValues.annotationImage
      ? {
          id: newValues.annotationImage?.id
        }
      : null,
    presentation: newValues.presentation
      ? {
          id: newValues.presentation?.id,
          name: newValues.presentation?.name
        }
      : null,
    audienceTarget: newValues.audienceTarget
      ? {
          id: newValues.audienceTarget?.id
        }
      : null,
    imageDetail: newValues.imageDetail
      ? {
          id: newValues.imageDetail?.id
        }
      : null,

    documentsGallery: {
      ...newValues.documentsGallery,
      files: (newValues.documentsGallery?.files ?? []).map((item) => ({
        id: item.id,
        sort: item.sort
      }))
    },
    contactPerson: newValues.contactPerson
      ? {
          id: newValues.contactPerson?.id,
          org: { id: newValues.contactPerson?.org?.id || newValues.organizer?.id }
        }
      : null,
    leaderPerson: newValues.leaderPerson
      ? {
          id: newValues.leaderPerson?.id,
          org: { id: newValues.leaderPerson?.org?.id || newValues.organizer?.id }
        }
      : null,

    ...(Boolean(newValues.organizer) && { organizer: { id: newValues.organizer?.id } }),
    ...(Boolean(newValues.city) && { city: { id: newValues.city?.id } }),
    competencies: newValues.competencies?.map((com) => ({ id: com?.id })) ?? []
  };

  if (isCreateMode) delete input.id;
  return input;
};
