import React, { useMemo } from "react";
import { TableHeadCell } from "~/shared/components/TableHeadLabel";
import { Link } from "~/shared/components/Link";
import { formatDateForTable } from "~/shared/lib/formatDate";
import { LabsPageEdit } from "~/shared/routes";
import { useSortProps } from "~/shared/hooks/useSortProps";
import { ActiveOrder } from "~/shared/types/ActiveOrder";
import { Column } from "../types";
import { formatDescriptionForTable } from "~/shared/lib/formatDescriptionForTable";
import { LabsModel } from "~/generated/graphql";
import { useResortColumns } from "~/shared/hooks/useResortColumns";
import { getTableCellDefaultStyles } from "~/shared/lib/getTableCellDefaultStyles";
import { StatusColorWrapper } from "~/shared/components/StatusColorWrapper";

const orderKey = "__news-columns-order";

export const useColumns = (
  activeOrder?: ActiveOrder,
  handleOrderClick?: (_activeOrder: ActiveOrder) => void
) => {
  const { getClickHandler, getActiveProps } = useSortProps(handleOrderClick, activeOrder);

  const defaultColumns: Record<Column["id"], Column> = useMemo(
    () => ({
      id: {
        id: "id",
        label: (
          <TableHeadCell
            title='ID'
            cellId='id'
            onSortClick={getClickHandler("id")}
            sortProps={getActiveProps("id")}
          />
        ),
        style: { ...getTableCellDefaultStyles(50), paddingRight: 24 },
        className: "text-grayLight cursor-default",
        defaultSelected: true,
        selectTitle: "ID"
      },
      name: {
        id: "name",
        label: (
          <TableHeadCell
            title='Designation name'
            cellId='name'
            onSortClick={getClickHandler("name")}
            sortProps={getActiveProps("name")}
          />
        ),
        style: { ...getTableCellDefaultStyles(300), cursor: "default" },
        render: (value = "", row: LabsModel) => {
          return (
            <Link className='transition-all' to={`${LabsPageEdit.replace(":id", String(row.id))}`}>
              {formatDescriptionForTable(value) === ""
                ? "Без названия"
                : formatDescriptionForTable(value)}
            </Link>
          );
        },
        defaultSelected: true,
        selectTitle: "Designation name"
      },
      description: {
        id: "description",
        label: (
          <TableHeadCell
            title='Description'
            cellId='description'
            onSortClick={getClickHandler("description")}
            sortProps={getActiveProps("description")}
          />
        ),
        style: { ...getTableCellDefaultStyles(300), cursor: "default" },
        render: (value: any, row: LabsModel) => {
          return (
            <Link className='transition-all' to={`${LabsPageEdit.replace(":id", String(row.id))}`}>
              {formatDescriptionForTable(value)}
            </Link>
          );
        },
        defaultSelected: true,
        selectTitle: "Description"
      },
      city: {
        id: "city",
        label: <TableHeadCell title='City' cellId='city' />,
        style: { ...getTableCellDefaultStyles(300), cursor: "default" },
        render: (value: any, row: LabsModel) => {
          return (
            <Link className='transition-all' to={`${LabsPageEdit.replace(":id", String(row.id))}`}>
              {value?.name}
            </Link>
          );
        },
        defaultSelected: true,
        selectTitle: "Description"
      },
      status: {
        id: "status",
        label: <TableHeadCell title='Status' cellId='status' />,
        style: { ...getTableCellDefaultStyles(150), cursor: "default" },
        defaultSelected: true,
        selectTitle: "Status",
        render: (value: { name: string; id: number }) => <StatusColorWrapper value={value} />
      },
      publishedAt: {
        id: "publishedAt",
        label: (
          <TableHeadCell
            title='Published at'
            align='center'
            cellId='published_at'
            onSortClick={getClickHandler("publishedAt")}
            sortProps={getActiveProps("publishedAt")}
          />
        ),
        style: { ...getTableCellDefaultStyles(170), cursor: "default" },
        align: "center",
        render: formatDateForTable,
        defaultSelected: true,
        selectTitle: "Published at"
      }
    }),
    [getActiveProps, getClickHandler]
  );

  const { columns, onSortColumnsEnd } = useResortColumns(defaultColumns, orderKey);

  return { columns, onSortColumnsEnd };
};
