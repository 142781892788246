import { useCallback } from "react";
import { useSelectedColumns } from "./useSelectedColumns";

type Column = {
  id: string;
  selectTitle: string;
  defaultSelected: boolean;
};

export const useCheckboxes = ({
  columns,
  storageKey
}: {
  columns: Column[];
  storageKey: string;
}) => {
  const { selectedColumns, setSelectedColumns } = useSelectedColumns(
    columns.reduce((res: string[], column) => {
      if (column.defaultSelected) {
        res.push(column.id);
      }

      return res;
    }, []),
    storageKey
  );

  const checkboxes = columns.map((column) => ({
    id: column.id,
    selected: selectedColumns.has(column.id),
    name: column.selectTitle
  }));

  const handleChangeSelectedColumns = useCallback(
    (checkboxes: { id: string; selected: boolean }[]) => {
      const newSelectedColumns = checkboxes.reduce((res, checkbox) => {
        if (checkbox.selected) {
          res.add(checkbox.id);
        }

        return res;
      }, new Set<string>());

      setSelectedColumns(newSelectedColumns);
    },
    [setSelectedColumns]
  );

  return { checkboxes, handleChangeSelectedColumns, selectedColumns };
};
