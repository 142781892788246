import dayjs, { Dayjs } from "dayjs";

export const deepDropTimezone = <T>(object: T): T => {
  if (["number", "boolean", "undefined", "bigint", "symbol", "function"].includes(typeof object)) {
    return object;
  }

  if (typeof object === "object" && !object) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map((item) => deepDropTimezone(item)) as T;
  }

  if (object instanceof File) {
    return object;
  }

  if (typeof object === "object") {
    const newObject: T = {} as T;

    Object.keys(object ?? {}).forEach((key) => {
      newObject[key as keyof T] = deepDropTimezone(
        object?.[key as keyof T] as T
      ) as unknown as T[keyof T];
    });

    return newObject as T;
  }

  if (typeof object === "string" && dayjs(object).isValid()) {
    return object?.replace(
      "Z",
      `${new Date()?.toString()?.match(/([-+][0-9]+)\s/)?.[1] ?? ""}`
    ) as T;
  }

  return object;
};

export const deepSetTimezone = <T>(object: T): T => {
  if (["number", "boolean", "undefined", "bigint", "symbol", "function"].includes(typeof object)) {
    return object;
  }

  if (typeof object === "object" && !object) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map((item) => deepSetTimezone(item)) as T;
  }

  if (object instanceof File) {
    return object;
  }

  if (typeof object === "object" && dayjs(object as Dayjs).isValid()) {
    return dayjs(object as Dayjs).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") as T;
  }

  if (typeof object === "object") {
    const newObject: T = {} as T;

    Object.keys(object ?? {}).forEach((key) => {
      const needTranspile =
        key.includes("At") ||
        key.includes("start") ||
        key.includes("end") ||
        key.includes("date") ||
        key.includes("Date") ||
        key.includes("time") ||
        key.includes("Time") ||
        typeof object?.[key as keyof T] === "object";

      newObject[key as keyof T] = object?.[key as keyof T] as unknown as T[keyof T];
      if (needTranspile) {
        newObject[key as keyof T] = deepSetTimezone(
          object?.[key as keyof T] as T
        ) as unknown as T[keyof T];
      }
    });

    return newObject as T;
  }

  if (typeof object === "string" && dayjs(object).isValid()) {
    return dayjs(deepDropTimezone(object)).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") as T;
  }

  return object;
};
