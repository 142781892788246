import { FilesModel } from "~/generated/graphql";
import { DeepPartial } from "react-hook-form";

// type Options = {
//   quality?: number;
//   width?: number;
// };

export const getImageSrc = (
  image: DeepPartial<FilesModel> | null | undefined
  // { quality = 75, width = 0 }: Options = {}
) => {
  if (!image?.path) {
    return null;
  }

  // const stringifyOptions = `q:${quality}/w:${width}`;

  // const normalizedPath = image.path.replace("uploads/", "");
  // TODO: проверить почему не работает imageproxy
  // return `${process.env.REACT_APP_IMAGES_URL}insecure/${stringifyOptions}/plain/local:///uploads/${normalizedPath}`;

  return image?.path ? `${process.env.REACT_APP_FILES_URL}${image.path}` : null;
};

export const getOriginalImageSrc = (image: DeepPartial<FilesModel> | null | undefined) => {
  return image?.path ? `${process.env.REACT_APP_FILES_URL}${image.path}` : null;
};

export const getFilePath = (path: string | undefined) => {
  if (!path) return "";
  return `${process.env.REACT_APP_FILES_URL}${path}`;
};
