import React from "react";
import { useDocumentDeleteMutation, useDocumentMassUploadMutation } from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { GalleryInputProps } from "~shared/components/GalleryInput";
import { GalleryFilesInput } from "~/shared/components/GalleryFilesInput";
import { useAlertsStore } from "~/shared/stores/alerts";

type Props = Omit<GalleryInputProps, "onDelete" | "onUpload" | "loading">;

export const ConnectedFilesInput: React.FC<Props> = (props) => {
  const client = useGraphqlClient();

  const { mutateAsync: massUploadMutation, isLoading } = useDocumentMassUploadMutation(client, {
    onError: () => {
      useAlertsStore.getState().addAlert("error", "Ошибка при загрузке файла");
    }
  });

  const { mutateAsync: deleteMutation } = useDocumentDeleteMutation(client);

  const handleUpload = (documents: (File | Blob)[]) =>
    massUploadMutation({ documents }).then(({ documentMassUpload }) => documentMassUpload);

  const handleDelete = (id: number) => deleteMutation({ id });

  return (
    <GalleryFilesInput
      loading={isLoading}
      onUpload={handleUpload}
      onDelete={handleDelete}
      {...props}
    />
  );
};
