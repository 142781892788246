import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { useGetApplicationHistoryQuery } from "~/generated/graphql";
import { TableWrapper } from "~/shared/components/TableWrapper";
import { getFilePath } from "~/shared/lib/getImageSrc";

type Props = {
  id: string | undefined;
};

export const ExpertisesApplicationsHistory: React.FC<Props> = ({ id }) => {
  const client = useGraphqlClient();
  const { data } = useGetApplicationHistoryQuery(client, { getApplicationHistoryId: Number(id) });

  return (
    <>
      <div className='container pb-4'>
        {data?.getApplicationHistory.length ? (
          <div>
            <h2 className='text-xl text-center'>
              {`Наименование учебного курса: «${data.educationsById?.name}»`}
            </h2>
            <TableWrapper>
              <TableContainer>
                <Table aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Дата и время</TableCell>
                      <TableCell>Действие</TableCell>
                      <TableCell>Файлы</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.getApplicationHistory?.map((row) => (
                      <TableRow key={row?.date}>
                        <TableCell>{row?.date}</TableCell>
                        <TableCell>{row?.status}</TableCell>
                        <TableCell>
                          <Link target='blanc' to={getFilePath(row?.file?.path)}>
                            {row?.file?.name}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableWrapper>
          </div>
        ) : (
          <h2 className='text-xl text-center'>История отсутствует</h2>
        )}
      </div>
    </>
  );
};
