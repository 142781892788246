import { useCallback, useEffect, useState } from "react";
import { resortArray } from "../lib/resortArray";

export const useResortColumns = <
  Key extends string | number | symbol,
  Column extends { id: string }
>(
  defaultColumns: Record<Key, Column>,
  orderKey: string
) => {
  const [columns, setColumns] = useState<Column[]>(Object.values(defaultColumns));

  const handleSetColumns: React.Dispatch<React.SetStateAction<Column[]>> = useCallback(
    (args) => {
      setColumns((oldValue) => {
        const newValue = typeof args === "object" ? args : args(oldValue);
        localStorage.setItem(orderKey, newValue.map((column) => column.id).join(","));

        return newValue;
      });
    },
    [orderKey]
  );

  const onSortColumnsEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      handleSetColumns((oldColumns) => {
        const newColumns = resortArray(oldIndex, newIndex, oldColumns);
        return newColumns;
      });
    },
    [handleSetColumns]
  );

  useEffect(() => {
    const order = localStorage.getItem(orderKey);

    if (!order) {
      return;
    }

    const initialColumns = order
      .split(",")
      .map((columnId) => defaultColumns[columnId as keyof typeof defaultColumns]);

    setColumns(initialColumns);
  }, [orderKey, defaultColumns]);

  useEffect(() => {
    setColumns((oldColumns) => oldColumns.map((column) => defaultColumns[column.id as Key]));
  }, [defaultColumns]);

  return { columns, onSortColumnsEnd };
};
