import React from "react";
import { CircularProgress } from "@mui/material";
import { Maybe, PracticesEventsModel } from "~/generated/graphql";
import { AppLine } from "~/modules/ExpertisesApplicationsTable/components/Filters/components/FiltersContent";
import { DeepPartial } from "~/modules/ExpertisesApplicationsTable/types";
import { Row } from "../Row";

type Props = {
  isLoading: boolean;
  removeHandler: (id: number) => void;
  openModal: (item: Maybe<DeepPartial<PracticesEventsModel>>) => void;
  items: Maybe<DeepPartial<PracticesEventsModel>>[];
};

export const Rows: React.FC<Props> = ({ isLoading, items, openModal, removeHandler }) => {
  return (
    <div className='flex flex-col gap-4'>
      {isLoading && (
        <div className='w-full flex items-center justify-center'>
          <CircularProgress />
        </div>
      )}
      {!isLoading && items?.length === 0 && (
        <div className='w-full flex items-center justify-center text-gray text-base'>
          Данные отсутствуют
        </div>
      )}
      {items
        .sort((a, b) => (a?.id as number) - (b?.id as number))
        ?.map((item, index) => (
          <div key={item?.id?.toString().concat(index.toString())}>
            <Row model={item} handleOpenEdit={() => openModal(item)} handleDelete={removeHandler} />
            <AppLine />
          </div>
        ))}
    </div>
  );
};
