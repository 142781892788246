import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { Button } from "~/shared/components/Button";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  isLoading?: boolean;
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
};

export const ConfirmDialog: React.FC<Props> = ({
  isOpen,
  handleClose,
  handleConfirm,
  isLoading,
  title = "Подтверждение удаления",
  description = "Вы уверены, что хотите удалить?",
  cancelText = "Отмена",
  confirmText = "Удалить"
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} variant='outlined' onClick={handleClose} color='primary'>
          {cancelText}
        </Button>
        <Button disabled={isLoading} variant='outlined' onClick={handleConfirm} color='error'>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
