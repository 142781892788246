import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Autocomplete as MuiAutocomplete,
  Autocomplete,
  Box
} from "@mui/material";
import React, { Fragment, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import {
  useImageDeleteMutation,
  ScientificJournalsModel,
  useUploadImageMutation,
  SpecialtiesModel,
  useSpecialtiesQuery,
  useScientificJournalsAccessFormatsQuery,
  useOrganisersQuery
} from "~/generated/graphql";
import { HelperText } from "~/shared/components/HelperText";
import { Text } from "~/shared/components/Text";
import { getErrorMessage } from "~/shared/lib/getError";
import { useAlertsStore } from "~/shared/stores/alerts";
import { EntityInfo } from "~shared/components/EntityInfo";
import { AppSwitch } from "~shared/components/AppSwitch";
import { ImageForm } from "~/shared/components/ImageForm";
import { StatusesExpertise } from "~/shared/types/statusesExpertise";
import { DateTimePicker } from "~/shared/components/DateTimePicker";
import { LinkInput } from "~/shared/components/LinkInput";
import {
  baseRequired,
  baseRequiredTextValidation,
  getBaseUrlValidation
} from "~/shared/lib/validation";
import { useCanPublish } from "~/entities/Roles/useCanPublish";
import { useRole } from "~/entities/Roles";
import { useMyOrganisersQuery } from "~/entities/Organizers/lib/useMyOrganisersQuery";
import { unifyName } from "~/shared/lib/unifyName";

type Props = {
  canEdit: boolean;
  initialValues?: Partial<ScientificJournalsModel>;
};

export const GeneralForm: React.FC<Props> = ({ canEdit, initialValues }) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    control
  } = useFormContext();

  const getError = getErrorMessage(errors);

  const addAlert = useAlertsStore((state) => state.addAlert);

  const client = useGraphqlClient();
  const isCreateMode = !Number.isInteger(initialValues?.id);
  const { hasPermissions } = useRole();

  const isAdmin = hasPermissions(["all"]);

  const { data: organizersQueryData } = useMyOrganisersQuery({ enabled: isCreateMode || isAdmin });

  const { data: allOrganizersQueryData } = useOrganisersQuery(
    client,
    {
      where: { id: { eq: initialValues?.organizer?.id } }
    },
    { enabled: !(isCreateMode || isAdmin) }
  );

  const organisersResponse = isCreateMode || isAdmin ? organizersQueryData : allOrganizersQueryData;

  const organizations = useMemo(
    () => unifyName(organisersResponse?.organisers.data ?? []),
    [organisersResponse]
  );

  const { data: accessFormatsData } = useScientificJournalsAccessFormatsQuery(client);

  const accessFormats = accessFormatsData?.scientificJournalsAccessFormats?.data;

  const { mutateAsync: uploadImageMutation } = useUploadImageMutation(client, {
    onError: () => {
      useAlertsStore.getState().addAlert("error", "Ошибка при загрузке файла");
    }
  });

  const { mutateAsync: deleteMutation } = useImageDeleteMutation(client);

  const { data: specialtiesQueryData } = useSpecialtiesQuery(client, {
    where: { isNir: { eq: true } }
  });

  const specialties = specialtiesQueryData?.specialties.data?.filter((item) => item?.parent) ?? [];

  const canChangePublished = useCanPublish(initialValues?.status?.id as StatusesExpertise, [
    "moderator_content_science"
  ]);

  const uploadImageHandler: (image?: File | null) => void = (image) => {
    uploadImageMutation({ image }).then((url) => {
      setValue("image", {
        id: url.imageUpload.id
      });
    });
  };

  const deleteImageHandler = async () => {
    const imageId = getValues("image")?.id;
    await deleteMutation({ id: Number(imageId) });
    setValue("image", null);
  };

  const [showVAKFields, setShowVAKFields] = React.useState(initialValues?.inVAK || false);

  React.useEffect(() => {
    setShowVAKFields(getValues("inVAK") as any);
  }, [getValues]);
  useEffect(() => {
    if (isCreateMode && organizations.length === 1) {
      setValue("organizer", organizations.at(0));
    }
  }, [isCreateMode, organizations]);

  return (
    <ImageForm
      addAlert={addAlert}
      onChange={uploadImageHandler}
      onDelete={deleteImageHandler}
      single
      disabled={!canEdit}
      asideChildren={
        <EntityInfo createdAt={initialValues?.createdAt} updatedAt={initialValues?.updatedAt} />
      }
    >
      <div className='flex flex-wrap gap-2'>
        <Controller
          name='published'
          defaultValue={initialValues?.published || false}
          render={({ field }) => (
            <AppSwitch
              checked={!!field.value}
              disabled={!canChangePublished}
              label='Published'
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name='publishedAt'
          render={({ field }) => (
            <div className='max-sm:grow'>
              <DateTimePicker
                label={<Text>Published at</Text>}
                value={field.value ?? null}
                className='w-full'
                onChange={field.onChange}
                disabled
              />
              <HelperText id='publishedAt' error={getError("publishedAt")} />
            </div>
          )}
        />
      </div>

      <Controller
        name='name'
        rules={baseRequiredTextValidation}
        defaultValue={initialValues?.name}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              disabled={!canEdit}
              label={<Text>Наименование</Text>}
              value={value?.name || value}
              multiline
              fullWidth
              error={!!getError("name")}
              InputProps={{
                inputComponent: TextareaAutosize
              }}
              {...register("name")}
            />
            <HelperText error={getError("name")} />
          </FormControl>
        )}
      />

      <Controller
        name='accessFormat'
        rules={baseRequired}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel htmlFor='accessFormat'>
              <Text>Access format</Text>
            </InputLabel>
            <Select
              defaultValue={initialValues?.accessFormat?.id ?? 0}
              id='accessFormat'
              variant='outlined'
              error={getError("accessFormat")}
              {...field}
              disabled={!canEdit}
            >
              <MenuItem key={"empty"} value={0}>
                <Text>Not selected</Text>
              </MenuItem>
              {accessFormats?.map((format) => (
                <MenuItem key={format.id} value={format.id ?? 0}>
                  {format.name}
                </MenuItem>
              ))}
            </Select>
            <HelperText error={getError("accessFormat")} />
          </FormControl>
        )}
      />

      <Controller
        name='inVAK'
        defaultValue={initialValues?.inVAK || false}
        render={({ field }) => (
          <AppSwitch
            checked={!!field.value}
            label='InVAK'
            disabled={!canEdit}
            onChange={(e: any) => {
              field.onChange(e);
              setShowVAKFields(e);
            }}
          />
        )}
      />

      <Controller
        name='link'
        defaultValue={initialValues?.link}
        render={({ field }) => (
          <FormControl fullWidth>
            <LinkInput
              disabled={!canEdit}
              label={<Text>Link</Text>}
              {...register("link", getBaseUrlValidation({ required: false }))}
              {...field}
              value={field.value ?? ""}
              fullWidth
              error={!!getError("link")}
            />
            <HelperText error={getError("link")} />
          </FormControl>
        )}
      />
      <Controller
        name='organizer'
        rules={baseRequired}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              // key={value?.name}
              id='organizer'
              freeSolo
              disabled={!canEdit}
              className='w-full'
              defaultValue={
                isCreateMode && organizations.length === 1
                  ? organizations.at(0)
                  : initialValues?.organizer
              }
              options={organizations}
              getOptionLabel={(option: any) => option?.name || ""}
              noOptionsText={<Text>No options</Text>}
              size='small'
              value={value}
              onChange={(_: any, value: any) => {
                setValue("organizer", value);
              }}
              renderOption={(props: any, option: any) => (
                <Fragment key={option.id}>
                  <MenuItem {...props} id={option.id}>
                    {option.name}
                  </MenuItem>
                </Fragment>
              )}
              renderInput={(params: any) => (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!canEdit}
                    multiline
                    label={<Text>Организация</Text>}
                    error={getError("organizer")}
                  />
                </Box>
              )}
            />
            <HelperText error={getError("organizer")} />
          </FormControl>
        )}
      />

      {showVAKFields && (
        <Controller
          control={control}
          name='specialties'
          defaultValue={Array.isArray(initialValues?.specialties) ? initialValues?.specialties : []}
          render={({ field: { onChange } }) => (
            <MuiAutocomplete
              defaultValue={
                Array.isArray(initialValues?.specialties) ? initialValues?.specialties : []
              }
              multiple
              disabled={!canEdit}
              disableCloseOnSelect
              options={(Array.isArray(specialties) ? specialties : []) as SpecialtiesModel[]}
              getOptionLabel={(option: SpecialtiesModel) => option.name}
              onChange={(_: any, values: SpecialtiesModel[]) => {
                onChange(values);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  multiline
                  disabled={!canEdit}
                  label={<Text>Specialties</Text>}
                  helperText={errors.specialties?.message?.toString()}
                  error={!!errors.specialties}
                />
              )}
            />
          )}
        />
      )}

      <div className='flex flex-wrap gap-2'>
        <Controller
          name='inRINC'
          defaultValue={initialValues?.inRINC || false}
          render={({ field }) => (
            <AppSwitch
              checked={!!field.value}
              disabled={!canEdit}
              label='InRINC'
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          name='inScopus'
          defaultValue={initialValues?.inScopus || false}
          render={({ field }) => (
            <AppSwitch
              checked={!!field.value}
              disabled={!canEdit}
              label='InScopus'
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          name='inWebOfScience'
          defaultValue={initialValues?.inWebOfScience || false}
          render={({ field }) => (
            <AppSwitch
              checked={!!field.value}
              disabled={!canEdit}
              label='InWebOfScience'
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          name='inOtherDatabases'
          defaultValue={initialValues?.inOtherDatabases || false}
          render={({ field }) => (
            <AppSwitch
              checked={!!field.value}
              disabled={!canEdit}
              label='InOtherDatabases'
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </ImageForm>
  );
};
