import React, { useState } from "react";
import { useModal } from "~/shared/hooks/useModal";
import { Autocomplete, Button, IconButton, MenuItem, TextField } from "@mui/material";
import { DeepPartial } from "react-hook-form";
import clsx from "clsx";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  Maybe,
  PracticesGrantWinnersModel,
  usePracticesGrantWinnersDeleteMutation
} from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { Text } from "~/shared/components/Text";
import { CreateModal } from "../CreateModal";
import { ConfirmDialog } from "../../../ConfirmDialog";
import { useRole } from "~/entities/Roles";

type Props = {
  items: Maybe<DeepPartial<PracticesGrantWinnersModel>[]>;
  addItem: (input: any) => Promise<void>;
  refetch: () => void;
};

export const FormActions: React.FC<Props> = ({ items, addItem, refetch }) => {
  const [selectedRow, setSelectedRow] =
    useState<Maybe<DeepPartial<PracticesGrantWinnersModel>>>(null);

  const { open, handleOpen, handleClose } = useModal();

  const { open: confirmOpen, handleOpen: onConfirmOpen, handleClose: onConfirmClose } = useModal();

  const client = useGraphqlClient();

  const { mutateAsync: remove, isLoading } = usePracticesGrantWinnersDeleteMutation(client);

  const handleRemove = () => {
    remove({ practicesGrantWinnersDeleteId: selectedRow?.id as number }).then(() => {
      onConfirmClose();
      refetch();
    });
  };

  const handleCloseConfirm = () => {
    setSelectedRow(null);
    onConfirmClose();
  };

  const { hasPermissions } = useRole();

  const canEdit = hasPermissions(["moderator_content_mp_vd"]);

  return (
    <>
      <div className='flex md:gap-6 gap-4 max-md:flex-col'>
        <Autocomplete
          disablePortal
          freeSolo
          className='w-[340px]'
          options={items ?? []}
          isOptionEqualToValue={(
            option: Maybe<DeepPartial<PracticesGrantWinnersModel>>,
            value: Maybe<DeepPartial<PracticesGrantWinnersModel>>
          ) => option?.name === value?.name}
          getOptionLabel={(option) => (option as { name: string }).name || ""}
          noOptionsText={<Text>No options</Text>}
          size='small'
          onChange={(_, value) => {
            if (value) addItem(value);
          }}
          renderOption={(props, option: Maybe<DeepPartial<PracticesGrantWinnersModel>>) => (
            <MenuItem {...props} className={clsx(props?.className, "flex gap-2 !justify-between")}>
              {option?.name}{" "}
              {canEdit && (
                <IconButton
                  color='error'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onConfirmOpen();
                    setSelectedRow(option);
                  }}
                  className='shrink-0'
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true
              }}
              multiline
              label={<Text>Выбрать</Text>}
            />
          )}
        />
        {canEdit && <Button onClick={() => handleOpen()}>Добавить</Button>}
      </div>
      <CreateModal isOpen={!!open} onClose={handleClose} onSubmit={addItem} title={"Добавить"} />
      <ConfirmDialog
        isOpen={!!confirmOpen}
        handleClose={handleCloseConfirm}
        isLoading={isLoading}
        handleConfirm={handleRemove}
      />
    </>
  );
};
