import React from "react";
import { PropsWithChildren, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Loader } from "~/shared/components/Loader";

export const Router: React.FC<PropsWithChildren> = ({ children }) => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Suspense fallback={<Loader />}>{children}</Suspense>
  </BrowserRouter>
);
