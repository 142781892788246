import { Box, ClickAwayListener } from "@mui/material";
import React, { PropsWithChildren, ReactNode, useEffect } from "react";
import { Button } from "../Button";
import clsx from "clsx";
import { SearchIcon } from "~shared/components/Icons";

type Props = {
  opened: boolean;
  handleClose: () => void;
} & (
  | {
      handleSubmit?: () => void;
      footer?: never;
      handleDrop?: () => void;
    }
  | {
      footer?: ReactNode;
      handleDrop?: never;
      handleSubmit?: never;
    }
);

export const ModalFilters: React.FC<PropsWithChildren<Props>> = ({
  opened,
  footer,
  handleClose,
  handleDrop,
  handleSubmit,
  children
}) => {
  useEffect(() => {
    const listenerHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", listenerHandler);
    return () => {
      document.removeEventListener("keydown", listenerHandler);
    };
  }, [handleClose]);

  const closeAwayHandler = () => {
    if (opened) {
      handleClose();
    }
  };

  const handleDropClick = () => {
    handleDrop?.();
    handleClose();
  };

  const handleApplyClick = () => {
    handleSubmit?.();
    handleClose();
  };

  return (
    <>
      {opened && (
        <ClickAwayListener onClickAway={closeAwayHandler}>
          <Box
            className={clsx(
              "absolute w-full bg-mainBg hidden z-50",
              "rounded-lg border border-primary-30 p-[12px] pt-[30px] mt-1 z-[1001]",
              {
                "!block": opened
              }
            )}
            sx={{ boxShadow: "0px 2px 4px rgba(83, 83, 83, 0.1)" }}
          >
            {children}

            {footer}

            {!footer && (
              <Box className='flex items-center justify-end gap-2 mt-9'>
                {handleDrop && (
                  <Button variant='outlined' onClick={handleDropClick}>
                    Drop
                  </Button>
                )}
                {handleSubmit && (
                  <Button
                    variant='contained'
                    onClick={handleApplyClick}
                    startIcon={<SearchIcon color='mainBg' />}
                  >
                    Find
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};
