import { Box, CircularProgress } from "@mui/material";
import React from "react";
import clsx from "clsx";

type Props = {
  className?: string;
  progressClassName?: string;
};

export const Loader: React.FC<Props> = ({ className, progressClassName }) => {
  return (
    <Box className={clsx("flex h-[20vh] w-full justify-center items-center", className)}>
      <CircularProgress className={progressClassName} />
    </Box>
  );
};
