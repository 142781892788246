import { CSSProperties } from "react";

export const getTableCellDefaultStyles = (width: number): CSSProperties => ({
  width,
  minWidth: width,
  maxWidth: width,
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-word"
});
