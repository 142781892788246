import React, { ReactElement, useMemo } from "react";
import { createCallableCtx } from "~/shared/lib/context";
import PeopleIcon from "@mui/icons-material/People";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import DescriptionIcon from "@mui/icons-material/Description";
import InboxIcon from "@mui/icons-material/Inbox";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

import {
  HomePageRoute,
  NewsPageRoute,
  UsersPageRoute,
  OrganizationsPageRoute,
  EventsPageRoute,
  InternshipsApplicationsPageRoute,
  InternshipsPageRoute,
  EducationsPageRoute,
  ProjectPageRoute,
  PostgraduatesPageRoute,
  CoApplicationPageRoute,
  DissertationCouncilsPageRoute,
  LabsPageRoute,
  NomusPageRoute,
  JournalsPageRoute,
  PracticesPageRoute,
  DigitalResourcesPageRoute,
  ProfessionalOrientationsPageRoute,
  OutsideResourcesPageRoute,
  ExpertisesApplicationsPageRoute,
  DictionariesPageRoute,
  FaqPageRoute,
  PagesPageRoute,
  PreUnivercityEventsPageRoute,
  YoungPolicyConferencePageRoute,
  YoungPolicyContestPageRoute,
  YoungPolicyEducationsPageRoute,
  PreUnivercityEducationsPageRoute,
  YoungPolicyProjectPageRoute,
  PreUnivercityProjectsPageRoute,
  VacancyPageRoute,
  GrantsPageRoute,
  PaymentsPageRoute,
  NotificationPageRoute,
  TipsPageRoute
} from "~shared/routes";
import { AccountBalanceWalletOutlined, QuestionMarkOutlined } from "@mui/icons-material";
import { useRole } from "~/entities/Roles";
import { NewsIcon } from "~/shared/components/Icons/NewsIcon";
import { HomeIcon } from "~/shared/components/Icons/HomeIcon";
import {
  DigitalResourcesIcon,
  EducationIcon,
  EventsIcon,
  InternshipsIcon,
  MpvdIcon,
  PreUnivercityIcon,
  ScienceIcon
} from "~/shared/components/Icons";

export type TPath = {
  id: number;
  label: string;
  path?: string;
  icon: ReactElement;
  permissions?: string[];
  children?: TPath[];
};

const paths: TPath[] = [
  {
    id: 1,
    label: "Home",
    path: HomePageRoute,
    icon: <HomeIcon />
  },
  {
    id: 2,
    label: "News",
    path: NewsPageRoute,
    icon: <NewsIcon />,
    permissions: ["all", "moderator_content_news"]
  },
  {
    id: 2,
    label: "Science",
    icon: <ScienceIcon />,
    permissions: ["all", "moderator_content_science", "management_content_science"],
    children: [
      {
        id: 1,
        label: "Projects",
        path: ProjectPageRoute,
        icon: <ScienceIcon />,
        permissions: ["all", "moderator_content_science", "management_content_science"]
      },
      {
        id: 2,
        label: "Postgraduate",
        path: PostgraduatesPageRoute,
        icon: <ScienceIcon />,
        permissions: ["all", "management_content_science", "moderator_content_science"]
      },
      {
        id: 3,
        label: "ApplicationForJob",
        path: CoApplicationPageRoute,
        icon: <ScienceIcon />,
        permissions: ["all", "management_content_science", "moderator_content_science"]
      },
      {
        id: 4,
        label: "DissertationAdvice",
        path: DissertationCouncilsPageRoute,
        icon: <ScienceIcon />,
        permissions: ["all", "management_content_science", "moderator_content_science"]
      },
      {
        id: 5,
        label: "Laboratories",
        path: LabsPageRoute,
        icon: <ScienceIcon />,
        permissions: ["all", "management_content_science", "moderator_content_science"]
      },
      {
        id: 6,
        label: "NOMUS",
        path: NomusPageRoute,
        icon: <ScienceIcon />,
        permissions: ["all", "management_content_science", "moderator_content_science"]
      },
      {
        id: 7,
        label: "ScientificJournals",
        path: JournalsPageRoute,
        icon: <ScienceIcon />,
        permissions: ["all", "management_content_science", "moderator_content_science"]
      }
    ]
  },
  {
    id: 9,
    label: "YoungPolicy",
    icon: <MpvdIcon />,
    children: [
      {
        id: 1,
        label: "Practices",
        path: PracticesPageRoute,
        icon: <MpvdIcon />,
        permissions: ["all", "moderator_content_mp_vd", "management_content_mp_vd", "curator_mp_vd"]
      },
      {
        id: 2,
        label: "Educations",
        path: YoungPolicyEducationsPageRoute,
        icon: <MpvdIcon />,
        permissions: [
          "all",
          "management_content_education",
          "moderator_content_mp_vd",
          "management_content_mp_vd",
          "curator_mp_vd",
          "edit_published_courses"
        ]
      },
      {
        id: 3,
        label: "Nir",
        path: YoungPolicyProjectPageRoute,
        icon: <MpvdIcon />,
        permissions: [
          "all",
          "management_content_mp_vd",
          "moderator_content_mp_vd",
          "curator_mp_vd",
          "moderator_content_science"
        ]
      },
      {
        id: 40,
        label: "Конференции по МП и ВД",
        path: YoungPolicyConferencePageRoute,
        icon: <MpvdIcon />,
        permissions: [
          "all",
          "management_content_mp_vd",
          "moderator_content_mp_vd",
          "curator_mp_vd",
          "moderator_content_event",
          "moderator_event",
          "moderator_content_event",
          "management_content_event",
          "curator_event"
        ]
      },
      {
        id: 50,
        label: "Конкурсы по МП и ВД",
        path: YoungPolicyContestPageRoute,
        icon: <MpvdIcon />,
        permissions: [
          "all",
          "management_content_mp_vd",
          "moderator_content_mp_vd",
          "curator_mp_vd",
          "moderator_content_event",
          "moderator_event",
          "moderator_content_event",
          "management_content_event",
          "curator_event"
        ]
      },
      {
        id: 60,
        label: "Гранты в области МП и ВД",
        path: GrantsPageRoute,
        icon: <MpvdIcon />,
        permissions: ["all", "management_content_mp_vd", "moderator_content_mp_vd", "curator_mp_vd"]
      }
    ]
  },
  {
    id: 3,
    label: "Users",
    path: UsersPageRoute,
    icon: <PeopleIcon htmlColor='#5229DD' />,
    permissions: ["all", "lk_organizer"]
  },
  {
    id: 4,
    label: "Organizations",
    path: OrganizationsPageRoute,
    icon: <CorporateFareIcon htmlColor='#5229DD' />,
    permissions: ["all"]
  },
  {
    id: 5,
    label: "Events",
    path: EventsPageRoute,
    icon: <EventsIcon />,
    permissions: [
      "all",
      "moderator_event",
      "moderator_content_event",
      "management_content_event",
      "curator_event"
    ]
  },
  {
    id: 6,
    label: "Internships",
    path: InternshipsPageRoute,
    icon: <InternshipsIcon />,
    permissions: [
      "all",
      "moderator_content_internship",
      "management_content_internship",
      "moderator_internship",
      "moderator_reviews"
    ]
  },
  {
    id: 7,
    label: "Internships Applications",
    path: InternshipsApplicationsPageRoute,
    icon: <AutoStoriesIcon htmlColor='#5229DD' />,
    permissions: ["all", "management_content_internship", "moderator_content_internship"]
  },
  {
    id: 8,
    label: "Educations",
    path: EducationsPageRoute,
    icon: <EducationIcon />,
    permissions: [
      "all",
      "management_content_education",
      "edit_published_courses",
      "moderator_reviews"
    ]
  },
  {
    id: 9,
    label: "Экспертиза",
    path: ExpertisesApplicationsPageRoute,
    icon: <LocalLibraryIcon htmlColor='#5229DD' />,
    permissions: ["all", "expertise_technics_courses", "expertise_content_courses"]
  },
  {
    id: 10,
    label: "Digital Resources",
    path: DigitalResourcesPageRoute,
    icon: <DigitalResourcesIcon />,
    permissions: ["all", "management_content_digital", "moderator_content_digital"]
  },
  {
    id: 11,
    label: "Довузовское образование",
    icon: <PreUnivercityIcon />,
    children: [
      {
        id: 1,
        label: "Professional Orientations",
        path: ProfessionalOrientationsPageRoute,
        icon: <PreUnivercityIcon />,
        permissions: [
          "all",
          "moderator_content_school",
          "management_content_school",
          "curator_school"
        ]
      },
      {
        id: 2,
        label: "Educations",
        path: PreUnivercityEducationsPageRoute,
        icon: <PreUnivercityIcon />,
        permissions: [
          "all",
          "management_content_education",
          "moderator_content_school",
          "management_content_school",
          "curator_school"
        ]
      },
      {
        id: 3,
        label: "Events",
        path: PreUnivercityEventsPageRoute,
        icon: <PreUnivercityIcon />,
        permissions: [
          "all",
          "moderator_content_school",
          "management_content_school",
          "curator_school",
          "moderator_content_event",
          "moderator_event",
          "moderator_content_event",
          "management_content_event",
          "curator_event"
        ]
      },
      {
        id: 4,
        label: "Materials",
        path: OutsideResourcesPageRoute,
        icon: <PreUnivercityIcon />,
        permissions: [
          "all",
          "management_content_school",
          "moderator_content_school",
          "curator_school"
        ]
      },
      {
        id: 5,
        label: "Science",
        path: PreUnivercityProjectsPageRoute,
        icon: <PreUnivercityIcon />,
        permissions: [
          "all",
          "moderator_content_science",
          "moderator_content_school",
          "management_content_school",
          "curator_school"
        ]
      }
    ]
  },
  {
    id: 12,
    label: "Dictionaries",
    path: DictionariesPageRoute,
    icon: <InboxIcon htmlColor='#5229DD' />,
    permissions: [
      "all",
      "moderator_content_science",
      "moderator_content_event",
      "moderator_content_internship",
      "moderator_content_mp_vd",
      "moderator_content_school",
      "moderator_content_digital"
    ]
  },
  {
    id: 12,
    label: "Tips",
    path: TipsPageRoute,
    icon: <TipsAndUpdatesIcon htmlColor='#5229DD' />,
    permissions: [
      "all"
      // "moderator_content_event",
      // "moderator_content_internship",
      // "moderator_content_mp_vd",
      // "moderator_content_school"
    ]
  },
  {
    id: 12,
    label: "Pages",
    path: PagesPageRoute,
    icon: <DescriptionIcon htmlColor='#5229DD' />,
    permissions: ["all"]
  },
  {
    id: 13,
    label: "Faq",
    path: FaqPageRoute,
    icon: <QuestionMarkOutlined htmlColor='#5229DD' />,
    permissions: ["all"]
  },
  {
    id: 14,
    label: "Notification",
    path: NotificationPageRoute,
    icon: <NotificationsActiveIcon htmlColor='#5229DD' />,
    permissions: ["all"]
  },
  {
    id: 15,
    label: "Vacancy",
    path: VacancyPageRoute,
    icon: <SupervisedUserCircleIcon htmlColor='#5229DD' />,
    permissions: ["all", "moderator_content_science", "management_content_science"]
  },
  {
    id: 16,
    label: "Платежи",
    path: PaymentsPageRoute,
    icon: <AccountBalanceWalletOutlined htmlColor='#5229DD' />,
    permissions: ["all", "lk_organizer"]
  }
];

const useHook = () => {
  const { hasPermissions, permissions, rolesQuery } = useRole();

  const permittedPaths = useMemo(() => {
    return [
      ...paths.map((path) => ({
        ...path,
        ...(Boolean(path.children) && { children: [...(path.children || [])] })
      }))
    ].reduce((acc, path) => {
      if (path.children?.some((child) => child.permissions)) {
        path.children = path.children.filter((child) => {
          return !child.permissions || hasPermissions(child.permissions);
        });
      }

      if (path.permissions && hasPermissions(path.permissions)) {
        acc.push(path);
      } else if (!path.permissions && (path?.path || path.children?.length)) {
        acc.push(path);
      }

      return acc;
    }, [] as TPath[]);
  }, [paths, hasPermissions, permissions, rolesQuery.data?.me?.isOrg]);

  return permittedPaths;
};

export const [usePaths, PathsProvider] = createCallableCtx(useHook, { name: "PathsProvider" });
