import React from "react";
import { PageTableTitle } from "~/shared/components/PageTableTitle";
import { PageWrapper } from "~/shared/components/PageWrapper";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { CoApplicationTable } from "~/modules/CoApplicationTable";

export const CoApplication: React.FC = () => {
  const { count, isLoading } = usePageLoadingStore((state) => ({
    count: state.count,
    isLoading: state.isLoading
  }));

  return (
    <PageWrapper>
      <PageTableTitle title='ApplicationForJob' isLoading={isLoading} count={count} />
      <CoApplicationTable />
    </PageWrapper>
  );
};
