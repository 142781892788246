import { Path, UseFormSetError } from "react-hook-form";
import { getFieldsErrors } from "../lib/graphqlErrors";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

type Fields = Record<string, unknown>;

export const useValidationError = <T extends Fields = Fields>(setError: UseFormSetError<T>) => {
  const { t } = useTranslation();

  const onError = useCallback(
    (error: unknown) => {
      const fieldsErrors = getFieldsErrors(error);

      fieldsErrors.fields.forEach((err) => {
        if (!err?.field) {
          return;
        }

        const message = t(err.message, { defaultValue: "Проверка не пройдена" });

        setError(err.field as Path<T>, { type: "server", message }, { shouldFocus: true });
      });

      return fieldsErrors;
    },
    [t]
  );

  return onError;
};
