import { hasPath } from "rambda";
import { empty } from "ramda";
import { Params } from "~/shared/hooks/useRequestState";
import {
  getCategoryChips,
  getDatePublishedChips,
  getIdChips,
  getIsPublishedChips,
  getOnIndexChips,
  getTypeChips
} from "~/shared/lib/getChips";

const Chips: Record<string, (value: any) => string> = {
  "id.like": (value) => getIdChips(value),
  published: () => getIsPublishedChips(),
  createdAt: (value) => getDatePublishedChips(value),
  publishedAt: (value) => getDatePublishedChips(value),
  categoryId: () => getCategoryChips(),
  typeId: () => getTypeChips(),
  onIndex: () => getOnIndexChips(),
  audienceTargetId: (value) => value?.name ?? "",
  eventTypeId: (value) => value?.name ?? "",
  statusId: (value) => value?.label ?? ""
};

export const useChips = (params: Params | null) => {
  if (!params) return;

  const prepareFilters: Record<string, string> = Object.entries(params).reduce(
    (res: Record<string, string>, [key, value]) => {
      if (hasPath([`${key}`], Chips)) {
        if (value === "" || !value || Object.values(value).every(empty)) return res;
        res[key] = Chips[key](value) as never;
      } else res[key] = JSON.stringify(params[key]);
      return res;
    },
    {}
  );

  return prepareFilters;
};
