import React, { useId } from "react";
import { DeepPartial } from "react-hook-form";
import { useModal } from "~/shared/hooks/useModal";
import { ContactPersonsInput, ContactPersonsModel } from "~/generated/graphql";
import { AppLine } from "~/modules/ExpertisesApplicationsTable/components/Filters/components/FiltersContent";
import { ContactPersonsData } from "../ContactPersonsData";
import { CreateContactsModal } from "../CreateContactsModal";

type Props = {
  orgId: number;
  contactPerson: DeepPartial<ContactPersonsModel>;
  addItem: (input: ContactPersonsInput) => Promise<void>;
  onRemove: () => void;
  isLoading: boolean;
  isLeader: boolean;
  isLinkExist: boolean;
};

export const ContactPersonsTable: React.FC<Props> = ({
  contactPerson,
  addItem,
  isLoading,
  orgId,
  onRemove,
  isLeader,
  isLinkExist
}) => {
  const { open, handleOpen, handleClose } = useModal();

  const keyData = useId();

  const handleUpdate = async (formData: ContactPersonsInput) => {
    await addItem({
      id: contactPerson?.id,
      ...formData,
      image: { id: formData.image?.id },
      org: { id: orgId }
    }).then(() => {
      handleClose();
    });
  };

  const openModalHandler = () => {
    handleOpen();
  };

  const contactPersons = [contactPerson].filter(Boolean);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-1'>
        <div className='flex gap-3 text-base font-medium items-center'>
          <div className='w-[300px] text-center'>ФИО</div>
          <div className='w-[324px] max-md:hidden text-center'>Должность</div>
          <div className='w-[324px] max-md:hidden text-center'>email</div>
          <div className='w-[324px] max-md:hidden text-center'>Действия</div>
        </div>
        <AppLine />
      </div>

      <ContactPersonsData
        key={keyData}
        contactPersons={contactPersons}
        isLoading={isLoading}
        openModal={openModalHandler}
        removeHandler={onRemove}
      />
      <CreateContactsModal
        isOpen={!!open}
        onClose={handleClose}
        initialValue={contactPerson}
        onSubmit={handleUpdate}
        title={isLeader ? "Редактировать руководителя" : "Редактировать контактное лицо"}
        isLeader={isLeader}
        isLinkExist={isLinkExist}
      />
    </div>
  );
};
