import React, { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import {
  useProjectsByIdQuery,
  useProjectsDeclineMutation,
  useProjectsPublishMutation,
  useProjectsSendToModerateMutation,
  useProjectsUpsertMutation
} from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { TabsForm } from "~/entities/TabsForm";
import { initFormValues } from "~/shared/lib/initFormValues";
import { useNavigationBack } from "~/shared/hooks/useBackClick";
import { GeneralForm } from "./components/GeneralForm";
import { prepareFormData } from "./lib/prepareFormData";
import { either, has, isEmpty } from "rambda";
import { useAlertsStore } from "~/shared/stores/alerts";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { Loader } from "~/shared/components/Loader";
import { ContactPersonForm } from "~/entities/ContactPersonFrom";
import { FilesForm } from "~/entities/FilesForm";
import { useRole } from "~/entities/Roles";
import { useValidationError } from "~/shared/hooks/useValidationError";
import { isFullData } from "~/shared/lib/isFullData";
import { useInputStatus } from "~/shared/hooks/useInputStatus";
import { useCanEditByStatus } from "~/entities/Roles/useCanEditOnModeration";
import { getBaseRefInitializer } from "~/shared/hooks/useChangesRef";

type Props = {
  id?: number;
  formName?: string;
  audienceTargetsCategory: string;
  sectionModeratorRole: string;
  sectionManagerRole: string;
  changesGetterRef?: (callback: () => Promise<boolean>) => void;
};

export const ProjectDetailsForm: React.FC<Props> = ({
  id,
  formName,
  audienceTargetsCategory,
  sectionModeratorRole,
  sectionManagerRole,
  changesGetterRef
}) => {
  const [step, setStep] = useState(0);

  const isCreateMode = !Number.isInteger(id);

  const client = useGraphqlClient();

  const { mutateAsync: sendToModerate } = useProjectsSendToModerateMutation(client);
  const { mutateAsync: decline } = useProjectsDeclineMutation(client);
  const { mutateAsync: publish } = useProjectsPublishMutation(client);

  const { setIsSaveLoading } = usePageLoadingStore((state) => ({
    setIsSaveLoading: state.setIsSaveLoading
  }));

  const addAlert = useAlertsStore((state) => state.addAlert);

  const {
    data,
    refetch,
    isLoading: isItemLoading
  } = useProjectsByIdQuery(
    client,
    { id: Number(id) },
    {
      enabled: !isCreateMode,
      refetchOnMount: "always",
      cacheTime: 0
    }
  );

  const goBack = useNavigationBack();

  const formMethods = useForm({ mode: "all" });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    watch
  } = formMethods;

  const onError = useValidationError(setError);

  const { mutateAsync: updateProject, isLoading: isUpsertLoading } = useProjectsUpsertMutation(
    client,
    {
      onSuccess: goBack,
      onError
    }
  );

  const values = data?.projectsById;

  const isLoading = (isItemLoading && !isCreateMode) || isUpsertLoading;

  const { hasPermissions } = useRole();

  const isGeneralFormError = either(has("name"), has("content"))(errors);

  const { prepareInputStatus, setInitialValue } = useInputStatus({
    isModerator: hasPermissions([sectionModeratorRole]),
    currentStatusId: values?.status?.id
  });

  changesGetterRef?.(() =>
    getBaseRefInitializer({
      isCreate: isCreateMode,
      value: prepareFormData(values as never),
      newValue: prepareFormData(getValues())
    })
  );

  const onSubmit = handleSubmit((newValues) => {
    prepareFormData(newValues, values, { isCreateMode }).then((input) => {
      updateProject({ input: prepareInputStatus(input) });
    });
  });

  const canEdit =
    useCanEditByStatus({
      isModerator: hasPermissions([sectionModeratorRole]),
      currentStatusId: values?.status?.id
    }) && !watch("published");

  const getIsFullDataFunction = (value: unknown): boolean => {
    return isFullData(value, [
      "rejectReason",
      "commentForModerator",
      "isCollaboration",
      "isVrMp",
      "published",
      "competencies",
      "publishedAt"
    ]);
  };

  const forms = useMemo(
    () => [
      {
        tabTitle: "General data",
        hasErrors: isGeneralFormError,
        component: (
          <GeneralForm
            initialValues={values}
            audienceTargetsCategory={audienceTargetsCategory}
            canEdit={canEdit}
            sectionModeratorRole={sectionModeratorRole}
          />
        )
      },
      {
        tabTitle: "Контакты",
        component: (
          <ContactPersonForm
            initialValues={{ contactPerson: values?.contactPerson, orgId: values?.organizer?.id }}
            disabled={!canEdit}
          />
        )
      },
      {
        tabTitle: "Leader person",
        component: (
          <ContactPersonForm
            initialValues={{
              contactPerson: values?.leaderPerson,
              orgId: values?.organizer?.id,
              isLeaderPresentation: true
            }}
            disabled={!canEdit}
            name='leaderPerson'
          />
        )
      },
      {
        tabTitle: "Файлы",
        component: <FilesForm disabled={!canEdit} />
      }
    ],
    [isGeneralFormError, values, audienceTargetsCategory, canEdit, sectionModeratorRole]
  );

  useEffect(() => {
    setValue("publishedAt", isCreateMode ? dayjs().toISOString() : values?.publishedAt ?? null);
    setValue("isVrMp", audienceTargetsCategory === "МП и ВД");
    if (isCreateMode) {
      return;
    }

    initFormValues(
      [
        "name",
        "description",
        "published",
        "annotation",
        "leaderDescription",
        "isCollaboration",
        "startDate",
        "endDate",
        "link",
        "annotation",
        "presentation",
        "annotationImage",
        "video",
        "city",
        "audienceTarget",
        "organizer",
        "contactPerson",
        "leaderPerson",
        "commentForModerator"
      ],
      setValue,
      values
    );

    setValue("image", values?.image ?? null);
    setValue("imageDetail", (values as any)?.imageDetail ?? null);
    setValue("isVrMp", audienceTargetsCategory === "МП и ВД" || values?.isVrMp);
    setValue("documentsGallery.files", values?.documentsGallery?.files ?? []);
    setValue("competencies", values?.competencies ?? []);

    prepareFormData(getValues(), values, { isCreateMode }).then(setInitialValue);
  }, [values, isCreateMode, setValue, audienceTargetsCategory, getValues]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      addAlert("error", "Fill in all required fields");
    }
  }, [errors, step, addAlert]);

  useEffect(() => {
    setIsSaveLoading(isLoading);
  }, [isLoading, setIsSaveLoading]);
  const getIsFullData = getIsFullDataFunction(values);

  if (!values && !isCreateMode) {
    return <Loader />;
  }

  return (
    <FormProvider {...formMethods}>
      <TabsForm
        handleSubmit={onSubmit}
        handleStepChange={setStep}
        activeStep={step}
        formName={formName}
        forms={forms}
        initialValues={values}
        sendToModerate={sendToModerate}
        decline={decline}
        refetch={refetch}
        publish={publish}
        isFullData={getIsFullData}
        sectionModeratorRole={sectionModeratorRole}
        canNotSendToModerate={!(hasPermissions([sectionManagerRole]) && getIsFullData)}
      />
    </FormProvider>
  );
};
