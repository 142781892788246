import React, { useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { TableHeadCell } from "~/shared/components/TableHeadLabel";
import { Link } from "~/shared/components/Link";
import { formatDateForTable } from "~/shared/lib/formatDate";
import { PracticesPageEdit } from "~/shared/routes";
import { useSortProps } from "~/shared/hooks/useSortProps";
import { ActiveOrder } from "~/shared/types/ActiveOrder";
import { formatDescriptionForTable } from "~/shared/lib/formatDescriptionForTable";
import { useResortColumns } from "~/shared/hooks/useResortColumns";
import { getTableCellDefaultStyles } from "~/shared/lib/getTableCellDefaultStyles";
import { getImageSrc } from "~/shared/lib/getImageSrc";
import { Column, Model } from "../types";
import { StatusColorWrapper } from "~/shared/components/StatusColorWrapper";
import { usePracticesUpsertMutation } from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { useAlertsStore } from "~/shared/stores/alerts";
import { NumericInput } from "~/shared/components/NumericInput";
import { useRole } from "~/entities/Roles";
import { omit } from "rambda";

const SortInput = ({
  initialValue,
  refetch,
  id
}: {
  initialValue: number;
  refetch: () => void;
  id: number;
}) => {
  const [index, setIndex] = useState(initialValue);

  const timeoutRef = useRef<any>();

  const client = useGraphqlClient();

  const { mutateAsync: updateIndex } = usePracticesUpsertMutation(client, {
    onSuccess: () => {
      useAlertsStore.getState().addAlert("success", "Успешно обновлено");
      refetch();
    },
    onError: () => {
      useAlertsStore.getState().addAlert("error", "Ошибка обновления");
    }
  });

  const handleChange = (e: any) => {
    setIndex(Number(e.target.value));
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (!id) {
        useAlertsStore.getState().addAlert("error", "Ошибка обновления");
        return;
      }
      updateIndex({ input: { id, sort: Number(e.target.value) } });
    }, 300);
  };

  return <NumericInput value={index} min={1} onChange={handleChange} />;
};

const orderKey = "__practices-columns-order";

export const useColumns = (
  refetch: () => void,
  activeOrder?: ActiveOrder,
  handleOrderClick?: (_activeOrder: ActiveOrder) => void
) => {
  const { getClickHandler, getActiveProps } = useSortProps(handleOrderClick, activeOrder);

  const { hasPermissions } = useRole();

  const isModerator = hasPermissions(["moderator_content_mp_vd"]);

  const defaultColumns: Record<Column["id"], Column> = useMemo(
    () =>
      ({
        id: {
          id: "id",
          label: (
            <TableHeadCell
              title='ID'
              cellId='id'
              onSortClick={getClickHandler("id")}
              sortProps={getActiveProps("id")}
            />
          ),
          style: { ...getTableCellDefaultStyles(50), paddingRight: 24 },
          className: "text-grayLight cursor-default",
          defaultSelected: true,
          selectTitle: "ID"
        },
        sort: {
          id: "sort",
          label: (
            <TableHeadCell
              title='Положение'
              cellId='sort'
              onSortClick={getClickHandler("sort")}
              sortProps={getActiveProps("sort")}
            />
          ),
          style: { ...getTableCellDefaultStyles(200), paddingRight: 24 },
          className: "text-grayLight cursor-default",
          defaultSelected: true,
          selectTitle: "Положение",
          render: (value: number, row: any) => (
            <SortInput initialValue={value} refetch={refetch} id={row?.id} />
          )
        },
        image: {
          id: "image",
          label: <TableHeadCell title='Image short' cellId='image' />,
          style: { ...getTableCellDefaultStyles(52), paddingLeft: 8, cursor: "default" },
          render: (value: Model["image"], row: Model) => (
            <img
              className={clsx("w-[52px] h-[30px] rounded object-cover", {
                hidden: !value?.path
              })}
              loading='lazy'
              src={getImageSrc(value) ?? ""}
              alt={row.name as string}
            />
          ),
          defaultSelected: true,
          selectTitle: "Image"
        },
        name: {
          id: "name",
          label: (
            <TableHeadCell
              title='Designation name'
              cellId='name'
              onSortClick={getClickHandler("name")}
              sortProps={getActiveProps("name")}
            />
          ),
          style: { ...getTableCellDefaultStyles(300), cursor: "default" },
          render: (value = "", row: Model) => {
            return (
              <Link
                className='transition-all'
                to={`${PracticesPageEdit.replace(":id", String(row.id))}`}
              >
                {formatDescriptionForTable(value) === ""
                  ? "Без названия"
                  : formatDescriptionForTable(value)}
              </Link>
            );
          },
          defaultSelected: true,
          selectTitle: "Designation name"
        },
        description: {
          id: "description",
          label: (
            <TableHeadCell
              title='Description'
              cellId='description'
              onSortClick={getClickHandler("description")}
              sortProps={getActiveProps("description")}
            />
          ),
          style: { ...getTableCellDefaultStyles(300), cursor: "default" },
          render: (value: any, row: Model) => {
            return (
              <Link
                className='transition-all'
                to={`${PracticesPageEdit.replace(":id", String(row.id))}`}
              >
                {formatDescriptionForTable(value)}
              </Link>
            );
          },
          defaultSelected: true,
          selectTitle: "Description"
        },
        status: {
          id: "status",
          label: <TableHeadCell title='Status' cellId='status' />,
          style: { ...getTableCellDefaultStyles(150), cursor: "default" },
          defaultSelected: true,
          selectTitle: "Status",
          render: (value: { name: string; id: number }) => <StatusColorWrapper value={value} />
        },
        region: {
          id: "region",
          label: <TableHeadCell title='Region' cellId='region' />,
          style: { ...getTableCellDefaultStyles(300), cursor: "default" },
          render: (value: any, row: Model) => {
            return (
              <Link
                className='transition-all'
                to={`${PracticesPageEdit.replace(":id", String(row.id))}`}
              >
                {value?.name}
              </Link>
            );
          },
          defaultSelected: true,
          selectTitle: "Region"
        },
        publishedAt: {
          id: "publishedAt",
          label: (
            <TableHeadCell
              title='Published at'
              align='center'
              cellId='published_at'
              onSortClick={getClickHandler("publishedAt")}
              sortProps={getActiveProps("publishedAt")}
            />
          ),
          style: { ...getTableCellDefaultStyles(170), cursor: "default" },
          align: "center",
          render: formatDateForTable,
          defaultSelected: true,
          selectTitle: "Published at"
        }
      } as unknown as Record<Column["id"], Column>),
    [getActiveProps, getClickHandler, isModerator]
  );

  const { columns, onSortColumnsEnd } = useResortColumns(
    isModerator
      ? defaultColumns
      : (omit(["sort"], defaultColumns) as unknown as Record<Column["id"], Column>),
    orderKey
  );

  return { columns, onSortColumnsEnd };
};
