import React from "react";
import { clsx } from "clsx";
import { IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

type Props = {
  open: boolean;
  onClick: () => void;
  className?: string;
};

export const DrawerToggle: React.FC<Props> = ({ open, onClick, className = "" }) => (
  <IconButton className={clsx("p-1 bg-white shadow", className)} onClick={onClick}>
    <NavigateNextIcon
      className={clsx({
        "rotate-180": open
      })}
    />
  </IconButton>
);
