import dayjs, { ConfigType } from "dayjs";
import { compose } from "rambda";
import { dateToISOString } from "./dateToISOString";

export const formatDateForTable = (value: unknown) => {
  if (typeof value !== "string") {
    return "-";
  }

  return dayjs(value).format("DD.MM.YYYY");
};

export const formatDateForFilters = (value: unknown) => {
  if (typeof value !== "string") {
    return "";
  }
  return dayjs(value).format("YYYY-MM-DD");
};

export const formatDateForExport = (value: unknown) => {
  if (typeof value !== "string") {
    return "";
  }
  const splitted = value.split(".");

  const res = dayjs(value);
  return res.isValid()
    ? res.format("YYYY-MM-DD")
    : dayjs(`${splitted[1]}.${splitted[0]}.${splitted[2]}`).format("YYYY-MM-DD");
};

export const formatDayJsForFilters = compose(formatDateForFilters, dateToISOString);

export const formatDateToHumanReadable = (date: ConfigType) => {
  const dateObj = dayjs(date);
  const now = dayjs();

  const isToday = dateObj.isSame(now, "day");
  const isYesterday = dateObj.isSame(now.subtract(1, "day"), "day");
  const isDayBeforeYesterday = dateObj.isSame(now.subtract(2, "day"), "day");

  let formatTemplate = "DD.MM.YYYY в HH:mm";
  if (isToday) {
    formatTemplate = "Сегодня в HH:mm";
  }
  if (isYesterday) {
    formatTemplate = "Вчера в HH:mm";
  }
  if (isDayBeforeYesterday) {
    formatTemplate = "Позавчера в HH:mm";
  }

  return dateObj.format(formatTemplate);
};

export const formatDateCard = (value: unknown, locale = "ru", template = "DD.MM.YYYY") => {
  if (typeof value !== "string") {
    return "-";
  }

  return dayjs(value).locale(locale).format(template);
};
