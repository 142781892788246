import React from "react";
import { clsx } from "clsx";
import { DateTitleValue } from "~shared/components/DateTitleValue";

type Props = {
  createdAt?: string;
  updatedAt?: string;
  className?: string;
};

export const EntityInfo: React.FC<Props> = ({ createdAt, updatedAt, className = "" }) => {
  return (
    <div className={clsx("space-y-2", className)}>
      {createdAt && <DateTitleValue date={createdAt} title='Date create' />}
      {updatedAt && <DateTitleValue date={updatedAt} title='Date of change' />}
    </div>
  );
};
