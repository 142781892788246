import React from "react";
import { PageTableTitle } from "~/shared/components/PageTableTitle";
import { PageWrapper } from "~/shared/components/PageWrapper";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { PostgraduatesTable } from "~/modules/PostgraduatesTable/PostgraduatesTable";

export const Postgraduates: React.FC = () => {
  const { count, isLoading } = usePageLoadingStore((state) => ({
    count: state.count,
    isLoading: state.isLoading
  }));

  return (
    <PageWrapper>
      <PageTableTitle title='Postgraduate' isLoading={isLoading} count={count} />
      <PostgraduatesTable />
    </PageWrapper>
  );
};
