import React from "react";
import { useModal } from "~/shared/hooks/useModal";
import { Button } from "@mui/material";
import { CreateModal } from "../CreateModal";

type Props = {
  addItem: (input: any) => Promise<void>;
};

export const FormActions: React.FC<Props> = ({ addItem }) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <div className='flex md:gap-6 gap-4 max-md:flex-col'>
        <Button variant='outlined' onClick={() => handleOpen()}>
          Добавить
        </Button>
      </div>
      <CreateModal isOpen={!!open} onClose={handleClose} onSubmit={addItem} title={"Добавить"} />
    </>
  );
};
