import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, "value"> & {
  value?: boolean;
  label?: string | null;
  labelClassName?: string;
};

export const AppRadioButton: React.FC<Props> = ({
  value,
  label,
  labelClassName,
  className,
  disabled,
  ...props
}) => {
  return (
    <label
      className={clsx(
        "flex gap-1 md:gap-2 items-center",
        { "hover:cursor-pointer": !disabled },
        className
      )}
    >
      <div className='flex items-center justify-center w-5 h-5 md:w-6 md:h-6 p-[2.5px] md:p-[3px] shrink-0'>
        <input
          {...props}
          type='radio'
          checked={value}
          disabled={disabled}
          className={clsx(
            "appearance-none w-[15px] h-[15px] md:w-[18px] md:h-[18px] border-[1.5px]",
            "border-primary rounded-full bg-white checked:border-[5px] md:checked:border-[6px]",
            { "!border-gray-4": disabled, "hover:cursor-pointer": !disabled }
          )}
        />
      </div>
      {label && (
        <span
          className={clsx(
            "text-sm md:text-base font-normal select-none md:mt-[1px] break-word",
            { "text-gray": disabled },
            labelClassName
          )}
        >
          {label}
        </span>
      )}
    </label>
  );
};
