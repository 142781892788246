import { TableCell, TableCellProps } from "@mui/material";
import React, { CSSProperties, ReactNode } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";

type Props = {
  children: ReactNode;
  id: string | number;
  className?: string;
  style?: CSSProperties;
  align?: TableCellProps["align"];
  isHandleMove?: boolean;
  isActiveOrder?: boolean;
};

export const TableCellSortable: React.FC<Props> = ({
  children,
  id,
  className,
  style: inlineStyles,
  align,
  isHandleMove,
  isActiveOrder
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id
  });

  const style = {
    ...inlineStyles,
    zIndex: 1000,
    transform: CSS.Transform.toString(transform)?.replace(/scaleX\(.*\)/, ""),
    transition
  };

  return (
    <TableCell
      className={clsx(className, {
        "bg-primary bg-opacity-20": isActiveOrder
      })}
      ref={setNodeRef}
      {...attributes}
      style={style}
      align={align}
      tabIndex={-1}
      {...(isHandleMove ? listeners : {})}
    >
      {children}
    </TableCell>
  );
};
