import React from "react";
import { ContentEditor, ContentEditorProps } from "~shared/components/ContentEditor";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import {
  UploadImageMutation,
  UploadMutation,
  UploadVideoMutation,
  useUploadImageMutation,
  useUploadMutation,
  useUploadVideoMutation
} from "~/generated/graphql";
import { useAlertsStore } from "~/shared/stores/alerts";

type Props = ContentEditorProps;

export const ContentEditorInput: React.FC<Props> = (props) => {
  const client = useGraphqlClient();

  const { mutateAsync: uploadImageMutation } = useUploadImageMutation(client, {
    onError: () => {
      useAlertsStore.getState().addAlert("error", "Ошибка при загрузке файла");
    }
  });

  const { mutateAsync: uploadMutation } = useUploadMutation(client);

  const { mutateAsync: uploadVideo, isLoading: isVideoLoading } = useUploadVideoMutation(client);

  const getUploadedUrl = async (file: File, type = "") => {
    let uploadedFile: UploadMutation | UploadImageMutation | UploadVideoMutation;
    let url: string;
    if (type == "image") {
      uploadedFile = await uploadImageMutation({ image: file });
      url = uploadedFile.imageUpload.path;
    } else if (type == "video") {
      uploadedFile = await uploadVideo({ video: file });
      url = uploadedFile.videoUpload.path;
    } else {
      uploadedFile = await uploadMutation({ file });
      url = uploadedFile.upload;
    }
    return `${process.env.REACT_APP_FILES_URL}${url}`;
  };

  return (
    <ContentEditor getUploadedUrl={getUploadedUrl} isUploadLoading={isVideoLoading} {...props} />
  );
};
