import dayjs from "dayjs";
import { useEffect } from "react";

export const useStartEndDate = ({
  watch,
  clearErrors,
  errors,
  setError,
  endName,
  startName
}: {
  watch: any;
  clearErrors: any;
  errors: any;
  setError: any;
  endName?: string;
  startName?: string;
}) => {
  const endFieldName = endName || "endDate";

  const startDate = watch(startName || "startDate");

  const endDate = watch(endFieldName);

  const minDateTime = startDate ? dayjs(startDate) || undefined : undefined;

  const onError = () => setError(endFieldName, { message: "Измените дату" });

  const isDateAfter = dayjs(endDate).toDate().getTime() > dayjs(startDate).toDate().getTime();

  const hasEndError = errors[endFieldName];

  useEffect(() => {
    if (hasEndError && isDateAfter) {
      clearErrors(endFieldName);
    }
  }, [isDateAfter, clearErrors, hasEndError]);

  return { startDate, endDate, minDateTime, onError };
};
