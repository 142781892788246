import { StatusesExpertise } from "~/shared/types/statusesExpertise";

export const useCanEditByStatus = ({
  isModerator,
  currentStatusId
}: {
  isModerator: boolean;
  currentStatusId?: number | null;
}) => {
  const canEdit =
    !currentStatusId ||
    [
      StatusesExpertise.DRAFT_ID,
      StatusesExpertise.DECLINED_ID,
      StatusesExpertise.REMOVED_FROM_PUBLICATION_ID
    ].includes(currentStatusId as StatusesExpertise) ||
    (isModerator &&
      [StatusesExpertise.ON_MODERATION_ID].includes(currentStatusId as StatusesExpertise));

  return canEdit;
};
