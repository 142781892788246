import React, { PropsWithChildren } from "react";
import { AppToolbar } from "~/modules/AppToolbar/AppToolbar";
import { Box } from "@mui/material";
import { AppDrawer } from "~/modules/AppDrawer";

export const BaseLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className='flex gap-6'>
      <AppDrawer className='max-lg:hidden sticky top-0 left-0 h-screen shrink-0' />
      <Box className='grow p-1 min-w-[1px] lg:px-4'>
        <>
          <AppToolbar />
          {children}
        </>
      </Box>
    </Box>
  );
};
