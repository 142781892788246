import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { useRole } from "~/entities/Roles";
import { useOrganisersQuery } from "~/generated/graphql";

export const useMyOrganisersQuery = ({ enabled }: { enabled?: boolean } = {}) => {
  const client = useGraphqlClient();

  const { hasPermissions, rolesQuery } = useRole();

  const isAdmin = hasPermissions(["all"]);

  const queryData = rolesQuery?.data?.me;

  const userOrg = !isAdmin ? queryData?.dictOrgId || queryData?.parent?.dictOrgId : null;

  return useOrganisersQuery(
    client,
    {
      where: { isOrg: { eq: true }, ...(Boolean(userOrg) && { id: { eq: userOrg } }) }
    },
    { enabled, cacheTime: 0, refetchOnMount: "always" }
  );
};
