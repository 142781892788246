import React from "react";

export const EducationIcon = () => {
  return (
    <svg width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.4789 0.261192C10.1804 0.0983861 9.81962 0.0983862 9.52115 0.261192L0.610145 5.12174C-0.0848572 5.50083 -0.0846819 6.49885 0.610453 6.8777L3 8.18V13.5865C3 13.9524 3.19981 14.289 3.52097 14.4643L9.52097 17.7386C9.81954 17.9015 10.1805 17.9015 10.479 17.7386L16.479 14.4643C16.8002 14.289 17 13.9524 17 13.5865V8.18L19 7.09V13C19 13.5523 19.4477 14 20 14C20.5523 14 21 13.5523 21 13V6.59363C21 6.22769 20.8001 5.89097 20.4788 5.71574L10.4789 0.261192ZM16.82 6L10.4789 9.45881C10.1804 9.62161 9.81962 9.62162 9.52115 9.45881L3.18 6L9.52115 2.54119C9.81962 2.37839 10.1804 2.37839 10.4789 2.54119L16.82 6ZM15 12.99L10.4792 15.4583C10.1805 15.6214 9.81945 15.6214 9.52078 15.4583L5 12.99V9.27L9.52078 11.7383C9.81945 11.9014 10.1805 11.9014 10.4792 11.7383L15 9.27V12.99Z'
        fill='#5229DD'
      />
    </svg>
  );
};
