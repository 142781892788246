export const RootRoute = "/";

export const HomePageRoute = "/";

export const LoginPageRoute = "/login";

export const NewsPageRoute = "/news";

export const NewsPageEdit = `${NewsPageRoute}/edit/:id`;

export const NewsPageCreate = `${NewsPageRoute}/create`;

export const ProjectPageRoute = "/projects";

export const ProjectPageEdit = `${ProjectPageRoute}/edit/:id`;

export const ProjectPageCreate = `${ProjectPageRoute}/create`;

export const PostgraduatesPageRoute = "/postgraduates";

export const PostgraduatesPageEdit = `${PostgraduatesPageRoute}/edit/:id`;

export const PostgraduatesPageCreate = `${PostgraduatesPageRoute}/create`;

export const CoApplicationPageRoute = "/coapplications";

export const CoApplicationsPageEdit = `${CoApplicationPageRoute}/edit/:id`;

export const CoApplicationsPageCreate = `${CoApplicationPageRoute}/create`;

export const DissertationCouncilsPageRoute = "/dissertation-councils";

export const DissertationCouncilsPageEdit = `${DissertationCouncilsPageRoute}/edit/:id`;

export const DissertationCouncilsPageCreate = `${DissertationCouncilsPageRoute}/create`;

export const LabsPageRoute = "/labs";

export const LabsPageEdit = `${LabsPageRoute}/edit/:id`;

export const LabsPageCreate = `${LabsPageRoute}/create`;

export const NomusPageRoute = "/nomus";

export const NomusPageEdit = `${NomusPageRoute}/edit/:id`;

export const NomusPageCreate = `${NomusPageRoute}/create`;

export const JournalsPageRoute = "/scientific-journals";

export const JournalsPageEdit = `${JournalsPageRoute}/edit/:id`;

export const JournalsPageCreate = `${JournalsPageRoute}/create`;

export const PracticesPageRoute = "/practices";

export const PracticesPageEdit = `${PracticesPageRoute}/edit/:id`;

export const PracticesPageCreate = `${PracticesPageRoute}/create`;

export const InternshipsApplicationsPageRoute = "/internships-applications";

export const InternshipsApplicationsPageEdit = `${InternshipsApplicationsPageRoute}/edit/:id`;

export const InternshipsApplicationsPageCreate = `${InternshipsApplicationsPageRoute}/create`;

export const InternshipsPageRoute = "/internships";

export const InternshipsPageEdit = `${InternshipsPageRoute}/edit/:id`;

export const InternshipsPageCreate = `${InternshipsPageRoute}/create`;

export const EducationsPageRoute = "/educations";

export const EducationsPageEdit = `${EducationsPageRoute}/edit/:id`;

export const EducationsPageCreate = `${EducationsPageRoute}/create`;

export const ExpertisesApplicationsPageRoute = "/expertises-applications";

export const ExpertisesApplicationsPageEdit = `${ExpertisesApplicationsPageRoute}/edit/:id`;

export const ExpertiseHistoryPage = `${ExpertisesApplicationsPageRoute}/history/:id`;

export const UsersPageRoute = "/users";

export const UsersPageEdit = `${UsersPageRoute}/edit/:id`;

export const UsersPageCreate = `${UsersPageRoute}/create`;

export const OrganizationsPageRoute = "/organizations";

export const OrganizationsPageEdit = `${OrganizationsPageRoute}/edit/:id`;

export const OrganizationsPageCreate = `${OrganizationsPageRoute}/create`;

export const EventsPageRoute = "/events";

export const EventsPageEdit = `${EventsPageRoute}/edit/:id`;

export const EventsPageCreate = `${EventsPageRoute}/create`;

export const ProfilePage = "/profile";

export const SettingsPage = "/settings";

export const PagesRoute = "/pages";

export const PagesEditRoute = "/pages/edit/:id";

export const PagesCreateRoute = "/pages/create";

export const DigitalResourcesPageRoute = "/digital-resources";

export const DigitalResourcesPageEdit = `${DigitalResourcesPageRoute}/edit/:id`;

export const DigitalResourcesPageCreate = `${DigitalResourcesPageRoute}/create`;

export const ProfessionalOrientationsPageRoute = "/professional-orientations";

export const ProfessionalOrientationsPageEdit = `${ProfessionalOrientationsPageRoute}/edit/:id`;

export const ProfessionalOrientationsPageCreate = `${ProfessionalOrientationsPageRoute}/create`;

export const OutsideResourcesPageRoute = "/outsideResources";

export const OutsideResourcesPageEdit = `${OutsideResourcesPageRoute}/edit/:id`;

export const OutsideResourcesPageCreate = `${OutsideResourcesPageRoute}/create`;

export const PreUnivercityEventsPageRoute = "/pre-univercity-events";

export const PreUnivercityEventsPageEdit = `${PreUnivercityEventsPageRoute}/edit/:id`;

export const PreUnivercityEventsPageCreate = `${PreUnivercityEventsPageRoute}/create`;

export const ProjectsPageRoute = "/projects";

export const ProjectsPageEdit = `${ProjectsPageRoute}/edit/:id`;

export const ProjectsPageCreate = `${ProjectsPageRoute}/create`;

export const CourseProgrammesPageEdit = `${EducationsPageRoute}/edit/:id/course-programmes/edit`;

export const DictionariesPageRoute = "/dictionaries";

export const DictionariesPageEdit = `${DictionariesPageRoute}/edit/:id`;

export const TipsPageRoute = "/tips";

export const TipsPageEdit = `${TipsPageRoute}/edit/:id`;

export const FaqPageRoute = "/faq";

export const FaqPageEdit = `${FaqPageRoute}/edit/:id`;

export const FaqPageCreate = `${FaqPageRoute}/create`;

export const NotificationPageRoute = "/notifications";

export const NotificationPageEdit = `${NotificationPageRoute}/edit/:id`;

export const NotificationPageConfirm = `${NotificationPageRoute}/edit/:id/send-tech-work`;

export const PagesPageRoute = "/pages";

export const PagesPageEdit = `${PagesPageRoute}/edit/:id`;

export const PagesPageCreate = `${PagesPageRoute}/create`;

export const VacancyPageRoute = "/vacancy";

export const VacancyPageEdit = `${VacancyPageRoute}/edit/:id`;

export const VacancyPageCreate = `${VacancyPageRoute}/create`;

export const YoungPolicyConferencePageRoute = "/young-policy-conference";

export const YoungPolicyConferencePageEdit = `${YoungPolicyConferencePageRoute}/edit/:id`;

export const YoungPolicyConferencePageCreate = `${YoungPolicyConferencePageRoute}/create`;

export const YoungPolicyContestPageRoute = "/young-policy-contest";

export const YoungPolicyContestPageEdit = `${YoungPolicyContestPageRoute}/edit/:id`;

export const YoungPolicyContestPageCreate = `${YoungPolicyContestPageRoute}/create`;

export const YoungPolicyEducationsPageRoute = "/young-policy-educations";

export const YoungPolicyEducationsPageEdit = `${YoungPolicyEducationsPageRoute}/edit/:id`;

export const YoungPolicyEducationsPageCreate = `${YoungPolicyEducationsPageRoute}/create`;

export const YoungPolicyProjectPageRoute = "/young-policy-projects";

export const YoungPolicyProjectPageEdit = `${YoungPolicyProjectPageRoute}/edit/:id`;

export const YoungPolicyProjectPageCreate = `${YoungPolicyProjectPageRoute}/create`;

export const PreUnivercityEducationsPageRoute = "/pre-univercity-educations";

export const PreUnivercityEducationsPageEdit = `${PreUnivercityEducationsPageRoute}/edit/:id`;

export const PreUnivercityEducationsPageCreate = `${PreUnivercityEducationsPageRoute}/create`;

export const PreUnivercityProjectsPageRoute = "/pre-univercity-projects";

export const PreUnivercityProjectsPageEdit = `${PreUnivercityProjectsPageRoute}/edit/:id`;

export const PreUnivercityProjectsPageCreate = `${PreUnivercityProjectsPageRoute}/create`;

export const GrantsPageRoute = "/grants";

export const GrantsPageEdit = `${GrantsPageRoute}/edit/:id`;

export const GrantsPageCreate = `${GrantsPageRoute}/create`;

export const PaymentsPageRoute = "/payments";

export const SettingsPageRoute = "/settings";
