import React, { useEffect, useState } from "react";
import { Autocomplete, FormControl } from "@mui/material";

const CustomAutocomplete: React.FC<any> = ({ options, defaultValue, onChange, ...otherProps }) => {
  const [value, setValue] = useState<any | null>(null);

  useEffect(() => {
    if (defaultValue) {
      const matchedOption = options.find((option: any) => option.id === defaultValue.id);
      setValue(matchedOption || null);
    }
  }, [defaultValue, options]);

  const handleChange = (_: any, newValue: any | null) => {
    setValue(newValue);

    if (onChange) {
      onChange(newValue, newValue);
    }
  };

  return (
    <FormControl fullWidth>
      <Autocomplete {...otherProps} options={options} value={value} onChange={handleChange} />
    </FormControl>
  );
};

export default CustomAutocomplete;
