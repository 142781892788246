import React from "react";
import logo from "~shared/assets/svg/logo.svg";

type Props = {
  className?: string;
};

export const Logo: React.FC<Props> = ({ className = "" }) => {
  return <img className={className} width={183} height={48} src={logo} alt='logo' />;
};
