import { useCallback, useEffect, useRef } from "react";
import equal from "fast-deep-equal";
import { omit, pick } from "rambda";

export const getBaseRefInitializer = async ({
  isCreate,
  value,
  newValue
}: {
  isCreate: boolean;
  value: any;
  newValue: any;
}) => {
  let [value1, value2] = await Promise.all([value, newValue]).catch(() => []);
  const excludedFields = [
    "slug",
    "rejectReason",
    "publishedAt",
    "status",
    "id",
    "documentsGallery",
    "createdAt",
    "updatedAt",
    "inFavourites"
  ];

  [value1, value2] = [value1, value2].map((value) => {
    if (value?.documentsGallery) {
      value.documentsGallery = pick("files", value.documentsGallery);
    }
    return omit(excludedFields, value);
  });
  return isCreate || (!isCreate && !equal(value1, value2));
};

export const useChangesRef = () => {
  const ref = useRef<any>();
  const setRef = useCallback((callback: () => Promise<boolean>) => {
    ref.current = callback;
  }, []);

  useEffect(() => {
    return () => {
      ref.current = () => Promise.resolve(false);
    };
  }, []);

  return {
    hasChangesCallback: ref,
    setRef
  };
};
