import React from "react";
import { CircularProgress } from "@mui/material";
import { ContactPersonsModel } from "~/generated/graphql";
import { AppLine } from "~/modules/ExpertisesApplicationsTable/components/Filters/components/FiltersContent";
import { DeepPartial } from "~/modules/ExpertisesApplicationsTable/types";
import { ContactRow } from "../ContactRow";

type Props = {
  isLoading: boolean;
  removeHandler: () => void;
  openModal: () => void;
  contactPersons: DeepPartial<ContactPersonsModel>[];
};

export const ContactPersonsData: React.FC<Props> = ({
  isLoading,
  contactPersons,
  openModal,
  removeHandler
}) => {
  return (
    <div className='flex flex-col gap-4'>
      {isLoading && (
        <div className='w-full flex items-center justify-center'>
          <CircularProgress />
        </div>
      )}
      {!isLoading && contactPersons.length === 0 && (
        <div className='w-full flex items-center justify-center text-gray text-base'>
          Данные отсутствуют
        </div>
      )}
      {contactPersons.map((person, ind) => (
        <div key={person.id?.toString().concat(ind.toString())}>
          <ContactRow user={person} handleOpenEdit={openModal} handleDelete={removeHandler} />
          <AppLine />
        </div>
      ))}
    </div>
  );
};
