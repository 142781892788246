import React from "react";
import { useModal } from "~/shared/hooks/useModal";
import { ContactPersonsInput, ContactPersonsModel } from "~/generated/graphql";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Text } from "~/shared/components/Text";
import { CreateContactsModal } from "../CreateContactsModal";
import { DeepPartial } from "react-hook-form";

type Props = {
  contactPersons: DeepPartial<ContactPersonsModel>[];
  addItem: (input: ContactPersonsInput) => Promise<void>;
  isLeader: boolean;
  isLinkExist: boolean;
};

export const FormActions: React.FC<Props> = ({
  contactPersons,
  addItem,
  isLeader,
  isLinkExist
}) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <div className='flex md:gap-6 gap-4 max-md:flex-col'>
        <Autocomplete
          disablePortal
          id='contactPerson'
          freeSolo
          className='w-[340px]'
          options={contactPersons}
          getOptionLabel={(option) => (option as ContactPersonsModel).name || ""}
          noOptionsText={<Text>No options</Text>}
          size='small'
          onChange={(_, value) => {
            addItem(value as ContactPersonsInput);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true
              }}
              multiline
              label={<Text>{isLeader ? "Выбрать руководителя" : "Выбрать контактное лицо"}</Text>}
            />
          )}
        />
        <Button onClick={() => handleOpen()}>
          {isLeader ? "Добавить руководителя" : "Добавить контактное лицо"}
        </Button>
      </div>
      <CreateContactsModal
        isOpen={!!open}
        onClose={handleClose}
        onSubmit={addItem}
        title={isLeader ? "Добавить руководителя" : "Добавить контактное лицо"}
        isLeader={!!isLeader}
        isLinkExist={isLinkExist}
      />
    </>
  );
};
