import { CSSProperties, ReactNode } from "react";
import { ProjectsModel } from "~/generated/graphql";
import {
  PreUnivercityProjectsPageEdit,
  ProjectPageEdit,
  YoungPolicyProjectPageEdit
} from "~/shared/routes";

type Model = Pick<
  ProjectsModel,
  "id" | "image" | "name" | "description" | "publishedAt" | "published" | "status"
>;

export interface Column {
  id: any;
  label: JSX.Element | string;
  defaultSelected: boolean;
  selectTitle: string;
  style?: CSSProperties;
  align?: "right" | "center";
  render?: (_value: any, row: Model) => ReactNode;
  className?: string;
}

export enum Path {
  ProjectPage = "/projects",
  YoungPolicyProjectsPage = "/young-policy-projects",
  PreUnivercityProjectsPage = "/pre-univercity-projects"
}

export const pathLink: {
  [key: string]: string;
} = {
  ProjectPage: ProjectPageEdit,
  YoungPolicyProjectsPage: YoungPolicyProjectPageEdit,
  PreUnivercityProjectsPage: PreUnivercityProjectsPageEdit
};
