import React, { AnchorHTMLAttributes } from "react";
import { Text } from "../Text";
import { clsx } from "clsx";
import { ExternalLinkIcon } from "~shared/components/Icons";

type Props = AnchorHTMLAttributes<HTMLAnchorElement>;

export const ExternalLink: React.FC<Props> = ({ children, ...props }) => {
  return (
    <a
      target='_blank'
      {...props}
      className={clsx("flex gap-1 items-center hover:opacity-80 cursor:pointer", props.className)}
    >
      <ExternalLinkIcon />
      <span className='underline text-blueText font-medium'>
        {typeof children === "string" ? <Text>{children}</Text> : children}
      </span>
    </a>
  );
};
