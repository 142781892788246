import clsx from "clsx";
import React from "react";
import { Maybe, PracticesEventsModel, usePracticesEventsUpsertMutation } from "~/generated/graphql";
import { FormActions } from "./components/FormActions";
import { Table } from "./components/Table";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { DeepPartial, useFormContext } from "react-hook-form";
import { useAlertsStore } from "~/shared/stores/alerts";
import { Box } from "@mui/material";

type Props = {
  initialValues: {
    items?: Maybe<DeepPartial<PracticesEventsModel>>[] | null;
    practiceId?: Maybe<number>;
  };
  className?: string;
  refetch: () => void;
  isLoading?: boolean;
  disabled?: boolean;
};

export const EventsFrom: React.FC<Props> = ({
  className,
  initialValues,
  refetch,
  isLoading,
  disabled
}) => {
  const { watch, setValue } = useFormContext();

  const state: Props["initialValues"]["items"] = watch("events");

  const client = useGraphqlClient();

  const addAlert = useAlertsStore((state) => state.addAlert);

  const { mutateAsync: upsert } = usePracticesEventsUpsertMutation(client);

  const addItem = (input: any): Promise<void> => {
    return upsert({
      input: {
        ...(Boolean(input?.id) && { id: input?.id }),
        name: input?.name,
        description: input?.description,
        ...(Boolean(initialValues?.practiceId) && { practice: { id: initialValues?.practiceId } })
      }
    })
      .then((data): void => {
        addAlert("success", "Успешно");
        setValue(
          "events",
          state?.reduce((res: Maybe<DeepPartial<PracticesEventsModel>>[], cur) => {
            if (cur?.id === data?.practicesEventsUpsert?.id) {
              cur = { ...input, id: data?.practicesEventsUpsert?.id as number };
            }

            res.push(cur);
            return res;
          }, []) ?? [{ ...input, id: data?.practicesEventsUpsert?.id as number }]
        );
        refetch?.();
      })
      .catch(() => {
        addAlert("error", "Ошибка");
      });
  };

  const removeItem = (id: number) => {
    setValue(
      "events",
      state?.filter((item) => item?.id !== id)
    );
  };

  return (
    <Box
      sx={{ pointerEvents: disabled ? "none" : "all" }}
      className={clsx("flex flex-col gap-8", className)}
    >
      <div className='flex items-center justify-between'></div>

      <div className='card-base flex flex-col gap-8'>
        <div className='flex max-md:flex-col items-start justify-between max-md:gap-4 '>
          <div className='flex md:gap-6 gap-4 max-md:flex-col max-md:w-full'>
            <FormActions addItem={addItem} />
          </div>
        </div>
        <Table
          items={state ?? []}
          addItem={addItem}
          onRemove={removeItem}
          isLoading={!!isLoading}
        />
      </div>
    </Box>
  );
};
