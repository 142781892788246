import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  TextareaAutosize,
  MenuItem,
  Autocomplete
} from "@mui/material";
import { useAlertsStore } from "~/shared/stores/alerts";
import {
  ContactPersonsModel,
  useImageDeleteMutation,
  useOrganisersQuery,
  useUploadImageMutation
} from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { Text } from "~/shared/components/Text";
import { getErrorMessage } from "~/shared/lib/getError";
import { HelperText } from "~/shared/components/HelperText";
import { DeepPartial } from "~/modules/ExpertisesApplicationsTable/types";
import { ImageInput } from "~/shared/components/ImageInput";
import { getImageSrc } from "~/shared/lib/getImageSrc";
import { PhoneInput } from "~/shared/components/PhoneInput";
import { useRole } from "~/entities/Roles";
import { useMyOrganisersQuery } from "~/entities/Organizers/lib/useMyOrganisersQuery";
import { unifyName } from "~/shared/lib/unifyName";
import { useLocation } from "react-router-dom";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => Promise<void>;
  title: string;
  initialValue?: DeepPartial<ContactPersonsModel>;
  isLeader: boolean;
  isLinkExist: boolean;
};

export const CreateContactsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  initialValue,
  isLeader,
  isLinkExist
}) => {
  const [values, setValues] = useState<DeepPartial<ContactPersonsModel> | null>();
  const [errors, setErrors] = useState<Record<string, { message: string }>>({});

  const client = useGraphqlClient();
  const addAlert = useAlertsStore((state) => state.addAlert);
  const getError = getErrorMessage(errors);
  const location = useLocation();
  const hiddenOrgField = location.pathname.includes("/pages/edit");
  const { mutateAsync: uploadImageMutation } = useUploadImageMutation(client, {
    onError: () => {
      useAlertsStore.getState().addAlert("error", "Ошибка при загрузке файла");
    }
  });

  const { mutateAsync: deleteMutation } = useImageDeleteMutation(client);

  const uploadImageHandler: (image?: File | null) => void = (image) => {
    uploadImageMutation({ image }).then((url) => {
      setValues((cur) => ({
        ...cur,
        image: {
          id: url.imageUpload.id
        }
      }));
    });
  };

  const deleteImageHandler = async () => {
    await deleteMutation({ id: Number(values?.image?.id) });
    setValues((cur) => ({
      ...cur,
      image: null
    }));
  };

  const isCreateMode = !initialValue;

  const { hasPermissions } = useRole();

  const isAdmin = hasPermissions(["all"]);

  const { data: organizersQueryData } = useMyOrganisersQuery({
    enabled: isCreateMode || isAdmin
  });

  const { data: allOrganizersQueryData } = useOrganisersQuery(
    client,
    {
      where: { id: { eq: initialValue?.org?.id } }
    },
    { enabled: !(isCreateMode || isAdmin) }
  );

  const organisersResponse = isCreateMode || isAdmin ? organizersQueryData : allOrganizersQueryData;

  const organizations = useMemo(
    () => unifyName(organisersResponse?.organisers.data ?? []),
    [organisersResponse]
  );

  const create = () => {
    const errors = Object.entries(values ?? {}).map(([key, value]) => {
      if (
        ["firstName", "lastName", "surName", "position", "email", "phone", "org"].includes(key) &&
        !value
      ) {
        return [key, "Обязательное поле"];
      }

      if (["firstName", "lastName", "surName", "org"].includes(key) && value?.length < 2) {
        return [key, "Длина должна быть больше 2 символов"];
      }

      return;
    });

    if (errors?.filter(Boolean)?.length) {
      errors?.map((error) =>
        setErrors((errors) => ({ ...errors, [error?.[0] ?? ""]: { message: error?.[1] ?? "" } }))
      );
      return;
    }

    onSubmit({ ...values, org: { id: values?.org?.id } }).then(onClose);
  };

  useEffect(() => {
    setValues(initialValue);
  }, [initialValue]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          height: "80vh",
          overflow: "auto",
          width: 600
        }}
      >
        <Typography id='modal-title' variant='h6' component='h2' marginBottom={2}>
          {title}
        </Typography>
        <div className='mb-2'>
          <ImageInput
            addAlert={addAlert}
            url={getImageSrc(values?.image) ?? ""}
            value={values?.image}
            onUpdate={(image) => {
              setValues((cur) => ({ ...cur, image }));
            }}
            onChange={uploadImageHandler}
            onDelete={deleteImageHandler}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <FormControl fullWidth>
            <TextField
              label={<Text>Фамилия</Text>}
              error={!!getError("lastName")}
              value={values?.lastName}
              onChange={(e) => {
                setErrors((cur) => {
                  delete cur.lastName;
                  return { ...cur };
                });
                setValues((cur) => ({ ...cur, lastName: e.target.value }));
              }}
            />
            <HelperText error={getError("lastName")} />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label={<Text>Имя</Text>}
              error={!!getError("firstName")}
              value={values?.firstName}
              onChange={(e) => {
                setErrors((cur) => {
                  delete cur.firstName;
                  return { ...cur };
                });
                setValues((cur) => ({ ...cur, firstName: e.target.value }));
              }}
            />
            <HelperText error={getError("firstName")} />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label={<Text>Отчество</Text>}
              error={!!getError("surName")}
              value={values?.surName}
              onChange={(e) => {
                setErrors((cur) => {
                  delete cur.surName;
                  return { ...cur };
                });
                setValues((cur) => ({ ...cur, surName: e.target.value }));
              }}
            />

            <HelperText error={getError("surName")} />
          </FormControl>
        </div>
        <div className='flex flex-col gap-2 mt-2'>
          <FormControl fullWidth>
            <TextField
              label={<Text>Должность</Text>}
              error={!!getError("position")}
              value={values?.position}
              onChange={(e) => {
                setErrors((cur) => {
                  delete cur.position;
                  return { ...cur };
                });
                setValues((cur) => ({ ...cur, position: e.target.value }));
              }}
            />

            <HelperText error={getError("position")} />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label={<Text>Электронная почта</Text>}
              error={!!getError("email")}
              value={values?.email}
              onChange={(e) => {
                setErrors((cur) => {
                  delete cur.email;
                  return { ...cur };
                });
                setValues((cur) => ({ ...cur, email: e.target.value }));
              }}
            />
            <HelperText error={getError("email")} />
          </FormControl>

          {isLinkExist && (
            <FormControl fullWidth>
              <TextField
                label={<Text>Сайт</Text>}
                error={!!getError("link")}
                value={values?.link}
                onChange={(e) => {
                  setErrors((cur) => {
                    delete cur.link;
                    return { ...cur };
                  });
                  setValues((cur) => ({ ...cur, link: e.target.value }));
                }}
              />
              <HelperText error={getError("link")} />
            </FormControl>
          )}

          <FormControl fullWidth>
            <PhoneInput
              error={!!getError("phone")}
              label={<Text>Contact phone</Text>}
              value={values?.phone ?? ""}
              onChange={(e) => {
                setErrors((cur) => {
                  delete cur.phone;
                  return { ...cur };
                });
                setValues((cur) => ({ ...cur, phone: e }));
              }}
            />
            <HelperText error={getError("phone")} />
          </FormControl>

          {isLeader && (
            <FormControl fullWidth>
              <TextField
                label={<Text>Краткая биография</Text>}
                multiline
                fullWidth
                error={!!getError("leaderDescription")}
                InputProps={{
                  inputComponent: TextareaAutosize
                }}
                value={values?.leaderDescription ?? ""}
                onChange={(e) => {
                  setErrors((cur) => {
                    delete cur.leaderDescription;
                    return { ...cur };
                  });
                  setValues((cur) => ({ ...cur, leaderDescription: e?.target?.value }));
                }}
              />
              <HelperText error={getError("leaderDescription")} />
            </FormControl>
          )}

          {!hiddenOrgField && (
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id='org'
                freeSolo
                className='w-full'
                value={(values?.org as unknown as string) ?? ""}
                options={organizations}
                getOptionLabel={(option: any) => option?.name || ""}
                noOptionsText={<Text>No options</Text>}
                size='small'
                onChange={(_: any, value: any) => {
                  setValues((cur) => ({
                    ...cur,
                    org: value
                  }));
                }}
                renderOption={(props: any, option: any) => (
                  <MenuItem key={option.id} {...props} id={option.id}>
                    {option.name}
                  </MenuItem>
                )}
                renderInput={(params: any) => (
                  <Box sx={{ display: "flex" }}>
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      error={getError("org")}
                      label={<Text>Organizer</Text>}
                    />
                  </Box>
                )}
              />
              <HelperText error={getError("org")} />
            </FormControl>
          )}
        </div>

        <Box marginTop={2} sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
          <Button variant='outlined' color='primary' onClick={onClose}>
            Закрыть
          </Button>
          <Button variant='contained' color='primary' onClick={create}>
            {initialValue ? "Сохранить" : "Создать"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
