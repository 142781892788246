import React from "react";
import { Text } from "../Text";
import clsx from "clsx";
import { StatusesExpertise } from "~/shared/types/statusesExpertise";

type Props = {
  value?: { id?: number | null; name?: string | null } | null;
};

export const StatusColorWrapper: React.FC<Props> = ({ value }) => {
  return (
    <Text
      className={clsx("text-black font-semibold", {
        "text-blue-500": value?.id === StatusesExpertise.DECLINED_ID,
        "!text-primary": value?.id === StatusesExpertise.DRAFT_ID,
        "text-yellow-600":
          value?.id === StatusesExpertise.ON_TECH_EXPERTISE_ID ||
          value?.id === StatusesExpertise.ON_CONTENT_EXPERTISE_ID,
        "text-red-500": value?.id === StatusesExpertise.DECLINED_NO_RETRY_ID
      })}
    >
      {value?.name ?? ""}
    </Text>
  );
};
