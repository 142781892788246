import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { DetailsHead } from "~/shared/components/DetailsHead";
import { Panel } from "~/shared/components/Panel";
import { PageWrapper } from "~/shared/components/PageWrapper";
import { useNavigationBack } from "~/shared/hooks/useBackClick";
import { usePageLoadingStore } from "~/shared/stores/pageLoading";
import { usePracticesByIdQuery, usePracticesDeleteMutation } from "~/generated/graphql";
import { DetailsFormFooter } from "~/shared/components/DetailsFormFooter";
import { PracticeDetailsForm } from "~/modules/PracticeDetailsForm";
import { useChangesRef } from "~/shared/hooks/useChangesRef";

export const PracticesEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const isEdit = Number.isInteger(Number(id));

  const goBack = useNavigationBack();

  const client = useGraphqlClient();

  const { isSaveLoading } = usePageLoadingStore((state) => ({
    isSaveLoading: state.isSaveLoading
  }));

  const { mutateAsync: deletePractice } = usePracticesDeleteMutation(client, {
    onSuccess: goBack
  });

  const { data } = usePracticesByIdQuery(client, { id: Number(id) }, { enabled: isEdit });

  const { setRef, hasChangesCallback } = useChangesRef();

  const handleDelete = () => {
    if (!id) {
      return;
    }

    deletePractice({ id: Number(id) });
  };

  return (
    <PageWrapper>
      <Panel>
        <Box className='flex p-3 flex-col gap-6 items-center'>
          <DetailsHead
            title={!isEdit ? "Создание практики" : "Редактирование практики"}
            onBackClick={goBack}
            onRemove={isEdit ? handleDelete : undefined}
            formName='newsForm'
            isLoading={isSaveLoading}
            hasChanges={hasChangesCallback}
            clientUrl={
              data?.practicesById?.published
                ? `youth-policy-and-educational-activities/practices/${data?.practicesById?.slug}`
                : ""
            }
          />
          <PracticeDetailsForm id={Number(id)} formName='newsForm' changesGetterRef={setRef} />
        </Box>
        <DetailsFormFooter
          onBackClick={goBack}
          formName='newsForm'
          isLoading={isSaveLoading}
          hasChanges={hasChangesCallback}
        />
      </Panel>
    </PageWrapper>
  );
};
