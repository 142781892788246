import React, { ReactElement } from "react";
import { clsx } from "clsx";
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { Text } from "~shared/components/Text";

type Props = {
  active: boolean;
  open: boolean;
  icon: ReactElement;
  label: string;
  onClick: () => void;
  className?: string;
};

export const DrawerListItem: React.FC<Props> = ({
  active,
  open,
  icon,
  label,
  onClick,
  className = ""
}) => (
  <ListItemButton onClick={onClick} className={clsx("px-3", className)}>
    <Tooltip title={<Text>{label}</Text>} disableInteractive disableHoverListener={open}>
      <ListItemIcon className={clsx({ "text-primary": active }, open ? "min-w-[40px]" : "min-w-0")}>
        {icon}
      </ListItemIcon>
    </Tooltip>
    <ListItemText
      className={clsx("whitespace-nowrap", { "opacity-0": !open })}
      sx={{ maxWidth: "150px" }}
    >
      <Text className={clsx({ "text-primary": active })}>{label}</Text>
    </ListItemText>
  </ListItemButton>
);
