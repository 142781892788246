import {
  FormControl,
  MenuItem,
  TextareaAutosize,
  TextField,
  Box,
  FormLabel,
  Autocomplete as MuiAutocomplete
} from "@mui/material";
import React, { Fragment, useEffect, useMemo } from "react";
import { Controller, DeepPartial, useFormContext } from "react-hook-form";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import {
  useImageDeleteMutation,
  ProjectsModel,
  useUploadImageMutation,
  useCitiesQuery,
  useSpecialtiesQuery,
  useUploadDocumentMutation,
  FilesModel,
  useMassUploadVideoMutation,
  useVideoDeleteMutation,
  AudienceTargetsModel,
  useAudienceTargetsQuery,
  SpecialtiesModel,
  useOrganisersQuery,
  useDocumentDeleteMutation
} from "~/generated/graphql";
import { HelperText } from "~/shared/components/HelperText";
import { ImageForm } from "~/shared/components/ImageForm";
import { Text } from "~/shared/components/Text";
import { DateTimePicker } from "~shared/components/DateTimePicker";
import { getErrorMessage } from "~/shared/lib/getError";
import {
  baseRequired,
  baseDateValidation,
  getBaseUrlValidation,
  baseIdRequired
} from "~/shared/lib/validation";
import { useAlertsStore } from "~/shared/stores/alerts";
import { EntityInfo } from "~shared/components/EntityInfo";
import { AppSwitch } from "~shared/components/AppSwitch";
import Autocomplete from "~/shared/components/Autocomplete/Autocomplete";
import { LinkInput } from "~/shared/components/LinkInput";
import { useMyOrganisersQuery } from "~/entities/Organizers/lib/useMyOrganisersQuery";
import { getImageSrc } from "~/shared/lib/getImageSrc";
import { ImageInput } from "~/shared/components/ImageInput";
import { FilesMultipleInput } from "~/shared/components/FilesMultipleInput";
import { StatusesExpertise } from "~/shared/types/statusesExpertise";
import { useRole } from "~/entities/Roles";
import { audienceFilterByName } from "~/modules/DictionariesEditTable/lib/audiencyFilter";
import { useStartEndDate } from "~/shared/hooks/useStartEndDate";
import { useCanPublish } from "~/entities/Roles/useCanPublish";
import { unifyName } from "~/shared/lib/unifyName";

type Props = {
  sectionModeratorRole: string;
  audienceTargetsCategory: string;
  canEdit?: boolean;
  initialValues?: DeepPartial<ProjectsModel> | null;
};

export const GeneralForm: React.FC<Props> = ({
  initialValues,
  audienceTargetsCategory,
  canEdit,
  sectionModeratorRole
}) => {
  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
    clearErrors,
    setError
  } = useFormContext();

  const getError = getErrorMessage(errors);

  const addAlert = useAlertsStore((state) => state.addAlert);

  const client = useGraphqlClient();

  const isCreateMode = !Number.isInteger(initialValues?.id);

  const { hasPermissions } = useRole();

  const isAdmin = hasPermissions(["all"]);

  const { data: organizersQueryData } = useMyOrganisersQuery({ enabled: isCreateMode || isAdmin });

  const { data: allOrganizersQueryData } = useOrganisersQuery(
    client,
    {
      where: { id: { eq: initialValues?.organizer?.id } }
    },
    { enabled: !(isCreateMode || isAdmin) }
  );

  const organisersResponse = isCreateMode || isAdmin ? organizersQueryData : allOrganizersQueryData;

  const organizations = useMemo(
    () => unifyName(organisersResponse?.organisers.data ?? []),
    [organisersResponse]
  );

  const { mutateAsync: uploadImageMutation } = useUploadImageMutation(client, {
    onError: () => {
      useAlertsStore.getState().addAlert("error", "Ошибка при загрузке файла");
    }
  });

  const { mutateAsync: deleteMutation } = useImageDeleteMutation(client);

  const { data: audienceTargetsQueryData } = useAudienceTargetsQuery(client);
  const audienceTargets = (audienceTargetsQueryData?.audienceTargets.data ?? []).filter(
    (target) =>
      audienceFilterByName(audienceTargetsCategory)(target) ||
      target.name === initialValues?.audienceTarget?.name
  );

  const uploadImageHandler: (image?: File | null, isDetail?: boolean) => void = (
    image,
    isDetail
  ) => {
    uploadImageMutation({ image }).then((url) => {
      const path = isDetail ? "imageDetail" : "image";

      setValue(path, {
        id: url.imageUpload.id
      });
    });
  };

  const deleteImageHandler = async (isDetail?: boolean) => {
    const path = isDetail ? "imageDetail" : "image";
    const imageId = getValues(path)?.id;
    await deleteMutation({ id: Number(imageId) });
    setValue(path, null);
  };

  const { mutateAsync: massUploadVideoMutation } = useMassUploadVideoMutation(client);

  const { mutateAsync: deleteVideoMutation } = useVideoDeleteMutation(client);

  const uploadVideoHandler: (file?: Array<File> | null) => void = (file) => {
    massUploadVideoMutation({ videos: file }).then(({ videoMassUpload }) => {
      setValue("video", videoMassUpload?.[0]);
    });
  };

  const deleteVideoHandler = async () => {
    const currentFile = getValues("video");
    await deleteVideoMutation({ id: Number(currentFile?.id) });
    setValue("video", null);
  };

  const canChangePublished = useCanPublish(initialValues?.status?.id as StatusesExpertise, [
    sectionModeratorRole
  ]);

  const { mutateAsync: uploadDocumentMutation } = useUploadDocumentMutation(client);

  const { mutateAsync: deleteDocumentMutation } = useDocumentDeleteMutation(client);

  const uploadDocumentHandler: (name: string) => (document?: File[] | null) => void =
    (name: string) => (document) => {
      const file = Array.isArray(document) ? document?.[0] : document;
      uploadDocumentMutation({ document: file }).then((url) =>
        setValue(name, {
          id: url.documentUpload.id,
          name: url.documentUpload?.name
        })
      );
    };

  const deletDocumentHandler = (name: string) => async () => {
    const imageId = getValues(name)?.id;
    await deleteDocumentMutation({ id: Number(imageId) });
    setValue(name, null);
  };

  const { data: citiesQueryData } = useCitiesQuery(client);
  const cities = citiesQueryData?.cities.data ?? [];

  const { data: specialtiesQueryData } = useSpecialtiesQuery(client, {
    where: { isNir: { eq: true } }
  });
  const specialties = specialtiesQueryData?.specialties.data?.filter((item) => item?.parent) ?? [];

  const { minDateTime, onError } = useStartEndDate({ watch, clearErrors, setError, errors });

  useEffect(() => {
    if (isCreateMode && organizations.length === 1) {
      setValue("organizer", organizations.at(0));
    }
  }, [isCreateMode, organizations]);

  return (
    <ImageForm
      addAlert={addAlert}
      onChange={uploadImageHandler}
      onDelete={deleteImageHandler}
      disabled={!canEdit}
      asideChildren={
        <EntityInfo createdAt={initialValues?.createdAt} updatedAt={initialValues?.updatedAt} />
      }
    >
      <div className='flex flex-wrap gap-2'>
        <Controller
          name='published'
          defaultValue={initialValues?.published || false}
          render={({ field }) => (
            <AppSwitch
              checked={!!field.value}
              label='Published'
              disabled={!canChangePublished}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name='publishedAt'
          rules={baseDateValidation}
          render={({ field }) => (
            <div className='max-sm:grow'>
              <DateTimePicker
                label={<Text>Published at</Text>}
                value={field.value ?? null}
                className='w-full'
                disabled
                onChange={field.onChange}
              />
              <HelperText id='publishedAt' error={getError("publishedAt")} />
            </div>
          )}
        />
      </div>
      <Controller
        name='startDate'
        rules={baseDateValidation}
        render={({ field }) => (
          <div className='max-sm:grow'>
            <DateTimePicker
              label={<Text>Starting date</Text>}
              value={field.value ?? null}
              className='w-full'
              onChange={field.onChange}
              error={getError("startDate")}
              disabled={!canEdit}
            />
            <HelperText error={getError("startDate")} />
          </div>
        )}
      />
      <Controller
        name='endDate'
        rules={baseDateValidation}
        render={({ field }) => (
          <div className='max-sm:grow'>
            <DateTimePicker
              label={<Text>Ending date</Text>}
              value={field.value ?? null}
              className='w-full'
              onChange={field.onChange}
              onError={onError}
              minDateTime={minDateTime}
              error={getError("endDate")}
              disabled={!canEdit}
            />
            <HelperText error={getError("endDate")} />
          </div>
        )}
      />

      <Controller
        name='commentForModerator'
        defaultValue={initialValues?.commentForModerator}
        render={({ field: { value, ...other } }) => (
          <FormControl fullWidth>
            <TextField
              label={<Text>Комментарий для модератора</Text>}
              value={value ?? ""}
              error={!!getError("commentForModerator")}
              {...other}
              multiline
              InputProps={{
                inputComponent: TextareaAutosize
              }}
              disabled={!canEdit}
            />

            <HelperText error={getError("commentForModerator")} />
          </FormControl>
        )}
      />

      <Controller
        name='name'
        defaultValue={initialValues?.name}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              label={<Text>Название</Text>}
              value={value ?? ""}
              error={!!getError("name")}
              {...register("name", {
                ...baseRequired,
                maxLength: { value: 500, message: "Max length text field 500" }
              })}
              disabled={!canEdit}
            />

            <HelperText error={getError("name")} />
          </FormControl>
        )}
      />

      <Controller
        name='description'
        defaultValue={initialValues?.description}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              label={<Text>Description</Text>}
              value={value ?? ""}
              multiline
              fullWidth
              error={!!getError("description")}
              InputProps={{
                inputComponent: TextareaAutosize
              }}
              disabled={!canEdit}
              {...register("description")}
            />
            <HelperText error={getError("description")} />
          </FormControl>
        )}
      />
      <Controller
        name='audienceTarget'
        control={control}
        rules={baseIdRequired}
        render={({ field: { value, ...other } }) => (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id='audienceTarget'
              className='w-full'
              defaultValue={initialValues?.audienceTarget as AudienceTargetsModel}
              {...other}
              isOptionEqualToValue={(option: AudienceTargetsModel, value: AudienceTargetsModel) =>
                option.name === value.name
              }
              value={value as AudienceTargetsModel}
              options={audienceTargets ?? []}
              getOptionKey={(option: AudienceTargetsModel) => `${option.id}-${option.name}`}
              getOptionLabel={(option: AudienceTargetsModel) => option.name || ""}
              noOptionsText={<Text>No options</Text>}
              size='small'
              disabled={!canEdit}
              onChange={(_: Event, value: AudienceTargetsModel) => {
                setValue("audienceTarget", value as AudienceTargetsModel);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={!canEdit}
                  multiline
                  error={!!getError("audienceTarget")}
                  label={<Text>AudienceTarget</Text>}
                />
              )}
            />
            <HelperText error={getError("audienceTarget")} />
          </FormControl>
        )}
      />
      <Controller
        name='organizer'
        rules={baseRequired}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              // key={value?.name}
              id='organizer'
              freeSolo
              disabled={!canEdit}
              className='w-full'
              defaultValue={
                isCreateMode && organizations.length === 1
                  ? organizations.at(0)
                  : initialValues?.organizer
              }
              options={organizations}
              getOptionLabel={(option: any) => option?.name || ""}
              noOptionsText={<Text>No options</Text>}
              size='small'
              value={value}
              onChange={(_: any, value: any) => {
                setValue("organizer", value);
              }}
              renderOption={(props: any, option: any) => (
                <Fragment key={option.id}>
                  <MenuItem {...props} id={option.id}>
                    {option.name}
                  </MenuItem>
                </Fragment>
              )}
              renderInput={(params: any) => (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!canEdit}
                    multiline
                    label={<Text>Организация</Text>}
                    error={getError("organizer")}
                  />
                </Box>
              )}
            />
            <HelperText error={getError("organizer")} />
          </FormControl>
        )}
      />
      <Controller
        name='link'
        defaultValue={initialValues?.link}
        render={({ field }) => (
          <FormControl fullWidth>
            <LinkInput
              label={<Text>Link</Text>}
              {...register("link", getBaseUrlValidation({ required: false }))}
              {...field}
              disabled={!canEdit}
              value={field.value ?? ""}
              fullWidth
              error={!!getError("link")}
            />
            <HelperText error={getError("link")} />
          </FormControl>
        )}
      />
      <Controller
        name='annotation'
        defaultValue={initialValues?.annotation}
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <TextField
              label={<Text>Annotation</Text>}
              value={value ?? ""}
              multiline
              disabled={!canEdit}
              fullWidth
              error={!!getError("annotation")}
              InputProps={{
                inputComponent: TextareaAutosize
              }}
              {...register("annotation")}
            />
            <HelperText error={getError("annotation")} />
          </FormControl>
        )}
      />
      <FormControl fullWidth>
        <FormLabel>Презентация</FormLabel>
        <Controller
          control={control}
          name='presentation'
          render={({ field }) => (
            <FilesMultipleInput
              id='document-input'
              disabled={!canEdit}
              label={"Upload or drop documents"}
              files={(Array.isArray(field?.value)
                ? field.value
                : ([field?.value] as Array<FilesModel>)
              ).filter(Boolean)}
              onFileChange={uploadDocumentHandler("presentation")}
              onDelete={deletDocumentHandler("presentation")}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel>Изображение аннотации</FormLabel>
        <Controller
          control={control}
          name='annotationImage'
          render={({ field }) => (
            <ImageInput
              disabled={!canEdit}
              addAlert={addAlert}
              url={getImageSrc(field?.value) ?? ""}
              value={field?.value}
              onUpdate={field.onChange}
              onChange={(image: any) => {
                uploadDocumentHandler("annotationImage")(image);
              }}
              onDelete={deletDocumentHandler("annotationImage")}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel>Видео аннотации</FormLabel>
        <Controller
          name='video'
          render={({ field: { value } }) => (
            <FilesMultipleInput
              disabled={!canEdit}
              id='video-input'
              label={"Upload or drop video files"}
              files={(Array.isArray(value) ? value : [value]).filter(Boolean)}
              allowedExtensions={/(\.avi|\.mp4|\.mkv|\.mov|\.wmv|\.flv|\.webm)$/i}
              onFileChange={uploadVideoHandler}
              onDelete={deleteVideoHandler}
            />
          )}
        />
      </FormControl>
      <Controller
        name='city'
        render={({ field: { value } }) => (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id='city'
              freeSolo
              disabled={!canEdit}
              className='w-full'
              defaultValue={initialValues?.city}
              options={cities}
              getOptionLabel={(option: any) => option?.name || ""}
              noOptionsText={<Text>No options</Text>}
              size='small'
              value={value}
              onChange={(_: any, value: any) => {
                setValue("city", value);
              }}
              renderOption={(props: any, option: any) => (
                <Fragment key={option.id}>
                  <MenuItem {...props} id={option.id}>
                    {option.name}
                  </MenuItem>
                </Fragment>
              )}
              renderInput={(params: any) => (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!canEdit}
                    multiline
                    label={<Text>City</Text>}
                  />
                </Box>
              )}
            />
            <HelperText id='city' error={getError("city")} />
          </FormControl>
        )}
      />
      <Controller
        name='isCollaboration'
        defaultValue={initialValues?.isCollaboration || false}
        render={({ field }) => (
          <AppSwitch
            checked={!!field.value}
            disabled={!canEdit}
            label='Коллаборации'
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        name='isVrMp'
        defaultValue={!!initialValues?.isVrMp}
        render={({ field }) => (
          <AppSwitch
            checked={!!field.value}
            label='Молодежная политика и воспитательная деятельность'
            onChange={field.onChange}
            disabled={audienceTargetsCategory === "МП и ВД" || !canEdit}
          />
        )}
      />
      {watch("isCollaboration") && !!specialties?.length && (
        <Controller
          control={control}
          name='competencies'
          defaultValue={
            Array.isArray(initialValues?.competencies) ? initialValues?.competencies : []
          }
          render={({ field: { onChange } }) => (
            <MuiAutocomplete
              defaultValue={
                (Array.isArray(initialValues?.competencies)
                  ? initialValues?.competencies
                  : []) as SpecialtiesModel[]
              }
              disabled={!canEdit}
              multiple
              disableCloseOnSelect
              options={(Array.isArray(specialties) ? specialties : []) as SpecialtiesModel[]}
              getOptionLabel={(option: SpecialtiesModel) => option.name}
              onChange={(_: any, values: SpecialtiesModel[]) => {
                onChange(values);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  multiline
                  disabled={!canEdit}
                  label={<Text>Компетенции для сотрудничества</Text>}
                  helperText={errors.specialties?.message?.toString()}
                  error={!!errors.specialties}
                />
              )}
            />
          )}
        />
      )}
    </ImageForm>
  );
};
