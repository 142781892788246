import { when } from "rambda";
import { useEffect, useState } from "react";

const getInitialState = (defaultSelected: string[], storageKey: string) => {
  return new Set<string>(
    when(Boolean, JSON.parse)(localStorage.getItem(storageKey) ?? "") || defaultSelected
  );
};

export const useSelectedColumns = (defaultSelected: string[], storageKey: string) => {
  const [selectedColumns, setSelectedColumns] = useState<Set<string>>(
    getInitialState(defaultSelected, storageKey)
  );

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify([...selectedColumns]));
  }, [storageKey, selectedColumns]);

  return {
    selectedColumns,
    setSelectedColumns
  };
};
