import { Box, ClickAwayListener } from "@mui/material";
import React, { PropsWithChildren } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Text } from "../Text";
import { Link } from "../Link";
import { LinkButton } from "../LinkButton";
import { ButtonDelete } from "../ButtonDelete";
import { useModal } from "~/shared/hooks/useModal";
import { Button } from "../Button";
import { CustomTooltip } from "../CustomTooltip";

type Props = PropsWithChildren<{
  title: string;
  clientUrl?: string;
  onBackClick?: () => void;
  onRemove?: () => void;
  hasChanges?: { current: () => Promise<boolean> };

  formName?: string;
  isLoading?: boolean;
  hideSaveButton?: boolean;
}>;

export const DetailsHead: React.FC<Props> = ({
  title,
  clientUrl,
  children,
  onRemove,
  onBackClick,
  hasChanges
}) => {
  const { open, handleOpen, handleClose } = useModal();

  const handleClick = () => {
    if (!hasChanges?.current) {
      onBackClick?.();
      return;
    }

    hasChanges?.current?.().then((isChanged) => {
      if (isChanged) {
        handleOpen();
        return;
      }

      onBackClick?.();
    });
  };

  const onSuccess = () => {
    onBackClick?.();
    handleClose();
  };

  return (
    <Box className='flex flex-wrap items-center w-full md:flex-nowrap gap-6'>
      <ClickAwayListener onClickAway={handleClose} touchEvent='onTouchStart'>
        <CustomTooltip
          disableHoverListener
          title={
            <Box className='flex flex-col gap-4'>
              <Text>Вы уверены, что хотите выйти без сохранения?</Text>
              <Box className='flex gap-6'>
                <Button
                  className='px-2 w-full min-w-fit'
                  variant='outlined'
                  color='error'
                  onClick={handleClose}
                >
                  No
                </Button>

                <Button
                  className='px-2 w-full min-w-fit'
                  variant='outlined'
                  color='success'
                  onClick={onSuccess}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          }
          open={!!open}
          arrow
        >
          <LinkButton
            className='order-first w-full md:w-fit max-xl:hidden'
            onClick={handleClick}
            variant='outlined'
          >
            Back
          </LinkButton>
        </CustomTooltip>
      </ClickAwayListener>

      <Box className='order-last md:order-first w-full md:w-fit flex flex-col gap-6 md:flex-row items-center justify-center'>
        <Text
          className='w-full md:w-fit text-center text-mainText font-bold text-[24px]'
          component='h1'
          variant='h6'
        >
          {title}
        </Text>
      </Box>

      {clientUrl && (
        <Link
          className='flex items-center justify-center w-full md:w-fit order-last md:order-first'
          to={process.env.REACT_APP_FRONT_URL + clientUrl}
          target='_blank'
        >
          <Text component='span'>to the website</Text>
          <OpenInNewIcon className='!w-[20px] !h-[20px]' />
        </Link>
      )}
      <Box className='flex gap-2 md:ml-auto flex-wrap justify-end w-full items-center md:w-fit'>
        {children}
        {onRemove && <ButtonDelete className='w-full md:w-fit' onClick={onRemove} />}
      </Box>
    </Box>
  );
};
