import React, { useState } from "react";
import { hasPath } from "rambda";
import { TParams } from "../../Filters";
import { AppCheckbox } from "../AppCheckbox";
import { OptionsModel } from "~/generated/graphql";
import { AppRadioButton } from "../AppRadioButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface CategoryFilterProps {
  options: OptionsModel[];
  title: string;
  params: TParams | null;
  plural: string;
  className?: string;
  queryKey: keyof TParams;
  inputType: string;
  onCategoryChange: (
    filters: TParams | ((newFilters: TParams | null) => TParams | null) | null
  ) => void;
}

export const MultipleFilter: React.FC<CategoryFilterProps> = ({
  options,
  title,
  params,
  className,
  queryKey,
  inputType,
  plural,
  onCategoryChange
}) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => setShowMore((prev) => !prev);

  const handleCategoryChange = (category: OptionsModel) => () => {
    if (inputType === "checkbox") {
      if (!params) return;

      const currFiltersIds = params?.[queryKey]?.in as number[];

      if (currFiltersIds?.includes(category.id as never)) {
        if (currFiltersIds.length > 1) {
          onCategoryChange((curr) => {
            return {
              ...curr,
              [queryKey]: {
                in: currFiltersIds.filter((el) => el !== category.id)
              }
            };
          });
        } else {
          onCategoryChange((curr) => {
            const newQueries = { ...curr };
            delete newQueries[queryKey];
            return {
              ...newQueries
            };
          });
        }
      } else {
        onCategoryChange((curr) => {
          if (!currFiltersIds) {
            return {
              ...curr,
              [queryKey]: {
                in: [category.id]
              }
            };
          } else {
            return {
              ...curr,
              [queryKey]: {
                in: [...currFiltersIds, category.id]
              }
            };
          }
        });
      }
    } else if (inputType === "radio") {
      onCategoryChange((curr) => {
        const query = {
          ...curr
        };
        if (category.id === -1) {
          delete query[queryKey];
        }
        return {
          ...query,
          ...(category.id !== -1 && {
            [queryKey]: {
              eq: category.id
            }
          })
        };
      });
    }
  };

  const isChecked = (categoryId: number) => {
    if (!params) return;

    return inputType === "checkbox"
      ? params?.[queryKey]?.in?.includes(categoryId as never) ||
          params?.[queryKey]?.eq === categoryId
      : params?.[queryKey]?.eq === categoryId ||
          (!hasPath([queryKey], params) && categoryId === -1);
  };

  return (
    <div className={className}>
      <div className='text-base md:text-lg font-semibold mb-4'>{title}</div>

      <div className='flex flex-col'>
        {options.slice(0, showMore ? options.length : 5).map((category) => {
          if (inputType === "checkbox") {
            return (
              <AppCheckbox
                key={category.id}
                label={category.name}
                checked={isChecked(category.id as number)}
                onChange={handleCategoryChange(category)}
              />
            );
          } else if (inputType === "radio") {
            return (
              <AppRadioButton
                key={category.id}
                label={category.name}
                value={isChecked(category.id as number)}
                onChange={handleCategoryChange(category)}
              />
            );
          } else return null;
        })}
      </div>

      {options.length > 6 && (
        <button
          onClick={toggleShowMore}
          className='flex gap-1 md:gap-2 items-center cursor-pointer mt-4 md:mt-6'
        >
          <div className='text-sm md:text-base text-primary font-semibold'>
            {showMore ? "Скрыть" : plural}
          </div>
          <ArrowDropDownIcon />
        </button>
      )}
    </div>
  );
};
