import clsx from "clsx";
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
import React, { forwardRef } from "react";
import styles from "./PhoneInput.module.css";

export const PhoneInput: React.FC<MuiTelInputProps> = forwardRef(({ className, ...props }, ref) => {
  return (
    <MuiTelInput
      ref={ref}
      className={clsx(className, styles.telInput)}
      disableDropdown
      size='small'
      fullWidth
      inputProps={{
        maxLength: 16
      }}
      {...props}
      value={props?.value ? `+${props?.value}`.replace(/\+\+/g, "+") : ""}
      onChange={(value, info) => {
        props.onChange?.(`+${value}`.replace(/\+\+/g, "+"), info);
      }}
    />
  );
});

PhoneInput.displayName = "PhoneInput";
