type Item = { name?: string | null };

export const unifyName = (item: Item[]) => {
  const counts: Record<string, number> = {};

  return item.map((item) => {
    const name = item.name ?? "";
    const count = counts[name] ?? 0;
    counts[name] = count + 1;
    return {
      ...item,
      name: `${name}${count > 0 ? ` (${count})` : ""}`,
      label: name
    };
  });
};
